import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { MOBILE_VESSEL_POINT, VESSEL_POINT } from '../../constants/map.constants';
import s from '../Map/map.module.css';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_RESPONSIVE } from '../../constants/responsive.constants';
const MapVesselComponent = (props: any) => {
    const isSatelliteMap = useSelector((state) => get(state, "map.isSatelliteMap", true)); let location = props.data;
    const isMobile = useMediaQuery(MOBILE_RESPONSIVE);
    const POINT = isMobile ? MOBILE_VESSEL_POINT : VESSEL_POINT;

    function isVesselAvailable() {
        if (POINT[location.locationId]?.isDisplay) {
            if (location.parameters.length > 0 && (location.parameters[0].value === "Idle" || location.parameters[0].value === "-")) {
                return false;
            }
            return true;
        }
        return false;
    }
    function vesselStylePoint() {
        if (POINT[location.locationId]?.isDisplay) {
            const locationY = location.Y - POINT[location.locationId].style.top;
            const locationX = location.X - POINT[location.locationId].style.left;
            return {
                transform: POINT[location.locationId].style?.transform,
                top: locationY + "px",
                left: locationX + "px"
            }
        }
    }


    const vesselLink = isSatelliteMap ? '/img/vessel/' : '/img/vessel/road-views/'
    return (
        <>
            {isVesselAvailable() && <div className={s.vesselPoint} style={vesselStylePoint()}>
                <img src={`${vesselLink}${POINT[location.locationId]?.image}`} style={POINT[location.locationId]?.imgStyle} alt="Vessel" />
            </div>}
        </>
    )
}
export default MapVesselComponent;