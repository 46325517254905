import { Table } from "antd";
import { LmtSalesStackedChartComponentProps } from "./LmtSalesStackedChartComponent";

import sAll from "../../../Map/map.module.css";
import s from "./BaseLmtSalesSummaryChart.module.css";
import { isEmpty } from "lodash";
import { toFixedNumber } from "../../../../utils/Number.utils";

//create a new component of Dqtldlist
export default function LmtSalesListComponent({ chartData, chartName }: LmtSalesStackedChartComponentProps) {

    let columnConfig: any = {};
    let tableHeaderUnit = "";
    function getColumnConfig() {
        switch (chartName) {
            case 'List of DQT/LD':
                columnConfig.label = { title: "Customers", dataIndex: "category", key: "category" };
                columnConfig.value = { title: "Sales Volume", dataIndex: "value", key: "value" };
                tableHeaderUnit = "(BCE)";
                break;
            case 'Sales by Ports':
                columnConfig.label = { title: "Port", dataIndex: "category", key: "category", style: {fontSize: '16px !important'} };
                columnConfig.value = { title: "Sales Volume", dataIndex: "value", key: "value" }
                break;
            case 'Sales by Vessels':
                columnConfig.label = { title: "Vessel", dataIndex: "category", key: "category" };
                columnConfig.value = { title: "Sales Volume", dataIndex: "value", key: "value" }
                break;
            case 'SRC/MMBtu by Country':
                columnConfig.label = { title: "Country", dataIndex: "category", key: "category" };
                columnConfig.value = { title: "Actual", dataIndex: "value", key: "value" }
                columnConfig.plannedValue = { title: "Planned", dataIndex: "valuePlan", key: "valuePlan" }
                tableHeaderUnit = "(USD)";
                break;
            case 'Contract Details':
                columnConfig.label = { title: "Contract Name", dataIndex: "contractName", key: "contractName" };
                columnConfig.typecontract = { title: "Type", dataIndex: "contractType", key: "contractType" };
                columnConfig.bopt = { title: "Base/Opt", dataIndex: "baseOptional", key: "baseOptional" };
                columnConfig.start = { title: "Start", dataIndex: "contractStarts", key: "contractStarts" };
                columnConfig.end = { title: "End", dataIndex: "contractEnds", key: "contractEnds" };
                columnConfig.value = { title: "BCE", dataIndex: "bcePlanned", key: "bcePlanned" }
                break;
            default:
                break;
        }
        return;
    }

    let columns: any = [];
    if (chartData && chartData.length > 0) {
        getColumnConfig();
        columns = renderDesktopColumn(columnConfig);
        columns.forEach((column: any) => {

            setupColumn(column);
            
        });
        
    }

    function setupColumn(column){
        
        // Aligning right for contract detail table
        switch (column.dataIndex){

            case 'contractType':
            column.align = "right"
            break;

            case 'baseOptional':
            column.align = "right"
            break;

            case 'contractStarts':
            column.align = "right";
            break;

            case 'contractEnds':
            column.align = "right";
            break;

            case 'bcePlanned':
            column.align = "center";
            column.width = 80;
            break;

            case 'value':
            column.align = "center";
            column.width = 140;
            break;
        }
    }

    // Render date and reformat from db into dd/mm/yyyy
    function _renderDateText(text){
        return (
            <div className={sAll.tableRow}>
                <span>{(text.replace(/^(\d{1,2})\/(\d{1,2})\/(\d{2})$/, '$2/$1/20$3'))}</span>
            </div>
        );
    }

    function _renderTitleText(text) {
        return (
            <div className={sAll.tableRow}>
                <span>{text}</span>
            </div>
        );
    }

    function _renderValueText(text) {
        return (
            <div className={sAll.tableRow}>
                <span>{parseFloat(text).toFixed(2)}</span>
            </div>
        );
    }

    function titleSorting(a: any, b: any) {
        return a?.category ? (`${a.category}`.localeCompare(`${b.category}`)) : (`${a.contractName}`.localeCompare(`${b.contractName}`))
    }

    function valueSorting(a: any, b: any) {
        return a?.value ? (a.value - b.value) : (a.bcePlanned - b.bcePlanned);
    }

    function typeSorting(a: any, b: any) {
        return `${a.contractType}`.localeCompare(`${b.contractType}`);
    }

    function baseSorting(a: any, b: any) {
        return `${a.baseOptional}`.localeCompare(`${b.baseOptional}`);
    }

    function yearSorting(a: any, b: any) {
        const dateA = new Date(a);
        const dateB = new Date(b);
        if (a < b) {
            return -1
        } else {
            return 1
        }
    }

    function renderDesktopColumn(columnConfig: any) {

        if (!isEmpty(columnConfig)) {

            let columns =
                [
                    {
                        title: columnConfig.label.title,
                        dataIndex: columnConfig.label.dataIndex,
                        ellipsis: true,
                        render: (text: any) => _renderTitleText(text),
                        sorter: (a: any, b: any) => titleSorting(a, b)
                    },
                ];

            if (chartName === "SRC/MMBtu by Country") {
                columns.push(
                    {
                        title: columnConfig.plannedValue.title,
                        dataIndex: columnConfig.plannedValue.dataIndex,
                        ellipsis: true,
                        render: (text: any) => _renderValueText(text),
                        sorter: (a: any, b: any) => valueSorting(a, b)
                    }
                );
            }

            if (chartName === "Contract Details") {
                columns.push(
                    {
                        title: columnConfig.typecontract.title,
                        dataIndex: columnConfig.typecontract.dataIndex,
                        ellipsis: true,
                        render: (text: any) => _renderTitleText(text),
                        sorter: (a: any, b: any) => typeSorting(a, b)
                    },
                    {
                        title: columnConfig.bopt.title,
                        dataIndex: columnConfig.bopt.dataIndex,
                        ellipsis: true,
                        render: (text: any) => _renderTitleText(text),
                        sorter: (a: any, b: any) => baseSorting(a, b)
                    },
                    {
                        title: columnConfig.start.title,
                        dataIndex: columnConfig.start.dataIndex,
                        ellipsis: true,
                        render: (text: any) => _renderDateText(text),
                        sorter: (a: any, b: any) => yearSorting(a.contractStarts, b.contractStarts)
                    },
                    {
                        title: columnConfig.end.title,
                        dataIndex: columnConfig.end.dataIndex,
                        ellipsis: true,
                        render: (text: any) => _renderDateText(text),
                        sorter: (a: any, b: any) => yearSorting(a.contractEnds, b.contractEnds)
                    },
                )
            }

            columns.push(
                {
                    title: columnConfig.value.title,
                    dataIndex: columnConfig.value.dataIndex,
                    ellipsis: true,
                    render: (text: any) => _renderValueText(text),
                    sorter: (a: any, b: any) => valueSorting(a, b)
                }
            )
            return columns;
        }
    }

    return (<>
        {chartData && chartData.length > 0 ?
            (<div className={s.listContainer}>
                <div className={s.chartHeader}>
                    {chartName}
                    <span>{tableHeaderUnit}</span>
                </div>
                <div className="saleListContainer">
                    <div className="saleListTable">
                        <Table
                            rowKey={(record) => record.uid}
                            columns={columns}
                            dataSource={chartData}
                            pagination={
                                {
                                    pageSize: 10,
                                    position: ["bottomLeft"],
                                    showLessItems: true,
                                    responsive: true,
                                    showTotal: (total, range) => (
                                        <div>
                                            <span style={{ fontWeight: 500 }}>{range[0]}-{range[1]}</span>
                                            <span style={{ fontWeight: 300 }}> of {total} items</span>
                                        </div>
                                        //show the number of items and total items
                                    )
                                }
                            }
                            showSorterTooltip={true}
                        //scroll={{ y: 552, x: "max-content" }}
                        />
                    </div>
                </div>
            </div>) :
            (<div className={s.chartContainer} style={{ height: '592px'}}>
                <span className={s.chartNotAvailable}>Chart not available</span>
            </div>)
        }


    </>)
};