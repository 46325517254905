import "antd/dist/antd.css";

import { Breadcrumb, DatePicker, Select, Space, notification } from "antd";
import moment from "moment";
import queryString from "query-string";
import { useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { updateActiveTab } from "../../action/actionMap";
import {
  fetchScoreCardBigCard,
  fetchScoreCardBreakdownCard,
  fetchScoreCardPeriodsList,
  fetchScoreCardPrimaryCardDetail,
  fetchScoreCardRevenueChart,
  fetchScoreCardSmallCard,
  fetchScoreCardStrategicInitiatives,
  fetchScoreCardWaterfallChart,
  setCurrentDate,
  setScoreCardSectionIdActive,
  updateLevelAndKeyScoreCard,
} from "../../action/actionScoreCard";
import ExecutiveSummaryComponent from "../../components/ScoreCards/ExecutiveSummary/ExecutiveSummaryComponent";
import FinancialExcellenceComponent from "../../components/ScoreCards/FinancialExcellence";
import HSSEPerformanceComponent from "../../components/ScoreCards/HSSEPerformance";
import PlantPerformanceComponent from "../../components/ScoreCards/PlantPerformance";
import ProductionAndGenerationComponent from "../../components/ScoreCards/ProductionAndGeneration";
import StrategicInitiativesComponent from "../../components/ScoreCards/StrategicInitiativesComponent";
import { URL_SIDE_BAR } from "../../constants/map.constants";
import { BREADCRUMB, OPU_UPCS } from "../../constants/opu.constants";
import {
  MONTH_SHORT_TEXT,
  TAB_SCORE_CARD,
  TAB_SCORE_CARD_CONFIG,
} from "../../constants/score-card.constants";
import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import { renderScorecardNameBySectionLabel, renderSectionBySectionId } from "../../utils/common.utils";
import s from "./scorecards.module.css";
import sMobile from "./scorecardsResponsiveMobile.module.css";
import { get } from "lodash";
import { setSelectedPersona } from "../../action/actionUsers";
import { SESSION_STORAGE_KEY } from "../../constants/user.constants";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import DateDropdown from "../../components/DateDropdown/DateDropdown";


const ScoreCardsComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathName = window.location.pathname;
  const [showPeriodFilter, setShowPeriodFilter] = useState(false);
  const [showOPUFilter, setShowOPUFilter] = useState(false);
  const [viewSummary, setViewSummary] = useState(false);
  const [isBigCarLv2, setIsBigCarLv2] = useState(0);
  const { MonthPicker } = DatePicker;
  const query = new URLSearchParams(location.search);
  const personaSelection = useSelector((state) =>
    get(state, "user.personaSelection")
  );


  const selectedPersona = useSelector(
    (state: any) => state.user.selectedPersona
  );

  const scoreCard = useSelector((state: any) => state.scoreCard);
  const {
    currentDate,
    periodsList,
    keyScoreCard,
    levelScoreCard,
    executiveSummary,
    sectionIdActive,
    unitSelected,
  } = scoreCard;

  const buildUrlScoreCard = () => {
    const key = levelScoreCard !== 1 && keyScoreCard ? `&key=${keyScoreCard}` : "";
    const unitSelectedString = unitSelected ? `&unit=${unitSelected}` : "";
    return `?level=${levelScoreCard}&tab=${sectionIdActive}${key}${unitSelectedString}`;
  };

  useEffect(() => {
    const tabUrl = Number(query.get("tab"));
    if (levelScoreCard === 1 && !isNaN(currentDate.month)) {
      fetchScoreCardDataLevelOne();
    } else if (!isNaN(currentDate.month)) {
      if (tabUrl !== sectionIdActive && checkKeyExistInScoreCardData()) return;
      fetchScoreCardDataLevelTwo();
    }
    history.push(buildUrlScoreCard());
  }, [currentDate, sectionIdActive, unitSelected, keyScoreCard]);

  useEffect(() => {
    history.push(buildUrlScoreCard());
    if (!levelScoreCard) {
      dispatch(updateLevelAndKeyScoreCard({ level: 1 }));
    }
  }, [levelScoreCard]);

  useEffect(() => {
    let payload = {};
    if (location.search) {
      payload = {
        level: Number(query.get("level")),
        tab: Number(query.get("tab")),
      };
      if (query.get("key")) {
        payload["key"] = query.get("key");
      }
      dispatch(updateLevelAndKeyScoreCard(payload));
    }

    dispatch(updateActiveTab(URL_SIDE_BAR.SCORECARDS));
    dispatch(fetchScoreCardPeriodsList());
    history.push(buildUrlScoreCard());
    return () => {
      dispatch(updateLevelAndKeyScoreCard({ level: 1, tab: 1 }));
    };
  }, []);

  // get periods list
  useEffect(() => {
    if (periodsList && periodsList.length > 0) {
      const dateFilter = periodsList?.filter(
        (item: any) => item.defaultSelection === true
      )[0];
      const payload = {
        year: dateFilter?.year,
        month: dateFilter?.month,
      };

      if (isNaN(currentDate.month)) {
        dispatch(setCurrentDate(payload));
      }
    }
  }, [periodsList]);

  const checkKeyExistInScoreCardData = () => {
    const sectionName = renderSectionBySectionId(sectionIdActive) as any;
    const keyExistInData =
      scoreCard[sectionName] &&
      scoreCard[sectionName]?.parameters.filter((v) => v.key === keyScoreCard);
    const keyExistInMiniCardData =
      scoreCard.smallCardInfor &&
      scoreCard.smallCardInfor?.categories?.filter(
        (v) => v?.parameters.filter((a) => a.key === keyScoreCard).length > 0
      );
    if (
      keyExistInData &&
      keyExistInData.length === 0 &&
      keyExistInMiniCardData &&
      keyExistInMiniCardData?.length === 0
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (levelScoreCard === 2) {
      const sectionName = renderSectionBySectionId(sectionIdActive) as any;
      const keyExistInData =
        scoreCard[sectionName] &&
        scoreCard[sectionName]?.parameters.filter(
          (v) => v.key === keyScoreCard
        );
      const keyExistInMiniCardData =
        scoreCard.smallCardInfor &&
        scoreCard.smallCardInfor?.categories?.filter(
          (v) => v?.parameters.filter((a) => a.key === keyScoreCard).length > 0
        );
      if (checkKeyExistInScoreCardData()) {
        const payload = {
          level: levelScoreCard,
          key: scoreCard[sectionName]?.parameters[0]?.key,
          tab: sectionIdActive,
        };
        dispatch(updateLevelAndKeyScoreCard(payload));
      } else if (keyExistInData && keyExistInData.length > 0) {
        setIsBigCarLv2(1);
      } else if (keyExistInMiniCardData && keyExistInMiniCardData.length > 0) {
        setIsBigCarLv2(2);
      }
    }
  }, [scoreCard]);

  const buildParam = (hasSection: boolean, hasLevel: boolean) => {
    return (
      `?year=${currentDate.year}&month=${MONTH_SHORT_TEXT[currentDate.month - 1]
      }&` +
      (hasLevel ? `level=${levelScoreCard}&` : "") +
      (hasSection && `section=${sectionIdActive}`) +
      (unitSelected ? `&unit=${unitSelected}` : "")
    );
  };

  const buildPayLoad = (hasSection, hasLevel) => {
    return {
      tabActive: sectionIdActive,
      params: buildParam(hasSection, hasLevel),
    };
  };

  const fetchScoreCardDataLevelTwo = () => {
    const payloadBigCard = buildPayLoad(false, false);
    const payloadMiniCard = buildPayLoad(false, true);
    const param = {
      paramKey: keyScoreCard,
      year: currentDate.year,
      month: MONTH_SHORT_TEXT[currentDate.month - 1],
      unit: unitSelected,
    };

    dispatch(
      fetchScoreCardPrimaryCardDetail({
        params: param,
        tabActive: sectionIdActive,
      })
    );
    dispatch(fetchScoreCardBigCard(payloadBigCard));
    dispatch(
      fetchScoreCardBreakdownCard({
        params: { ...param },
        tabActive: sectionIdActive,
      })
    );
    dispatch(
      fetchScoreCardRevenueChart({ params: param, tabActive: sectionIdActive })
    );
    dispatch(
      fetchScoreCardWaterfallChart({
        params: param,
        tabActive: sectionIdActive,
      })
    );
  };

  const fetchScoreCardDataLevelOne = () => {
    const payloadBigCard = buildPayLoad(false, false);
    const payloadMiniCard = buildPayLoad(false, true);

    const params = {
      year: currentDate.year,
      month: MONTH_SHORT_TEXT[currentDate.month - 1],
    };

    // const paramsExecutiveSummary = buildParam(true, false);
    if (sectionIdActive === TAB_SCORE_CARD.STRATEGIC_INITIATIVES) {
      dispatch(fetchScoreCardStrategicInitiatives({ params }));
      return;
    }
    //dispatch(fetchScoreCardExecutiveSummary(paramsExecutiveSummary));
    dispatch(fetchScoreCardBigCard(payloadBigCard));
    if (sectionIdActive === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE && selectedPersona === OPU_UPCS.GNE) {
      dispatch(fetchScoreCardSmallCard(payloadMiniCard));
    }
  };

  const makeText = (m) => {
    if (m && m.year && m.month)
      return MONTH_SHORT_TEXT[m.month - 1] + " " + m.year;
    return "?";
  };

  function handleDateSelect($event) {
    dispatch(
      setCurrentDate({ month: $event.month() + 1, year: $event.year() })
    );
    setShowPeriodFilter(false);
  }

  function increaseDate() {
    let newMonth = currentDate.month + 1;
    let newYear = currentDate.year
    if (newMonth > 12) {
      newMonth = 1;
      newYear++
    }
    dispatch(
      setCurrentDate({ month: newMonth, year: newYear })
    );
  }

  function decreaseDate() {
    let newMonth = currentDate.month - 1;
    let newYear = currentDate.year
    if (newMonth < 1) {
      newMonth = 12;
      newYear--
    }
    dispatch(
      setCurrentDate({ month: newMonth, year: newYear })
    );
  }

  function disabledDate(current: moment.Moment) {
    if (periodsList && periodsList.length > 0) {
      return (
        current <
        moment(`${periodsList[0].month}-${periodsList[0].year}`, "MM-YYYY") ||
        current >
        moment(
          `${periodsList[periodsList.length - 1].month}-${periodsList[periodsList.length - 1].year
          }`,
          "MM-YYYY"
        )
      );
    } else {
      return false;
    }
  }

  function handleChangeTab(value) {
    dispatch(setScoreCardSectionIdActive(value));
  }

  function handleBackLevel() {
    if (levelScoreCard !== 1) {
      dispatch(updateLevelAndKeyScoreCard({ level: 1, tab: sectionIdActive }));
    }
  }
  const handleViewSummary = () => {
    setViewSummary(!viewSummary);
  };

  const tabScorecards = selectedPersona === OPU_UPCS.LMT
    ? TAB_SCORE_CARD_CONFIG.filter(x => x.value !== TAB_SCORE_CARD.STRATEGIC_INITIATIVES
      && x.value !== TAB_SCORE_CARD.PLANT_PERFORMANCE)
    : TAB_SCORE_CARD_CONFIG;

  const _renderScoreCardMobileTablet = () => (
    <div
      className={`score-carrds-page fullscreen-pt-0 ${sMobile.scoreCardsRoot}`}
    >
      <ScrollContainer
        className={`page-container bg-map ${sMobile.scoreCardsContainer}`}
        style={{ backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${scorecardBackgroundImgUrl()})` }}
      >
        {levelScoreCard === 1 && (
          <><div className={s.filterBlocks}>
            <div className={s.filterBlock}>
              <span >OPU</span>
              <div
                className={`${s.filterBlockDropdown}`}
                onBlur={() => {
                  setTimeout(() => {
                    setShowOPUFilter(false)
                  }, 500);
                }}
              >
                <button
                  type='button'
                  style={{ width: '140px', justifyContent: 'center' }}
                  onClick={() =>
                    setShowOPUFilter(!showOPUFilter)
                  }
                >
                  {selectedPersona}
                  <i className='mdi mdi-menu-down'></i>
                </button>
                <div className={`scorecards-OPU-selection`}>
                  <Space direction='vertical'>
                    <Select
                      className={s.mapRightPanelSelect}
                      value={selectedPersona}
                      onChange={handleChangeOPU}
                      open={showOPUFilter}
                      dropdownStyle={{
                        padding: '0',
                        background: 'linear-gradient(180deg,rgba(6, 25, 25, 0.9) 0%,#000000 100%)',
                      }}
                    >
                      {buildOPUOptions(personaSelection).map(item => (
                        <Select.Option value={item.value} key={item.value} className="score-card-OPU-selection-item">
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Space>
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
            <div className={sMobile.filterBlocks}>
              <div className={sMobile.filterBlock}>
                <span>PERIOD</span>
                <div
                  className={`${s.filterBlockDropdown}`}
                  onBlur={() => setShowPeriodFilter(false)}
                >
                  <div className={s.buttonContainer}>
                    <CaretLeftOutlined style={checkDisabledPreviousButton() ? { color: 'gray', pointerEvents: 'none' } : {}} onClick={decreaseDate} />
                    <button
                      type='button'
                      onClick={() =>
                        setShowPeriodFilter(!showPeriodFilter)
                      }
                    >
                      {makeText(currentDate)}
                    </button>
                    <CaretRightOutlined style={checkDisabledNextButton() ? { color: 'gray', pointerEvents: 'none' } : {}} onClick={increaseDate} />
                  </div>
                  <div className='scorecards-month-picker'>
                    <Space direction='vertical'>
                      <MonthPicker
                        disabledDate={disabledDate}
                        value={moment(
                          `${currentDate.year}-${currentDate.month}`,
                          'YYYY-MM'
                        )}
                        onChange={handleDateSelect}
                        open={showPeriodFilter}
                      />
                    </Space>
                  </div>
                </div>
              </div>
            </div>
            <div className={sMobile.scoreCardsRightTab}>
              <ul>
                {tabScorecards.map((v, i) => {
                  return (
                    <li
                      key={i}
                      onClick={() => {
                        handleChangeTab(v.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleChangeTab(v.value);
                        }
                      }}
                      tabIndex={0}
                      className={
                        (sectionIdActive === v.value ? sMobile.active : '') +
                        (levelScoreCard === 2 &&
                          (v.value === TAB_SCORE_CARD.HSSE_PERFORMANCE || v.value === TAB_SCORE_CARD.STRATEGIC_INITIATIVES
                            || v.value === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE || v.value === TAB_SCORE_CARD.PRODUCTION_GENERATION)
                          ? sMobile.disableTabLi
                          : ''
                        )
                      }
                    >
                      {renderScorecardNameBySectionLabel(v.label, selectedPersona)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}
        {levelScoreCard === 1 &&
          ![
            TAB_SCORE_CARD.FINANCIAL_EXCELLENCE,
            TAB_SCORE_CARD.STRATEGIC_INITIATIVES,
            TAB_SCORE_CARD.HSSE_PERFORMANCE,
            TAB_SCORE_CARD.PRODUCTION_GENERATION,
            TAB_SCORE_CARD.PLANT_PERFORMANCE
          ].includes(sectionIdActive) && (
            <>
              <div className={sMobile.scoreCardsLeft}>
                <div>
                  <div className={sMobile.scoreCardsSectionTitle}>
                    Executive <span>Summary</span>
                  </div>
                  <div
                    className={sMobile.viewSummary}
                    onClick={handleViewSummary}
                  >
                    VIEW FULL
                  </div>
                </div>
                <div
                  className={
                    viewSummary
                      ? sMobile.scoreCardsLeftListViewFull
                      : sMobile.scoreCardsLeftListViewLess
                  }
                >
                  {executiveSummary && (
                    <ExecutiveSummaryComponent data={executiveSummary} />
                  )}
                </div>
              </div>
              <div
                className={`${sMobile.scoreCardsSectionTitle} ${sMobile.scoreCardsSectionTitleWithAction}`}
              >
                {![TAB_SCORE_CARD.FINANCIAL_EXCELLENCE].includes(sectionIdActive) ?
                  (<div
                    onClick={() => handleBackLevel()}
                    style={{ cursor: 'pointer' }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleBackLevel();
                      }
                    }}
                    tabIndex={0}
                  >
                    {sectionTitle()} <span>Scorecards</span>
                  </div>) :
                  (<div className={s.titleBlock}>
                    <span>{sectionTitle()} Scorecards</span>
                  </div>)
                }
              </div>
            </>
          )}
        {levelScoreCard !== 1 && (
          <div
            className={sMobile.titleScoreCardLv2}
            onClick={() => handleBackLevel()}
            style={{ cursor: 'pointer' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleBackLevel();
              }
            }}
            tabIndex={0}
          >
            {sectionIdActive === TAB_SCORE_CARD.HSSE_PERFORMANCE && (
              <div>
                <img src='/img/icon-score-card/Icon Back.svg' alt='Back' />{' '}
                <p>HSSE</p> <span>Performance</span>
              </div>
            )}
            {sectionIdActive === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE && (
              <div>
                <img src='/img/icon-score-card/Icon Back.svg' alt='Back' />{' '}
                <p>Financial</p> <span>Excellence</span>
              </div>
            )}
            {sectionIdActive === TAB_SCORE_CARD.PLANT_PERFORMANCE && (
              <div>
                <img src='/img/icon-score-card/Icon Back.svg' alt='Back' />{' '}
                <p>Plant</p> <span>Performance</span>
              </div>
            )}
            {sectionIdActive === TAB_SCORE_CARD.PRODUCTION_GENERATION && (
              <div>
                <img src='/img/icon-score-card/Icon Back.svg' alt='Back' />{' '}
                <p>Production & Generation</p>
              </div>
            )}
          </div>
        )}{' '}
        <div className={sMobile.scoreCardsRightTabContent}>
          {sectionIdActive === TAB_SCORE_CARD.HSSE_PERFORMANCE && (
            <HSSEPerformanceComponent />
          )}
          {sectionIdActive === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE && (
            <FinancialExcellenceComponent />
          )}
          {sectionIdActive === TAB_SCORE_CARD.PLANT_PERFORMANCE && (
            <PlantPerformanceComponent />
          )}
          {sectionIdActive === TAB_SCORE_CARD.PRODUCTION_GENERATION && (
            <ProductionAndGenerationComponent />
          )}
          {sectionIdActive === TAB_SCORE_CARD.STRATEGIC_INITIATIVES && (
            <StrategicInitiativesComponent dateFilterValue={currentDate} />
          )}
        </div>
      </ScrollContainer>
    </div>
  );

  const sectionTitle = () => {
    if (selectedPersona === OPU_UPCS.MLNG) {
      return "PLC";
    }
    if (selectedPersona === OPU_UPCS.GNE) {
      return "Gas Business";
    } else {
      return selectedPersona;
    }
  };

  const backgroundImgUrl = () => {
    switch (selectedPersona) {
      case OPU_UPCS.PFLNG:
        return "/img/pflng_scorecards_bg.jpg";
      case OPU_UPCS.MLNG:
        return "/img/mlng_scorecards_bg.jpg";
      case OPU_UPCS.LNGA:
        return "/img/lnga_scorecards_bg.jpg";
      default:
        return "/img/scorecards_bg1.jpg";
    }
  };

  const scorecardBackgroundImgUrl = () => {
    if (selectedPersona === OPU_UPCS.GNE) {
      switch (sectionIdActive) {
        case TAB_SCORE_CARD.HSSE_PERFORMANCE:
          return "/img/background-score-card/HSSE_BG.png";
        case TAB_SCORE_CARD.PRODUCTION_GENERATION:
          return "/img/background-score-card/Production_BG.png";
        case TAB_SCORE_CARD.PLANT_PERFORMANCE:
          return "/img/background-score-card/Plant_Performance_BG.png";
        case TAB_SCORE_CARD.FINANCIAL_EXCELLENCE:
          return "/img/background-score-card/Financial_BG.png";
        default:
          return "/img/scorecards_bg1.jpg";
      }
    }

    return backgroundImgUrl();
  }

  const buildOPUOptions = (personaSelections: { opuName: string, viewUrl: { scorecards: string } }[]) => {
    const allowedDropdown = personaSelections.filter((personaSelection) => personaSelection.viewUrl.scorecards !== '')
    const convertedAllowedDropdown = allowedDropdown.map(personaSelection => { return { value: personaSelection.opuName, label: personaSelection.opuName } })
    return convertedAllowedDropdown;
  }

  const handleChangeOPU = (value: string) => {
    const splitPathnameArr = pathName.split("/");
    personaSelection.forEach((item) => {
      if (item?.opuName === value) {
        if (item?.viewUrl[splitPathnameArr[splitPathnameArr.length - 1]] !== "") {
          history.push(item?.viewUrl[splitPathnameArr[splitPathnameArr.length - 1]]);
          sessionStorage.setItem(SESSION_STORAGE_KEY.SELECTED_PERSONA, JSON.stringify(item))
          dispatch(setSelectedPersona(value));
        } else {
          notification.open({
            message: 'Info',
            duration: 8,
            className: 'personaNotification',
            icon: <i className="mdi mdi-information" />,
            description:
              `You don't have the access.`
          });
        }

      }
    });
    setShowOPUFilter(false)
  }

  const checkDisabledPreviousButton = () => {
    const checkDate = moment(`${currentDate?.month}-${currentDate?.year}`, "MM-YYYY").isSame(
      moment(`${periodsList[0]?.month}-${periodsList[0]?.year}`, "MM-YYYY"))
    return checkDate
  }

  const checkDisabledNextButton = () => {
    const checkDate = moment(`${currentDate?.month}-${currentDate?.year}`, "MM-YYYY").isSame(
      moment(`${periodsList[periodsList.length - 1]?.month}-${periodsList[periodsList.length - 1]?.year}`, "MM-YYYY"));
    return checkDate
  }

  const onDateChange = (date) => {
    dispatch(
      setCurrentDate({ month: date.month, year: date.year })
    );
  }

  const breadCrumb = () => {
    return <Breadcrumb
      className={s.mapBreadcrumb}
      separator={
        <img
          className={s.breadcrumbSeparator}
          alt='separator-icon'
          src='/img/icon/arrow-right.svg'
        />
      }
    >
      {BREADCRUMB[selectedPersona]?.map((OPU, index) => {
        return (
          <Breadcrumb.Item
            className={`${s.mapBreadcrumbItem} ${index === BREADCRUMB[selectedPersona].length - 1 ? s.currentLocationBcItem : ''
              }`}
            key={OPU}
            onClick={() => handleChangeOPU(OPU === "Gas Business Scorecard" ? OPU_UPCS.GNE : OPU)}
          >
            {OPU}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  }


  return (
    <>
      <Desktop>
        <div
          className={`score-carrds-page fullscreen-pt-0 ${s.scoreCardsRoot}`}
        >
          <ScrollContainer
            className={`page-container bg-map ${s.scoreCardsContainer}`}
            style={{ backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${scorecardBackgroundImgUrl()})` }}
          >
            <div className='page-content'>
              <div className={s.scoreCardsContent}>
                {levelScoreCard === 1 &&
                  ![
                    TAB_SCORE_CARD.STRATEGIC_INITIATIVES,
                    TAB_SCORE_CARD.FINANCIAL_EXCELLENCE,
                    TAB_SCORE_CARD.HSSE_PERFORMANCE,
                    TAB_SCORE_CARD.PRODUCTION_GENERATION,
                    TAB_SCORE_CARD.PLANT_PERFORMANCE
                  ].includes(sectionIdActive) && (
                    <div className={s.scoreCardsLeft}>
                      <div className={s.scoreCardsSectionTitle}>
                        Executive <span>Summary</span>
                      </div>
                      <div className={s.scoreCardsLeftList}>
                        {executiveSummary && (
                          <ExecutiveSummaryComponent data={executiveSummary} />
                        )}
                      </div>
                    </div>
                  )}
                <div className={s.scoreCardsRight}>
                  <div
                    className={`${s.scoreCardsSectionTitle} ${s.scoreCardsSectionTitleWithAction}`}
                  >
                    {![TAB_SCORE_CARD.FINANCIAL_EXCELLENCE].includes(sectionIdActive) ?
                      (<div onClick={() => handleBackLevel()}
                        style={{ cursor: 'pointer' }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleBackLevel();
                          }
                        }}
                        tabIndex={0}
                      >
                        {/* {sectionTitle()} <span>Scorecards</span> */}
                        {breadCrumb()}
                        {levelScoreCard !== 1 && (
                          <span>
                            {' '}
                            <img
                              src='/img/icon-score-card/Icon Back.svg'
                              alt='Back'
                            />{' '}
                            Overview
                          </span>
                        )}{' '}
                      </div>) :
                      (<div>
                        {/* <span>{sectionTitle()} Scorecards</span> */}
                        {breadCrumb()}
                      </div>)
                    }
                    <div className={s.filterContainer}>
                      <div className={s.filterBlocks}>
                        <div className={s.filterBlock}>
                          <span >OPU</span>
                          <div
                            className={`${s.filterBlockDropdown}`}
                            onBlur={() => {
                              setTimeout(() => {
                                setShowOPUFilter(false)
                              }, 500);
                            }}
                          >
                            <button
                              type='button'
                              style={{ width: '140px', justifyContent: 'center' }}
                              onClick={() =>
                                setShowOPUFilter(!showOPUFilter)
                              }
                            >
                              {selectedPersona}
                              <i className='mdi mdi-menu-down'></i>
                            </button>
                            <div className={`scorecards-OPU-selection`}>
                              <Space direction='vertical'>
                                <Select
                                  className={s.mapRightPanelSelect}
                                  value={selectedPersona}
                                  onChange={handleChangeOPU}
                                  open={showOPUFilter}
                                  dropdownStyle={{
                                    padding: '0',
                                    background: 'linear-gradient(180deg,rgba(6, 25, 25, 0.9) 0%,#000000 100%)',
                                  }}
                                >
                                  {buildOPUOptions(personaSelection).map(item => (
                                    <Select.Option value={item.value} key={item.value} className="score-card-OPU-selection-item">
                                      {item.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Space>
                            </div>
                            <div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <DateDropdown disabledDate={disabledDate} periodsList={periodsList} currentDate={currentDate} onDateChange={onDateChange} />
                    </div>
                  </div>
                  <div className={s.scoreCardsRightTab}>
                    <ul>
                      {tabScorecards.map((v, i) => {
                      const isMLNGProductionGeneration = selectedPersona === OPU_UPCS.MLNG && v.value === TAB_SCORE_CARD.PRODUCTION_GENERATION;
                      const isMLNGStrategicInitiatives = selectedPersona === OPU_UPCS.MLNG && v.value === TAB_SCORE_CARD.STRATEGIC_INITIATIVES;
                      return (
                        !(isMLNGProductionGeneration || isMLNGStrategicInitiatives) && (
                          <li
                            key={i}
                            onClick={() => {
                              handleChangeTab(v.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleChangeTab(v.value);
                              }
                            }}
                            tabIndex={0}
                            className={
                              (sectionIdActive === v.value ? s.active : '') +
                              (levelScoreCard === 2 &&
                                (v.value === TAB_SCORE_CARD.HSSE_PERFORMANCE || v.value === TAB_SCORE_CARD.STRATEGIC_INITIATIVES
                                  || v.value === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE || v.value === TAB_SCORE_CARD.PRODUCTION_GENERATION
                                  || v.value === TAB_SCORE_CARD.PLANT_PERFORMANCE)
                                ? s.disableTabLi
                                : '')
                            }
                          >
                            {renderScorecardNameBySectionLabel(v.label, selectedPersona)}
                          </li>
                        ));
                      })}
                    </ul>
                  </div>
                  <div className={s.scoreCardsRightTabContent}>
                    {sectionIdActive === TAB_SCORE_CARD.HSSE_PERFORMANCE && (
                      <HSSEPerformanceComponent />
                    )}
                    {sectionIdActive ===
                      TAB_SCORE_CARD.FINANCIAL_EXCELLENCE && (
                        <FinancialExcellenceComponent />
                      )}
                    {sectionIdActive === TAB_SCORE_CARD.PLANT_PERFORMANCE && (
                      <PlantPerformanceComponent />
                    )}
                    {sectionIdActive ===
                      TAB_SCORE_CARD.PRODUCTION_GENERATION && (
                        <ProductionAndGenerationComponent />
                      )}
                    {sectionIdActive ===
                      TAB_SCORE_CARD.STRATEGIC_INITIATIVES && (
                        <StrategicInitiativesComponent
                          dateFilterValue={currentDate}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </ScrollContainer>
        </div >
      </Desktop >
      <Mobile>{_renderScoreCardMobileTablet()}</Mobile>
      <Tablet>{_renderScoreCardMobileTablet()}</Tablet>
    </>
  );
};
export default ScoreCardsComponent;
