import { useState, useEffect } from 'react';
import styles from './PrimaryCard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { formatScoreCardValue } from '../../../utils/Number.utils';
import { SyncOutlined } from "@ant-design/icons";
import { FINANCIAL_EXCELLENCE_LABEL, HSSE_PERFORMANCE_LABEL, PLANT_PERFORMANCE_LABEL, PRODUCTION_GENERATION_LABEL } from '../../../constants/score-card.constants';
import { OPU_UPCS } from '../../../constants/opu.constants';
import { openScorecardSecondaryCardModal } from '../../../action/actionScoreCard';
import { get } from 'lodash';
export interface PrimaryCardProps {
  data: { [key: string]: any };
  sectionName: string | null;
  enableDetail?: boolean;
  execSummary?: boolean;
}

export default function PrimaryCard({ data, sectionName, enableDetail, execSummary }: PrimaryCardProps) {
  const dispatch = useDispatch();

  const cardData = data;
  const [cardValue, setCardValue] = useState(data?.values?.[0] || data);

  const selectedPersona = useSelector((state: any) => state.user.selectedPersona)

  useEffect(() => {
    setCardValue(data?.values?.[0] || data);
  }, [data]);

  function handleChangeUnit(unit) {
    const value = cardData.values.filter((v) => v.unit !== unit);
    if (value.length > 0) {
      setCardValue(value[0]);
    }
  };

  // Check if PLC UMC, PFLNG1 UMC and PFLNG2 UMC on LNGA financial excellence display actual value otherwise display fix number
  const displayActualValue = () => {
    return (selectedPersona === "LNGA" && (cardData?.name === "PLC UMC" || cardData?.name === "PFLNG1 UMC" || cardData?.name === "PFLNG2 UMC")) || selectedPersona === "Gas Business"
  }

  const primaryValueDecimalNumber = getDecimalNumber();

  function getDecimalNumber() {
    if ((sectionName === FINANCIAL_EXCELLENCE_LABEL && cardValue?.actualValue?.unit === "%")
      || (sectionName === HSSE_PERFORMANCE_LABEL &&
        (cardData.name === "Group Fatality" || cardData.name === "Management HSE Visit/Engagement" || cardData.name === "MHSEV"))) {
      return 0;
    }
    else if (cardValue?.actualValue?.unit === "index") {
      return 2;
    }
    else {
      return 1;
    }
  }

  const formatScoreCardValueFollowBE = (value: any) => {
    if (value !== null && value !== undefined) {
      if (cardData.name !== "C1 SALES" && cardData.name !== "NON-C1 SALES" && cardData.name !== "Major LOPC" && cardData.name !== "Major Fire") {
        if (cardData.name === "FAR" || cardData.name === "LTIF" ||(sectionName === PRODUCTION_GENERATION_LABEL && (cardData.name === 'LNG Loadable' || cardData.name === 'LNG SALES'))) {
          return value?.toLocaleString("en-US", { minimumFractionDigits: 2 })
        }
        return value?.toLocaleString("en-US", { minimumFractionDigits: 1 })
      }
      return value?.toLocaleString("en-US", { minimumFractionDigits: 0 })
    }
    return '-';
  }

  function renderSecondarySection() {
    if (sectionName === HSSE_PERFORMANCE_LABEL && selectedPersona !== OPU_UPCS.LMT && cardValue.secondaryValues != null) {
      return <>
        <div className={styles.YET}>
          <div className={styles.subLabel}>{cardValue.secondaryValues[0]?.type}</div>
          <div className={styles.subNumber}>
            {displayActualValue() ? formatScoreCardValueFollowBE(cardValue?.secondaryValues[0]?.value) : formatScoreCardValue
              (cardValue?.secondaryValues[0]?.value,
                null,
                primaryValueDecimalNumber
              )}
          </div>
        </div>
        <div className={styles.YEP}>
          <div className={styles.subLabel}>{cardValue.secondaryValues[1]?.type}</div>
          <div className={styles.subNumber}>
            {displayActualValue() ? formatScoreCardValueFollowBE(cardValue?.secondaryValues[1]?.value) : formatScoreCardValue
              (cardValue?.secondaryValues[1]?.value,
                null,
                primaryValueDecimalNumber
              )}
          </div>
        </div>
      </>
    }
    return <>
      <div className={styles.YET}>
        <div className={styles.subLabel}>{cardValue.projectionValues[0]?.type}</div>
        <div className={styles.subNumber}>
          {displayActualValue() ? formatScoreCardValueFollowBE(cardValue?.projectionValues[0]?.value) : formatScoreCardValue
            (cardValue?.projectionValues[0]?.value,
              null,
              primaryValueDecimalNumber
            )}
        </div>
      </div>
      <div className={styles.YEP}>
        <div className={styles.subLabel}>{cardValue.projectionValues[1]?.type}</div>
        <div className={styles.subNumber}>
          {displayActualValue() ? formatScoreCardValueFollowBE(cardValue?.projectionValues[1]?.value) : formatScoreCardValue
            (cardValue?.projectionValues[1]?.value,
              null,
              primaryValueDecimalNumber
            )}
        </div>
      </div>
    </>
  }

  function openSecondaryDetailCard() {
    if (sectionName == PRODUCTION_GENERATION_LABEL) {
      dispatch(openScorecardSecondaryCardModal(cardData));
    }
    if (sectionName == HSSE_PERFORMANCE_LABEL) {
      dispatch(openScorecardSecondaryCardModal(cardData));
    }
    if (sectionName == FINANCIAL_EXCELLENCE_LABEL) {
      dispatch(openScorecardSecondaryCardModal(cardData));
    }
    if (sectionName == PLANT_PERFORMANCE_LABEL) {
      dispatch(openScorecardSecondaryCardModal(cardData));
    }
  }

  const syncOutlinedNames = ["LNG Loadable", "LNG Loadable (TOTAL)", "LNG Loadable (DOM)", "LNG Loadable (INT)",
  "LNG Loaded", "Upstream Production", "PFLNG1 LNG LOADABLE", "PFLNG2 LNG LOADABLE", "LMT Sales Volume "];
 
  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.cardInner}>
          <div className={styles.cardPrimarySection}>
            <div className={`${styles.statusBar} ${cardValue?.color ? styles[cardValue?.color] : styles.defaultColor}`} />
            <div className={styles.primaryInfo}>
              <div className={styles.headline}>{cardData.name}</div>
              <div className={cardData.values.length > 1 ? styles.primaryValueCursor : styles.primaryValue}
                onClick={() => handleChangeUnit(cardValue.unit)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {handleChangeUnit(cardValue.unit);}
                }}
                tabIndex={0}
              >
                <div className={styles.primaryNumber} >
                  {displayActualValue() ? formatScoreCardValueFollowBE(cardValue?.actualValue?.value) : formatScoreCardValue
                    (cardValue?.actualValue?.value,
                      cardValue?.actualValue?.currency,
                      primaryValueDecimalNumber
                    ).toLocaleString()}
                </div>
                <div className={styles.unit}>{cardData.values.length > 1 && <SyncOutlined />}
                {syncOutlinedNames.includes(cardData.name) ? cardValue.unit : (cardData.units[0] === "#" ? null : cardValue.unit)}</div>
              </div>
              <div className={styles.comparison}>
                <span className={styles.comparisonText}>
                  {(selectedPersona === OPU_UPCS.MLNG && (cardData.name === 'Cumulative Safe Manhours' || cardData.name === 'Cumulative PLC AIM ZeR0 Days' || cardData.name === 'Cumulative PLC No Harm Days' || cardData.name === 'No Minor and Major LOPC Day')) ? 'Best case since' : cardValue.secondaryValue?.value != null ?
                    cardValue.actualValue.value > cardValue.secondaryValue?.value ? "Higher than" : "Lower than"
                    : "Lower than"
                  }
                </span>
                &nbsp;&nbsp;
                <span className={styles.comparisonNumber}>
                  {displayActualValue() ? formatScoreCardValueFollowBE(cardValue?.secondaryValue?.value) : formatScoreCardValue
                    (cardValue?.secondaryValue?.value,
                      null,
                      primaryValueDecimalNumber
                    )}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.cardSecondarySection}>
            {renderSecondarySection()}
            <div className={styles.SPLY}>
              <div className={styles.subLabel}>{cardValue.splyValue?.type}</div>
              <div className={styles.subNumberWithArrow}>
                <div className={styles.subNumber}>
                  {displayActualValue() ? formatScoreCardValueFollowBE(cardValue?.splyValue?.value) : formatScoreCardValue
                    (cardValue?.splyValue?.value,
                      cardValue?.splyValue?.currency,
                      primaryValueDecimalNumber
                    )}
                </div>
                <div className={styles.subDirection}>
                  <span>{cardValue.splyDeltaValue?.value ?? "-"}{cardValue.splyDeltaValue?.unit} &nbsp;</span>
                  {cardValue.splyDeltaValue?.arrowDirection === 'up' ?
                    <img
                      alt='arrow-up'
                      src='\img\icon\arrow-up.svg'
                      className={styles.subDirectionIcon}
                    /> :
                    <img
                      alt='arrow-down'
                      src='\img\icon\arrow-down.svg'
                      className={styles.subDirectionIcon}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          {(selectedPersona === OPU_UPCS.LMT || enableDetail || (selectedPersona === OPU_UPCS.GNE && cardData?.name === "LNG Loadable")) &&
            <img
              className={styles.popUpIcon}
              alt='pop-up'
              src='/img/icon/popup-with-bg.svg'
              onClick={() => {openSecondaryDetailCard()}}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {openSecondaryDetailCard();}
              }}
              tabIndex={0}
            />
          }
        </div>
      </div>
    </>
  );
}