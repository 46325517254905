import * as types from "./actionType";

export const fetchScoreCardExecutiveSummary = (payload: any) => ({
  type: types.SCORE_CARD_EXECUTIVE_SUMMARY_REQUESTED,
  payload,
});

export const fetchScoreCardBigCard = (payload: any) => ({
  type: types.SCORE_CARD_GNE_TAB_BIG_CARD_REQUESTED,
  payload,
});

export const fetchScoreCardSmallCard = (payload: any) => ({
  type: types.SCORE_CARD_GNE_TAB_SMALL_CARD_REQUESTED,
  payload,
});

export const updateLevelAndKeyScoreCard = (payload: any) => ({
  type: types.SCORE_CARD_UPDATE_LEVEL_AND_KEY,
  payload,
});

export const fetchScoreCardBreakdownCard = (payload: any) => ({
  type: types.SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_REQUESTED,
  payload,
});

export const fetchScoreCardPrimaryCardDetail = (payload: any) => ({
  type: types.SCORE_CARD_GNE_TAB_PRIMARY_CARD_DETAIL_REQUESTED,
  payload,
});

export const fetchScoreCardRevenueChart = (payload: any) => ({
  type: types.SCORE_CARD_REVENUE_CHART_REQUESTED,
  payload,
});

export const fetchScoreCardWaterfallChart = (payload: any) => ({
  type: types.SCORE_CARD_WATERFALL_CHART_REQUESTED,
  payload,
});

export const setScoreCardSectionIdActive = (payload: number) => ({
  type: types.SCORE_CARD_GNE_TAB_CHANGE_SECTIONID_ACTIVE,
  payload,
});

export const setScoreCardBreakdownActiveLv3 = (payload: any) => ({
  type: types.SCORE_CARD_GNE_TAB_BREAKDOWN_ACTIVE_LV3,
  payload,
});

export const fetchScoreCardPrimaryLvl3 = (payload: any) => ({
  type: types.SCORE_CARD_GNE_TAB_PRIMARY_CARD_LVL3_REQUESTED,
  payload,
});

export const fetchScoreCardBreakdownCardLvl3 = (payload: any) => ({
  type: types.SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_REQUESTED,
  payload,
});
export const updateScoreCardBreakdownCardLvl3 = (payload: any) => ({
  type: types.SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_UPDATE,
  payload,
});

export const fetchScoreCardRevenueChartLvl3 = (payload: any) => ({
  type: types.SCORE_CARD_REVENUE_CHART_LVL3_REQUESTED,
  payload,
});

export const fetchScoreCardPeriodsList = () => ({
  type: types.SCORE_CARD_GNE_PERIODS_LIST_REQUSETED,
});

export const fetchCargoDelivery = () => ({
  type: types.GET_CARGO_DELIVERY_REQUESTED,
});

export const deleteUploadFile = (payload: any) => ({
  type: types.SCORE_CARD_GNE_DELETE_UPLOAD_FILE_REQUESTED,
  payload: payload
});

export const downloadUploadFile = (payload: any) => ({
  type: types.SCORE_CARD_GNE_DOWNLOAD_UPLOAD_FILE_REQUESTED,
  payload: payload
});

export const previewUploadFile = (payload: any) => ({
  type: types.SCORE_CARD_GNE_PREVIEW_UPLOAD_FILE_REQUESTED,
  payload: payload
});

export const clearPreviewUploadFile = () => ({
  type: types.SCORE_CARD_GNE_CLEAR_PREVIEW_UPLOAD_FILE_FAILED
});

export const clearUploadFileMessage = () => ({
  type: types.SCORE_CARD_GNE_CLEAR_UPLOAD_FILE_MESSAGE,
});

export const fetchScoreCardMenuList = (payload) => ({
  type: types.SCORE_CARD_MENU_LIST_REQUSETED,
  payload: payload
});

export const fetchListHistoryUpload = (payload) => ({
  type: types.SCORE_CARD_LIST_HISTORY_UPLOAD_REQUSETED,
  payload: payload
});

export const fetchScoreCardWaterfallChartLvl3 = (payload: any) => ({
  type: types.SCORE_CARD_WATERFALL_CHART_LVL3_REQUESTED,
  payload,
});

export const setCurrentDate = (payload: any) => ({
  type: types.SCORE_CARD_SET_CURRENT_DATE,
  payload,
});

export const clearWaterfallChartLevel3 = () => ({
  type: types.SCORE_CARD_WATERFALL_CHART_LVL3_CLEAR,
});
export const setKeyListMiniCardDropdownLv2 = (payload: any) => ({
  type: types.SCORE_CARD_GNE_LIST_MINICARD_DROPDOWN_LVL2,
  payload,
});

export const fetchScoreCardStrategicInitiatives = (payload: any) => ({
  type: types.SCORECARD_STRATEGIC_REQUESTED,
  payload,
});

export const fetchScoreCardLmtOperationalSecondaryCardDetail = (payload: any) => ({
  type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_SECONDARY_CARD_DETAIL_REQUESTED,
  payload,
});

export const fetchScoreCardLmtOperationalExecSummary = (payload: any) => ({
  type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_REQUESTED,
  payload,
});

export const fetchScoreCardMlngHseExecSummary = (payload: any) => ({
  type: types.SCORE_CARD_MLNG_HSE_EXEC_SUMMARY_REQUESTED,
  payload,
});

export const fetchScoreCardMlngFinancialExecSummary = (payload: any) => ({
  type: types.SCORE_CARD_MLNG_FINANCIAL_EXEC_SUMMARY_REQUESTED,
  payload,
});

export const fetchScoreCardMlngPlantExecSummary = (payload: any) => ({
  type: types.SCORE_CARD_MLNG_PLANT_EXEC_SUMMARY_REQUESTED,
  payload,
});

export const fetchScoreCardLmtHsseSecondaryCardDetail = (payload: any) => ({
  type: types.SCORE_CARD_LMT_TAB_HSSE_SECONDARY_CARD_DETAIL_REQUESTED,
  payload,
});

export const fetchScoreCardLmtFinancialSecondaryCardDetail = (payload: any) => ({
  type: types.SCORE_CARD_LMT_TAB_FINANCIAL_SECONDARY_CARD_DETAIL_REQUESTED,
  payload,
});

export const fetchScoreCardLmtChartDetail = (payload: any) => ({
  type: types.SCORECARD_LMT_CHART_DATA_REQUESTED,
  payload
});

export const fetchScoreCardLmtCountryChartDetail = (payload: any) => ({
  type: types.SCORECARD_LMT_COUNTRY_CHART_DATA_REQUESTED,
  payload
});

export const fetchScoreCardLmtCustomerChartDetail = (payload: any) => ({
  type: types.SCORECARD_LMT_CUSTOMER_CHART_DATA_REQUESTED,
  payload
});

export const fetchLmtSalesCountryData = (payload?: any) => ({
  type: types.SCORECARD_LMT_SALES_COUNTRY_DATA_REQUESTED,
  payload
});

export const fetchLmtContractDetail = (payload: any) => ({
  type: types.SCORECARD_LMT_CONTRACT_DETAIL_REQUESTED,
  payload
})

export const fetchLmtSaleVolume = (payload: any) => ({
  type: types.SCORECARD_LMT_SALE_VOLUME_REQUESTED,
  payload
})
export const fetchScorecardLmtContractDetailTable = (payload: any) => ({
  type: types.SCORECARD_LMT_CONTRACT_DETAIL_TABLE_REQUESTED,
  payload
});

export const uploadScorecardFile = (data: any) => ({
  type: types.UPLOAD_SCORECARD_FILE_REQUESTED,
  data: data
});

export const validateScorecardFile = (data: any) => ({
  type: types.VALIDATE_SCORECARD_FILE_REQUESTED,
  data: data
})

export const removeValidateScorecard = () => ({
  type: types.REMOVE_VALIDATE_SCORECARD_FILE_REQUESTED,
})

export const removeValidateScorecardMessage = () => ({
  type: types.REMOVE_VALIDATE_SCORECARD_MESSAGE
})

export const removeUploadFileMessage = () => ({
  type: types.REMVOE_UPLOAD_FILE_MESSAGE
})

export const resetUploadFileSuccessStatus = () => ({
  type: types.RESET_UPLOAD_FILE_SUCCESS_STATUS
})

export const setLmtCountrySelection = (data: any) => (
  {
    type: types.SCORECARD_LMT_SET_SELECTED_COUNTRY,
    data: data
  });

export const setLmtCustomerSelection = (data: any) => (
  {
    type: types.SCORECARD_LMT_SET_SELECTED_CUSTOMER,
    data: data
  });

export const setLmtSourceSelection = (data: any) => (
  {
    type: types.SCORECARD_LMT_SET_SELECTED_SOURCE,
    data: data
  });

export const openModalWaterfallChart = () => ({
  type: types.OPEN_MODAL_WATERFALL_CHART
})

export const closeModalWaterfallChart = () => ({
  type: types.CLOSE_MODAL_WATERFALL_CHART
})

export const openScorecardDetailsModal = () => ({
  type: types.OPEN_SCORECARD_DETAILS_MODAL,
});

export const closeScorecardDetailsModal = () => ({
  type: types.CLOSE_SCORECARD_DETAILS_MODAL,
});

export const openScorecardDetailsChartModal = () => ({
  type: types.OPEN_SCORECARD_DETAILS_CHART_MODAL,
});

export const closeScorecardDetailsChartModal = () => ({
  type: types.CLOSE_SCORECARD_DETAILS_CHART_MODAL,
});

export const openScorecardSecondaryCardModal = (scorecard: any) => ({
  type: types.OPEN_SCORECARD_SECONDARY_CARD_MODAL,
  data: scorecard
});

export const openScorecardSecondaryCardTabModal = (tabIndex: number) => ({
  type: types.OPEN_SCORECARD_SECONDARY_CARD_TAB_MODAL,
  payload: tabIndex
});

export const closeScorecardSecondaryCardModal = () => ({
  type: types.CLOSE_SCORECARD_SECONDARY_CARD_MODAL,
});

export const openScorecardLmtChartModal = () => ({
  type: types.OPEN_SCORECARD_LMT_CHART_MODAL,
});

export const closeScorecardLmtChartModal = () => ({
  type: types.CLOSE_SCORECARD_LMT_CHART_MODAL,
});