import React from 'react';
import { useLayoutEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { numberWithCommas } from '../../../utils/callout.utils';

am4core.useTheme(am4themes_animated);

const colors = {
  'Total Supply': '#FC930D',
  'Non-Power: Others': '#BB9FCC',
  'Non-Power: PIC': '#8B5CA7',
  'Non-Power: GMES': '#461B6A',
  Export: '#D2E36E',
  Power: '#7AC8C5',
};

export default function DemandValueChart(props: any) {
  const { demandValueData, totalDemandData = {}, legendHeight } = props;
  const chart = useRef<am4charts.PieChart>();
  const centerLabel = `<div style='text-align: center'>
      <p style='font-family: Museo Sans; font-weight: 100; font-size: 16px; line-height: 24px; letter-spacing: 0.22px; margin: 0'>Total Demand</ps>
      <p style='font-family: Museo Sans; font-weight: 500; font-size: 32px; line-height: 38px; letter-spacing: 0.25px; margin: 0'>${numberWithCommas(
        totalDemandData?.value
      )}</p>
      <p style='font-weight: 200; font-size: 10px; margin: 0; opacity: 0.5'>${
        totalDemandData?.unit
      }</p>
    </div>`;

  const labelModal = `<div style='padding-top:10px'>
      <p style='margin: 0'>{name}</p>
      <span>{value}</span>
      <span style="font-size: 10px; opacity: 0.5">${totalDemandData?.unit}</span>
    </div>
  `;

  useLayoutEffect(() => {
    let x = am4core.create('chartdiv', am4charts.PieChart);
    // x.responsive.enabled = true;
    //Binding data to chart
    x.data = demandValueData.map((item: any, i) => {
      return {
        color: colors[item.label],
        ...item,
      };
    });
    let pieSeries = x.series.push(new am4charts.PieSeries()) as any;
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'label';
    pieSeries.slices.template.propertyFields.fill = 'color';
    //Style stroke
    pieSeries.slices.template.stroke = am4core.color('#4a2abb');
    pieSeries.slices.template.stroke = am4core.color('#ffffff');
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.strokeLinejoin = 'round';
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.tooltip.disabled = true;
    pieSeries.slices.template.states.getKey('hover').properties.scale = 1;
    pieSeries.slices.template.states.getKey(
      'active'
    ).properties.shiftRadius = 0;
    pieSeries.slices.template.cornerRadius = 5;
    //Cut a hole in the center of the chart
    x.innerRadius = am4core.percent(67);
    // x.paddingRight = 20;

    // Add a legend
    x.legend = new am4charts.Legend();
    let marker = x.legend.markers.template.children.getIndex(0) as any;
    marker.cornerRadius(6, 6, 6, 6);
    marker.strokeWidth = 0;
    marker.strokeOpacity = 1;
    marker.width = 18;
    marker.height = 18;

    x.legend.position = 'right';
    x.legend.align = 'left';
    x.legend.valign = 'middle';
    x.legend.width = am4core.percent(100);
    x.legend.itemContainers.template.height = 70;
    x.legend.labels.template.html = labelModal;
    x.legend.valueLabels.template.disabled = true;

    const label = pieSeries.createChild(am4core.Label);
    label.html = centerLabel;
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'middle';

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  return <div id='chartdiv' style={{ width: '100%', height: '90%'}}></div>;
}
