import LimitConfigurationComponent from "../container/admin/LimitConfiguration/LimitConfigurationComponent";
import ReleaseContentComponent from "../container/admin/ReleaseContent/ReleaseContentComponent";
import ScorecardManagement from "../container/admin/ScorecardManagement/ScorecardManagement";
import ScorecardsOrderingComponent from "../container/admin/ScorecardsOrdering/ScorecardsOrderingComponent";
import UserManagementComponent from "../container/admin/Usermanagement/UserManagementComponent";
import DataHealthComponent from "../container/DataHealth/DataHealthComponent";
import MapComponent from "../container/Map/MapComponent";
import ScoreCardsComponent from "../container/pflng/ScoreCards/ScoreCardsComponent";
import SustainabilityComponent from "../container/Sustainability/SustainabilityComponent";
import ValueChainComponent from "../container/ValueChain/ValueChainComponent";
import { URL_SIDE_BAR } from "./map.constants";

const PATH_SCORE_CARD = [
  URL_SIDE_BAR.SCORECARDS,
  URL_SIDE_BAR.PFLNG_SCORECARDS,
  URL_SIDE_BAR.MLNG_SCORECARDS,
  URL_SIDE_BAR.LNGA_SCORECARDS,
];

const PATH_VALUE_CHAIN = [
  URL_SIDE_BAR.VALUE_CHAIN,
  URL_SIDE_BAR.PFLNG_VALUECHAIN,
  URL_SIDE_BAR.MLNG_VALUECHAIN,
  URL_SIDE_BAR.LNGA_VALUECHAIN,
];

const PATH_MAP = [
  URL_SIDE_BAR.MAP,
  URL_SIDE_BAR.PFLNG_MAP,
  URL_SIDE_BAR.MLNG_MAP,
  URL_SIDE_BAR.LNGA_MAP,
];

const PATH_SUSTAINABILITY = [
  URL_SIDE_BAR.SUSTAINABILITY
];

export const ROUTE = [
  {
    path: PATH_MAP,
    component: MapComponent,
    exact: true
  },
  {
    path: PATH_VALUE_CHAIN,
    component: ValueChainComponent,
    exact: true
  },
  {
    path: PATH_SCORE_CARD,
    component: ScoreCardsComponent,
    exact: true
  },
  {
    path: PATH_SUSTAINABILITY,
    component: SustainabilityComponent,
    exact: true
  }
];
export const ROUTE_ADMIN = [
  {
    path: URL_SIDE_BAR.USERMANAGEMENT,
    component: UserManagementComponent,
    exact: true,
  },
  {
    path: URL_SIDE_BAR.LIMITCONFIGURATION,
    component: LimitConfigurationComponent,
    exact: true,
  },
  {
    path: URL_SIDE_BAR.SCORECARDORDERING,
    component: ScorecardsOrderingComponent,
    exact: true,
  },
  {
    path: URL_SIDE_BAR.SCORECARD_MANAGEMENT,
    component: ScorecardManagement,
    exact: true,
  },
  {
    path: URL_SIDE_BAR.RELEASE_CONTENT,
    component: ReleaseContentComponent,
    exact: true,
  },
  {
    path: URL_SIDE_BAR.DATA_HEALTH_DASHBOARD,
    component: DataHealthComponent,
    exact: true,
  },
];
