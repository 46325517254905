import { all, fork } from "redux-saga/effects";

import * as callOutSaga from "./callout.saga";
import * as forwardPriceSaga from "./forwardPrice.saga";
import * as limitConfigSaga from "./limitconfig.saga";
import * as mapSaga from "./map.saga";
import * as mftSaga from "./mft.saga";
import * as privateRoute from "./private-route.saga";
import * as scoreCard from "./score-card.saga";
import * as scorecardorderingSaga from "./scorecardordering.saga";
import * as userSaga from "./user.saga";
import * as usermanagementSaga from "./usermanagement.saga";
import * as valueChainSaga from "./value-chain.saga";
import * as contentReleaseSaga from "./content-release.saga";
import * as datapipelinesSaga from "./datapipelines.saga";
import * as ghgSaga from "./sustainability.saga";

export default function* sagas() {
  yield all([
    fork(userSaga.workerUserData),
    fork(mapSaga.workerFetchMapData),
    fork(valueChainSaga.workerFetchValueChainData),
    fork(callOutSaga.workerFetchCalloutData),
    fork(scoreCard.workerScoreCardData),
    fork(privateRoute.workerFetchPrivateRouteData),
    fork(usermanagementSaga.workerUserManagementData),
    fork(limitConfigSaga.workerLimitConfigData),
    fork(scorecardorderingSaga.workerScorecardOrderingData),
    fork(mftSaga.workerMFTData),
    fork(forwardPriceSaga.workerForwardPriceData),
    fork(contentReleaseSaga.workerContentReleaseData),
    fork(datapipelinesSaga.workerDataPipeline),
    fork(ghgSaga.workerFetchGhgData),
  ]);
}
