import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { get, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { MOBILE_RESPONSIVE, TABLET_RESPONSIVE } from "../../../../constants/responsive.constants";
import s from "./BaseLmtSalesSummaryChart.module.css";

export interface LmtSalesDeliveredChartComponentProps {
    chartData: any | null;
    chartName?: string
}

export default function LmtSalesDeliveredChartComponent({ chartData, chartName }: LmtSalesDeliveredChartComponentProps) {
    const chart = useRef<am4charts.XYChart>();
    const chartColor = "#00D1CA";

    const isMobile = useMediaQuery(MOBILE_RESPONSIVE);
    const isTablet = useMediaQuery(TABLET_RESPONSIVE);

    useEffect(() => {
        if (!isEmpty(chartData)) {
            const chartdiv = am4core.create("salesDiv" + chartName, am4charts.XYChart);
            chart.current = chartdiv;
            const chartCurrent = chart.current;
            chartCurrent.zoomOutButton.disabled = true;
            if (chartCurrent) {
                if (chartData.length > 0) {
                    //chartCurrent.numberFormatter.numberFormat = "#.##a";
                    //chartCurrent.numberFormatter.smallNumberThreshold = 0;
                    chartCurrent.background.fillOpacity = 0;
                    chartCurrent.maskBullets = false;
                    chartCurrent.height = 300;
                    chartCurrent.cursor = new am4charts.XYCursor();
                    chartCurrent.cursor.lineY.disabled = true;
                    chartCurrent.cursor.behavior = "none";

                    if (chartCurrent.logo) {
                        chartCurrent.logo.disabled = true;
                    }

                    customizeDateAxis(chartCurrent);
                    customizeValueAxis(chartCurrent);

                    chartCurrent.data = chartData;
                }
            }
        }

        // Add data
        return () => {
            chart.current?.dispose();
        };
    }, [chartData]);

    function customizeDateAxis(chart: am4charts.XYChart) {
        const yearAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        yearAxis.dataFields.category = "year";
        yearAxis.renderer.minGridDistance = 10;
        // yearAxis.renderer.ticks.template.disabled = false;
        yearAxis.renderer.labels.template.fill = am4core.color("#ffffffa6");
        yearAxis.fontSize = 11;
        yearAxis.renderer.grid.template.disabled = true;
        // yearAxis.renderer.grid.template.location = 0;
        // yearAxis.renderer.labels.template.disabled = true;
        yearAxis.cursorTooltipEnabled = false;
        yearAxis.dateFormatter.dateFormat = "yyyy";
        yearAxis.numberFormatter = new am4core.NumberFormatter();
        yearAxis.numberFormatter.numberFormat = "#";
        (isMobile || isTablet) &&
            chart.events.on("ready", function () {
                const year = new Date().getFullYear();
                yearAxis.zoomToCategories(
                    year.toString(), (year + 1).toString()
                );
                chart.zoomOutButton.disabled = true;
            });
    }

    function customizeValueAxis(
        chart: am4charts.XYChart
    ) {
        // Create series
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.labels.template.fill = am4core.color("#ffffffa6");
        valueAxis.renderer.labels.template.fontSize = 12;

        createBarChart(
            chart,
            chartColor,
            valueAxis
        );
    }

    function customizeTooltip() {
        let tooltipHTML = `<table style='line-height:14px;color=${chartColor}'>`;
        tooltipHTML +=
            `<tr style='font-size:13px;'>
            <td>
                <span style="height: 14px;
                            width: 24px;
                            color: #C8CCCD;
                            font-family: "Museo Sans";">
                    {value.formatNumber('#.##')}
                </span>
            </td>                             
        </tr>`;
        tooltipHTML += "</table>";
        return tooltipHTML;
    }

    function createBarChart(
        chart: am4charts.XYChart,
        barColor: string,
        valueAxis: am4charts.ValueAxis
    ) {
        var barSeries = chart.series.push(new am4charts.ColumnSeries());
        barSeries.name = "Sales Delivered";
        barSeries.dataFields.valueY = "value";
        barSeries.dataFields.categoryX = "year";
        barSeries.yAxis = valueAxis;
        barSeries.columns.template.fill = am4core.color(barColor);
        barSeries.columns.template.stroke = am4core.color(barColor);
        barSeries.columns.template.width = 25;
        barSeries.tooltipHTML = customizeTooltip();

        const lineTooltip = barSeries.tooltip;
        if (lineTooltip) {
            lineTooltip.pointerOrientation = "down";
            lineTooltip.getFillFromObject = false;
            lineTooltip.background.fill = am4core.color("#051212");
            lineTooltip.background.opacity = 0.95;
            lineTooltip.background.strokeOpacity = 0;
        }
    }

    return (
        <>
            {chartData && chartData.length > 0 ?
                (
                    <div className={s.chartContainer}>
                        <div className={s.chartHeader}>
                            {chartName ? chartName : 'Sales Delivered'}
                        </div>
                        <div id={"salesDiv" + chartName} className={s.salesChart}>
                        </div>
                    </div>
                ) :
                (
                    <div className={s.chartContainer}>
                        <span className={s.chartNotAvailable}>Chart not available</span>
                    </div>
                )
            }
        </>
    );
};
