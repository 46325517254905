import { LOCATION } from './location.constants';
export const URL_SIDE_BAR = {
  MAP: '/map',
  SCORECARDS: '/scorecards',
  VALUE_CHAIN: '/valuechain',
  MFT: '/mft-scorecard',
  LNGA_MAP: '/lnga/map',
  LNGA_SCORECARDS: '/lnga/scorecards',
  LNGA_VALUECHAIN: '/lnga/valuechain',
  MLNG_MAP: '/mlng/map',
  MLNG_SCORECARDS: '/mlng/scorecards',
  MLNG_VALUECHAIN: '/mlng/valuechain',
  PFLNG_MAP: '/pflng/map',
  PFLNG_SCORECARDS: '/pflng/scorecards',
  PFLNG_VALUECHAIN: '/pflng/valuechain',
  LMT_MAP: '/lmt/map',
  LMT_SCORECARDS: '/lmt/scorecards',
  LMT_VALUECHAIN: '/lmt/valuechain',
  GP_MAP: '/gp/map',
  GP_SCORECARDS: '/gp/scorecards',
  GP_VALUECHAIN: '/gp/valuechain',
  PEGT_MAP: '/pegt/map',
  PEGT_SCORECARDS: '/pegt/scorecards',
  PEGT_VALUECHAIN: '/pegt/valuechain',
  PGB_MAP: '/pgb/map',
  PGB_SCORECARDS: '/pgb/scorecards',
  PGB_VALUECHAIN: '/pgb/valuechain',
  OTHERS_MAP: '/others/map',
  OTHERS_SCORECARDS: '/others/scorecards',
  OTHERS_VALUECHAIN: '/others/valuechain',
  SUSTAINABILITY: '/sustainability',
  PDSB_MAP: '/pdsb/map',
  PDSB_SCORECARDS: '/pdsb/scorecards',
  PDSB_VALUECHAIN: '/pdsb/valuechain',
  USERMANAGEMENT: '/admin/usermanagement',
  LIMITCONFIGURATION: '/admin/limitconfiguration',
  SCORECARDORDERING: '/admin/scorecardordering',
  RELEASE_CONTENT: '/admin/releasecontent',
  DATA_HEALTH_DASHBOARD: '/admin/datahealthdashboard',
  SCORECARD_MANAGEMENT: '/admin/scorecardmanagement',
  URL_ACCESS_SOCRE_CARDS:
    'https://app.powerbi.com/groups/me/apps/bfe764fc-b140-4ede-b964-18875720b30b/reports/8245ac77-aa9c-4ba0-bba1-24b98ad2570b/ReportSection',
};

export const LABEL_HEADER_MOBILE = {
  MALAYSIA_MAP: 'MALAYSIA MAP',
  VALUE_CHAIN: 'VALUE CHAIN',
  SCORECARDS: 'SCORECARDS',
  SUSTAINABILITY: 'SUSTAINABILITY'
};
const plcImage = 'PLC_Vessel.png';
export const VESSEL_POINT = {
  '36': {
    isDisplay: true,
    image: plcImage,
    style: {
      top: 20,
      left: -20,
    },
    imgStyle: {
      width: '60px',
    },
  },
  '37': {
    isDisplay: true,
    image: plcImage,
    style: {
      top: 50,
      left: 35,
    },
    imgStyle: {
      width: '60px',
    },
  },
  '38': {
    isDisplay: true,
    image: plcImage,
    style: {
      top: 35,
      left: 80,
    },
    imgStyle: {
      width: '60px',
    },
  },
};

export const MOBILE_VESSEL_POINT = {
  '36': {
    isDisplay: true,
    image: plcImage,
    style: {
      top: 20,
      left: -20,
    },
    imgStyle: {
      width: '60px',
    },
  },
  '37': {
    isDisplay: true,
    image: plcImage,
    style: {
      top: 50,
      left: 35,
    },
    imgStyle: {
      width: '60px',
    },
  },
  '38': {
    isDisplay: true,
    image: plcImage,
    style: {
      top: 35,
      left: 80,
    },
    imgStyle: {
      width: '60px',
    },
  },
};

export const LOCATION_NAME = {
  SCORE: 'SCORE',
  PLENTONG: 'Plentong',
  TTM: 'TTM',
  UKUG: 'UK / UG',
  RGPT: 'RGT PENGERANG',
  LNG_STORAGE: 'LNG STORAGE',
  SEND_OUT: 'SEND OUT',
};

export const LOCATION_ID = {
  RGPT: 7,
  RGTSU: 5,
  RGPT_LNG_STORAGE_SECONDARY: 85,
  RGPT_LNG_SEND_OUT_SECONDARY: 105,
  RGTSU_LNG_STORAGE_SECONDARY: 29,
  RGTSU_LNG_SEND_OUT_SECONDARY: 108,
};

export const NEW_CALLOUT = {
  UKUG: 6,
  PGU: 7,
  RGT: 8,
  LNG_DELIVERY: 9,
  MLNG: 32,
  MLNG_DUA: 33,
  MLNG_TIGA: 31,
  TRAIN_9: 30,
  MARLIN: 34
}