export const UPDATE_USERNAME = "UPDATE_USERNAME";

export const USER_FETCH_REQUESTED = "USER_FETCH_REQUESTED";
export const USER_FETCH_SUCCEEDED = "USER_FETCH_SUCCEEDED";
export const USER_FETCH_FAILED = "USER_FETCH_FAILED";

export const USER_FETCH_PROPERTIES = "USER_FETCH_PROPERTIES";
export const USER_FETCH_PROPERTIES_SUCCEEDED =
  "USER_FETCH_PROPERTIES_SUCCEEDED";
export const USER_FETCH_PROPERTIES_FAILED = "USER_FETCH_PROPERTIES_FAILED";
export const USER_SET_PERSONA_SELECTION = "USER_SET_PERSONA_SELECTION";
export const USER_SET_SELECTED_PERSONA = "USER_SET_SELECTED_PERSONA";

export const USER_ACCESS_GET_OPUS = "USER_ACCESS_GET_OPUS";
export const USER_ACCESS_GET_ADMINS = "USER_ACCESS_GET_ADMINS";
export const USER_ACCESS_OPUS_LOADED = "USER_ACCESS_OPUS_LOADED";
export const USER_ACCESS_ADMINS_LOADED = "USER_ACCESS_ADMINS_LOADED";
export const USER_ACCESS_SUBMIT = "USER_ACCESS_SUBMIT";
export const USER_ACCESS_SENDING = "USER_ACCESS_SENDING";
export const USER_ACCESS_SENT = "USER_ACCESS_SENT";

export const USER_FETCH_IMAGE = "USER_FETCH_IMAGE";
export const USER_FETCH_IMAGE_SUCCEEED = "USER_FETCH_IMAGE_SUCCEEED";
export const USER_FETCH_IMAGE_FAILED = "USER_FETCH_IMAGE_FAILED";

//Map
export const MAP_DATA_FETCH_REQUESTED = "MAP_DATA_REQUESTED";
export const MAP_DATA_FETCH_SUCCEEDED = "MAP_DATA_FETCH_SUCCEEDED";
export const MAP_DATA_FETCH_FAILED = "MAP_DATA_FETCH_FAILED";
export const MAP_TYPE_CHANGE = "MAP_TYPE_CHANGE";
export const MAP_LEVEL_CHANGE = "MAP_LEVEL_CHANGE";
export const MAP_POINT_VIEW_DETAIL = "MAP_POINT_VIEW_DETAIL";
export const MAP_POINT_ZOOM_IN = "MAP_POINT_ZOOM_IN";
export const MAP_POINT_ZOOM_OUT = "MAP_POINT_ZOOM_OUT";
export const MAP_POINT_CLOSE_VIEW_DETAIL = "MAP_POINT_CLOSE_VIEW_DETAIL";
export const MAP_MODAL_CHANGE = "MAP_MODAL_CHANGE";
export const MAP_CALLOUT_FETCH_REQEUSTED = "MAP_CALLOUT_FETCH_REQEUSTED";
export const MAP_CALLOUT_FETCH_REQUESTED_SEC = "MAP_CALLOUT_FETCH_REQUESTED_SEC";
export const MAP_CALLOUT_FETCH_SUCCEEDED = "MAP_CALLOUT_FETCH_SUCCEEDED";
export const MAP_CALLOUT_FETCH_SUCCEEDED_SEC = "MAP_CALLOUT_FETCH_SUCCEEDED_SEC";
export const MAP_CALLOUT_FETCH_FAILED = "MAP_CALLOUT_FETCH_FAILED";
export const MAP_CALLOUT_FETCH_FAILED_SEC = "MAP_CALLOUT_FETCH_FAILED_SEC";
export const MAP_HIGHLIGHT_FETCH_REQEUSTED = "MAP_HIGHLIGHT_FETCH_REQEUSTED";
export const MAP_HIGHLIGHT_FETCH_SUCCEEDED = "MAP_HIGHLIGHT_FETCH_SUCCEEDED";
export const MAP_HIGHLIGHT_FETCH_FAILED = "MAP_HIGHLIGHT_FETCH_FAILED";
export const MAP_CHART_FETCH_REQUESTED = "MAP_CHART_REQUESTED";
export const MAP_CHART_FETCH_SUCCEEDED = "MAP_CHART_FETCH_SUCCEEDED";
export const MAP_CHART_FETCH_FAILED = "MAP_CHART_FETCH_FAILED";
export const MAP_PRIMARY_FETCH_REQUESTED = "MAP_PRIMARY_REQUESTED";
export const MAP_PRIMARY_FETCH_SUCCEEDED = "MAP_PRIMARY_FETCH_SUCCEEDED";
export const MAP_PRIMARY_FETCH_FAILED = "MAP_PRIMARY_FETCH_FAILED";
export const MAP_PRIMARY_TAB_CHANGE = "MAP_PRIMARY_TAB_CHANGE";
export const MAP_DELIVER_FETCH_REQEUSTED = "MAP_DELIVER_FETCH_REQEUSTED";
export const MAP_DELIVER_FETCH_REQUESTED_OLD = "MAP_DELIVER_FETCH_REQUESTED_OLD";
export const MAP_DELIVER_FETCH_SUCCEEDED = "MAP_DELIVER_FETCH_SUCCEEDED";
export const MAP_DELIVER_FETCH_FAILED = "MAP_DELIVER_FETCH_FAILED";

// Sustainability/GHG
export const GHG_MAP_LEVEL_CHANGE = "GHG_MAP_LEVEL_CHANGE";
export const GHG_DATA_FETCH_REQUESTED = "GHG_DATA_FETCH_REQUESTED";
export const GHG_DATA_FETCH_SUCCEEDED = "GHG_DATA_FETCH_SUCCEEDED";
export const GHG_DATA_FETCH_FAILED = "GHG_DATA_FETCH_FAILED";
// Sustainability Callout
export const SUSTAINABILITY_SUMMARY_UPDATE_REQUESTED = "SUSTAINABILITY_SUMMARY_UPDATE_REQUESTED";
export const SUSTAINABILITY_SUMMARY_UPDATE_SUCCEEDED = "SUSTAINABILITY_SUMMARY_UPDATE_SUCCEEDED";
export const SUSTAINABILITY_CALLOUT_FETCH_REQUESTED = "SUSTAINABILITY_CALLOUT_FETCH_REQUESTED";
export const SUSTAINABILITY_CALLOUT_FETCH_SUCCEEDED = "SUSTAINABILITY_CALLOUT_FETCH_SUCCEEDED";
export const SUSTAINABILITY_CALLOUT_FETCH_FAILED = "SUSTAINABILITY_CALLOUT_FETCH_FAILED";


export const CALLOUT_CARGO_DETAILS_REQUESTED = "CALLOUT_CARGO_DETAILS_REQUESTED";
export const CALLOUT_CARGO_DETAILS_SUCCEEDED = "CALLOUT_CARGO_DETAILS_SUCCEEDED";
export const CALLOUT_CARGO_DETAILS_FAILED = "CALLOUT_CARGO_DETAILS_FAILED";

export const MAP_IS_OPEN_MAPTYPE_MOBILE = "MAP_IS_OPEN_MAPTYPE_MOBILE";
export const MAP_IS_OPEN_SIDE_BAR_MOBILE = "MAP_IS_OPEN_SIDE_BAR_MOBILE";
export const OPEN_MODAL_HIS_TREND = "OPEN_MODAL_HIS_TREND";
export const CLOSE_MODAL_HIS_TREND = "CLOSE_MODAL_HIS_TREND";
export const HIS_TREND_CHART_DATA_REQUESTED = "HIS_TREND_CHART_DATA_REQUESTED"
export const HIS_TREND_CHART_DATA_SUCCEEDED = "HIS_TREND_CHART_DATA_SUCCEEDED"
export const HIS_TREND_CHART_DATA_FAILED = "HIS_TREND_CHART_DATA_FAILED"

export const VALUE_CHAIN_DATA_REQUESTED = "VALUE_CHAIN_DATA_REQUESTED";
export const VALUE_CHAIN_DATA_FETCH_SUCCEEDED =
  "VALUE_CHAIN_DATA_FETCH_SUCCEEDED";
export const VALUE_CHAIN_DATA_FETCH_FAILED = "VALUE_CHAIN_DATA_FETCH_FAILED";

export const VALUE_CHAIN_OVERVIEW_REQUESTED = "VALUE_CHAIN_OVERVIEW_REQUESTED";
export const VALUE_CHAIN_OVERVIEW_FETCH_SUCCEEDED =
  "VALUE_CHAIN_OVERVIEW_FETCH_SUCCEEDED";
export const VALUE_CHAIN_OVERVIEW_FETCH_FAILED = "VALUE_CHAIN_OVERVIEW_FETCH_FAILED";

export const SEND_A_REQUEST = "SEND_A_REQUEST";
export const FINISH_A_REQUEST = "FINISH_A_REQUEST";

/* SCORE CARD MODULE */
export const SCORE_CARD_EXECUTIVE_SUMMARY_REQUESTED =
  "SCORE_CARD_EXECUTIVE_SUMMARY_REQUESTED";
export const SCORE_CARD_EXECUTIVE_SUMMARY_SUCCEEDED =
  "SCORE_CARD_EXECUTIVE_SUMMARY_SUCCEEDED";
export const SCORE_CARD_EXECUTIVE_SUMMARY_FAILED =
  "SCORE_CARD_EXECUTIVE_SUMMARY_FAILED";

export const SCORE_CARD_GNE_TAB_BIG_CARD_REQUESTED =
  "SCORE_CARD_GNE_TAB_BIG_CARD_REQUESTED";
export const SCORE_CARD_GNE_TAB_BIG_CARD_SUCCEEDED =
  "SCORE_CARD_GNE_TAB_BIG_CARD_SUCCEEDED";
export const SCORE_CARD_GNE_TAB_BIG_CARD_FAILED =
  "SCORE_CARD_GNE_TAB_BIG_CARD_FAILED";

export const SCORE_CARD_GNE_TAB_SMALL_CARD_REQUESTED =
  "SCORE_CARD_GNE_TAB_SMALL_CARD_REQUESTED";
export const SCORE_CARD_GNE_TAB_SMALL_CARD_SUCCEEDED =
  "SCORE_CARD_GNE_TAB_SMALL_CARD_SUCCEEDED";
export const SCORE_CARD_GNE_TAB_SMALL_CARD_FAILED =
  "SCORE_CARD_GNE_TAB_SMALL_CARD_FAILED";
export const SCORE_CARD_UPDATE_LEVEL_AND_KEY =
  "SCORE_CARD_UPDATE_LEVEL_AND_KEY";

export const SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_REQUESTED =
  "SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_REQUESTED";
export const SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_SUCCEEDED =
  "SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_SUCCEEDED";
export const SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_FAILED =
  "SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_FAILED";

export const SCORE_CARD_GNE_TAB_PRIMARY_CARD_DETAIL_REQUESTED =
  "SCORE_CARD_GNE_TAB_PRIMARY_CARD_DETAIL_REQUESTED";
export const SCORE_CARD_GNE_TAB_PRIMARY_CARD_DETAIL_SUCCEEDED =
  "SCORE_CARD_GNE_TAB_PRIMARY_CARD_DETAIL_SUCCEEDED";
export const SCORE_CARD_GNE_TAB_PRIMARY_CARD_DETAIL_FAILED =
  "SCORE_CARD_GNE_TAB_PRIMARY_CARD_DETAIL_FAILED";

export const SCORE_CARD_GNE_TAB_CHANGE_SECTIONID_ACTIVE =
  "SCORE_CARD_GNE_TAB_CHANGE_SECTIONID_ACTIVE";

export const SCORE_CARD_REVENUE_CHART_REQUESTED =
  "SCORE_CARD_REVENUE_CHART_REQUESTED";
export const SCORE_CARD_REVENUE_CHART_SUCCEEDED =
  "SCORE_CARD_REVENUE_CHART_SUCCEEDED";
export const SCORE_CARD_REVENUE_CHART_FAILED =
  "SCORE_CARD_REVENUE_CHART_FAILED";

export const SCORE_CARD_REVENUE_CHART_LVL3_REQUESTED =
  "SCORE_CARD_REVENUE_CHART_LVL3_REQUESTED";
export const SCORE_CARD_REVENUE_CHART_LVL3_SUCCEEDED =
  "SCORE_CARD_REVENUE_CHART_LVL3_SUCCEEDED";
export const SCORE_CARD_REVENUE_CHART_LVL3_FAILED =
  "SCORE_CARD_REVENUE_CHART_LVL3_FAILED";

export const SCORE_CARD_WATERFALL_CHART_REQUESTED =
  "SCORE_CARD_WATERFALL_CHART_REQUESTED";
export const SCORE_CARD_WATERFALL_CHART_SUCCEEDED =
  "SCORE_CARD_WATERFALL_CHART_SUCCEEDED";
export const SCORE_CARD_WATERFALL_CHART_FAILED =
  "SCORE_CARD_WATERFALL_CHART_FAILED";

export const SCORE_CARD_WATERFALL_CHART_LVL3_REQUESTED =
  "SCORE_CARD_WATERFALL_CHART_LVL3_REQUESTED";
export const SCORE_CARD_WATERFALL_CHART_LVL3_SUCCEEDED =
  "SCORE_CARD_WATERFALL_CHART_LVL3_SUCCEEDED";
export const SCORE_CARD_WATERFALL_CHART_LVL3_FAILED =
  "SCORE_CARD_WATERFALL_CHART_LVL3_FAILED";
export const SCORE_CARD_WATERFALL_CHART_LVL3_CLEAR =
  "SCORE_CARD_WATERFALL_CHART_LVL3_CLEAR";

export const SCORE_CARD_GNE_TAB_PRIMARY_CARD_LVL3_REQUESTED =
  "SCORE_CARD_GNE_TAB_PRIMARY_CARD_LVL3_REQUESTED";
export const SCORE_CARD_GNE_TAB_PRIMARY_CARD_LVL3_SUCCEEDED =
  "SCORE_CARD_GNE_TAB_PRIMARY_CARD_LVL3_SUCCEEDED";
export const SCORE_CARD_GNE_TAB_PRIMARY_CARD_LVL3_FAILED =
  "SCORE_CARD_GNE_TAB_PRIMARY_CARD_LVL3_FAILED";

export const SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_REQUESTED =
  "SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_REQUESTED";
export const SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_UPDATE =
  "SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_UPDATE";
export const SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_SUCCEEDED =
  "SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_SUCCEEDED";
export const SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_FAILED =
  "SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_FAILED";

export const SCORE_CARD_GNE_LIST_MINICARD_DROPDOWN_LVL2 =
  "SCORE_CARD_GNE_LIST_MINICARD_DROPDOWN_LVL2";

export const SCORECARD_STRATEGIC_REQUESTED =
  "SCORECARD_STRATEGIC_REQUESTED";
export const SCORECARD_STRATEGIC_SUCCEEDED =
  "SCORECARD_STRATEGIC_SUCCEEDED";
export const SCORECARD_STRATEGIC_FAILED = "SCORECARD_STRATEGIC_FAILED";

// set key keyBreakdownCard modal level 3
export const SCORE_CARD_GNE_TAB_BREAKDOWN_ACTIVE_LV3 =
  "SCORE_CARD_GNE_TAB_BREAKDOWN_ACTIVE_LV3";

export const SCORE_CARD_GNE_PERIODS_LIST_REQUSETED =
  "SCORE_CARD_GNE_PERIODS_LIST_REQUSETED";
export const SCORE_CARD_GNE_PERIODS_LIST_SUCCEEDED =
  "SCORE_CARD_GNE_PERIODS_LIST_SUCCEEDED";
export const SCORE_CARD_GNE_PERIODS_LIST_FAILED =
  "SCORE_CARD_GNE_PERIODS_LIST_FAILED";

//DELETE UPLOAD FILE
export const SCORE_CARD_GNE_DELETE_UPLOAD_FILE_REQUESTED =
  "SCORE_CARD_GNE_DELETE_UPLOAD_FILE_REQUSETED";
export const SCORE_CARD_GNE_DELETE_UPLOAD_FILE_SUCCEEDED =
  "SCORE_CARD_GNE_DELETE_UPLOAD_FILE_SUCCEEDED";
export const SCORE_CARD_GNE_DELETE_UPLOAD_FILE_FAILED =
  "SCORE_CARD_GNE_DELETE_UPLOAD_FILE_FAILED";
export const SCORE_CARD_GNE_CLEAR_UPLOAD_FILE_MESSAGE =
  "SCORE_CARD_GNE_CLEAR_UPLOAD_FILE_MESSAGE";

//PREVIEW UPLOAD FILE
export const SCORE_CARD_GNE_PREVIEW_UPLOAD_FILE_REQUESTED =
  "SCORE_CARD_GNE_PREVIEW_UPLOAD_FILE_REQUESTED";
export const SCORE_CARD_GNE_PREVIEW_UPLOAD_FILE_SUCCEEDED =
  "SCORE_CARD_GNE_PREVIEW_UPLOAD_FILE_SUCCEEDED";
export const SCORE_CARD_GNE_PREVIEW_UPLOAD_FILE_FAILED =
  "SCORE_CARD_GNE_PREVIEW_UPLOAD_FILE_FAILED";
export const SCORE_CARD_GNE_CLEAR_PREVIEW_UPLOAD_FILE_FAILED =
  "SCORE_CARD_GNE_CLEAR_PREVIEW_UPLOAD_FILE_FAILED";
//DELETE UPLOAD FILE
export const SCORE_CARD_GNE_DOWNLOAD_UPLOAD_FILE_REQUESTED =
  "SCORE_CARD_GNE_DOWNLOAD_UPLOAD_FILE_REQUSETED";

//score card

//score card
export const SCORE_CARD_MENU_LIST_REQUSETED =
  "SCORE_CARD_MENU_LIST_REQUSETED";
export const SCORE_CARD_MENU_LIST_SUCCEEDED =
  "SCORE_CARD_MENU_LIST_SUCCEEDED";
export const SCORE_CARD_MENU_LIST_FAILED =
  "SCORE_CARD_MENU_LIST_FAILED";

//score card list history upload

export const SCORE_CARD_LIST_HISTORY_UPLOAD_REQUSETED =
  "SCORE_CARD_LIST_HISTORY_UPLOAD_REQUSETED";
export const SCORE_CARD_LIST_HISTORY_UPLOAD_SUCCEEDED =
  "SCORE_CARD_LIST_HISTORY_UPLOAD_SUCCEEDED";
export const SCORE_CARD_LIST_HISTORY_UPLOAD_FAILED =
  "SCORE_CARD_LIST_HISTORY_UPLOAD_FAILED";



/* SCORECARD REVAMP */
// MODAL
export const OPEN_SCORECARD_DETAILS_MODAL = "OPEN_SCORECARD_DETAILS_MODAL";
export const CLOSE_SCORECARD_DETAILS_MODAL = "CLOSE_SCORECARD_DETAILS_MODAL";
export const OPEN_SCORECARD_DETAILS_CHART_MODAL = "OPEN_SCORECARD_DETAILS_CHART_MODAL";
export const CLOSE_SCORECARD_DETAILS_CHART_MODAL = "CLOSE_SCORECARD_DETAILS_CHART_MODAL";
export const OPEN_SCORECARD_SECONDARY_CARD_MODAL = "OPEN_SCORECARD_SECONDARY_CARD_MODAL";
export const OPEN_SCORECARD_SECONDARY_CARD_TAB_MODAL = "OPEN_SCORECARD_SECONDARY_CARD_TAB_MODAL";
export const CLOSE_SCORECARD_SECONDARY_CARD_MODAL = "CLOSE_SCORECARD_SECONDARY_CARD_MODAL";
export const OPEN_SCORECARD_LMT_CHART_MODAL = "OPEN_SCORECARD_LMT_CHART_MODAL";
export const CLOSE_SCORECARD_LMT_CHART_MODAL = "CLOSE_SCORECARD_LMT_CHART_MODAL";

// REQUEST
export const SCORE_CARD_LMT_TAB_OPERATIONAL_SECONDARY_CARD_DETAIL_REQUESTED =
  "SCORE_CARD_LMT_TAB_OPERATIONAL_SECONDARY_CARD_DETAIL_REQUESTED";
export const SCORE_CARD_LMT_TAB_OPERATIONAL_SECONDARY_CARD_DETAIL_SUCCEEDED =
  "SCORE_CARD_LMT_TAB_OPERATIONAL_SECONDARY_CARD_DETAIL_SUCCEEDED";
export const SCORE_CARD_LMT_TAB_OPERATIONAL_SECONDARY_CARD_DETAIL_FAILED =
  "SCORE_CARD_LMT_TAB_OPERATIONAL_SECONDARY_CARD_DETAIL_FAILED";
export const SCORE_CARD_LMT_TAB_HSSE_SECONDARY_CARD_DETAIL_REQUESTED =
  "SCORE_CARD_LMT_TAB_HSSE_SECONDARY_CARD_DETAIL_REQUESTED";
export const SCORE_CARD_LMT_TAB_HSSE_SECONDARY_CARD_DETAIL_SUCCEEDED =
  "SCORE_CARD_LMT_TAB_HSSE_SECONDARY_CARD_DETAIL_SUCCEEDED";
export const SCORE_CARD_LMT_TAB_HSSE_SECONDARY_CARD_DETAIL_FAILED =
  "SCORE_CARD_LMT_TAB_HSSE_SECONDARY_CARD_DETAIL_FAILED";
export const SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_REQUESTED =
  "SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_REQUESTED";
export const SCORE_CARD_MLNG_HSE_EXEC_SUMMARY_REQUESTED =
  "SCORE_CARD_MLNG_FINANCIAL_EXEC_SUMMARY_REQUESTED";
export const SCORE_CARD_MLNG_FINANCIAL_EXEC_SUMMARY_REQUESTED =
  "SCORE_CARD_MLNG_HSE_EXEC_SUMMARY_REQUESTED";
export const SCORE_CARD_MLNG_PLANT_EXEC_SUMMARY_REQUESTED =
  "SCORE_CARD_MLNG_PLANT_EXEC_SUMMARY_REQUESTED";
export const SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_SUCCEEDED =
  "SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_SUCCEEDED";
export const SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_FAILED =
  "SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_FAILED";
export const SCORE_CARD_LMT_TAB_FINANCIAL_SECONDARY_CARD_DETAIL_REQUESTED =
  "SCORE_CARD_LMT_TAB_FINANCIAL_SECONDARY_CARD_DETAIL_REQUESTED";
export const SCORE_CARD_LMT_TAB_FINANCIAL_SECONDARY_CARD_DETAIL_SUCCEEDED =
  "SCORE_CARD_LMT_TAB_FINANCIAL_SECONDARY_CARD_DETAIL_SUCCEEDED";
export const SCORE_CARD_LMT_TAB_FINANCIAL_SECONDARY_CARD_DETAIL_FAILED =
  "SCORE_CARD_LMT_TAB_FINANCIAL_SECONDARY_CARD_DETAIL_FAILED";
//general
export const SCORECARD_LMT_CHART_DATA_REQUESTED =
  "SCORECARD_LMT_CHART_DATA_REQUESTED";
export const SCORECARD_LMT_CHART_DATA_SUCCEEDED =
  "SCORECARD_LMT_CHART_DATA_SUCCEEDED";
export const SCORECARD_LMT_CHART_DATA_FAILED =
  "SCORECARD_LMT_CHART_DATA_FAILED";
//customer
export const SCORECARD_LMT_CUSTOMER_CHART_DATA_REQUESTED =
  "SCORECARD_LMT_CUSTOMER_CHART_DATA_REQUESTED";
export const SCORECARD_LMT_CUSTOMER_CHART_DATA_SUCCEEDED =
  "SCORECARD_LMT_CUSTOMER_CHART_DATA_SUCCEEDED";
export const SCORECARD_LMT_CUSTOMER_CHART_DATA_FAILED =
  "SCORECARD_LMT_CUSTOMER_CHART_DATA_FAILED";
//country
export const SCORECARD_LMT_COUNTRY_CHART_DATA_REQUESTED =
  "SCORECARD_LMT_COUNTRY_CHART_DATA_REQUESTED";
export const SCORECARD_LMT_COUNTRY_CHART_DATA_SUCCEEDED =
  "SCORECARD_LMT_COUNTRY_CHART_DATA_SUCCEEDED";
export const SCORECARD_LMT_COUNTRY_CHART_DATA_FAILED =
  "SCORECARD_LMT_COUNTRY_CHART_DATA_FAILED";

export const SCORECARD_LMT_SALES_COUNTRY_DATA_REQUESTED =
  "SCORECARD_LMT_SALES_COUNTRY_DATA_REQUESTED";
export const SCORECARD_LMT_SALES_COUNTRY_DATA_SUCCEEDED =
  "SCORECARD_LMT_SALES_COUNTRY_DATA_SUCCEEDED";
export const SCORECARD_LMT_SALES_COUNTRY_DATA_FAILED =
  "SCORECARD_LMT_SALES_COUNTRY_DATA_FAILED";
export const SCORECARD_LMT_CONTRACT_DETAIL_REQUESTED =
  "SCORECARD_LMT_CONTRACT_DETAIL_REQUESTED";
export const SCORECARD_LMT_CONTRACT_DETAIL_SUCCEEDED =
  "SCORECARD_LMT_CONTRACT_DETAIL_SUCCEEDED";
export const SCORECARD_LMT_CONTRACT_DETAIL_FAILED =
  "SCORECARD_LMT_CONTRACT_DETAIL_FAILED";

//sale volume
export const SCORECARD_LMT_SALE_VOLUME_REQUESTED =
  "SCORECARD_LMT_SALE_VOLUME_REQUESTED";
export const SCORECARD_LMT_SALE_VOLUME_SUCCEEDED =
  "SCORECARD_LMT_SALE_VOLUME_SUCCEEDED";
export const SCORECARD_LMT_SALE_VOLUME_FAILED =
  "SCORECARD_LMT_SALE_VOLUME_FAILED";

export const SCORECARD_LMT_CONTRACT_DETAIL_TABLE_REQUESTED =
  "SCORECARD_LMT_CONTRACT_DETAIL_TABLE_REQUESTED";
export const SCORECARD_LMT_CONTRACT_DETAIL_TABLE_SUCCEEDED =
  "SCORECARD_LMT_CONTRACT_DETAIL_TABLE_SUCCEEDED";
export const SCORECARD_LMT_CONTRACT_DETAIL_TABLE_FAILED =
  "SCORECARD_LMT_CONTRACT_DETAIL_TABLE_FAILED";

//score card upload
export const UPLOAD_SCORECARD_FILE_REQUESTED =
  "UPLOAD_SCORECARD_FILE_REQUESTED";
export const UPLOAD_SCORECARD_FILE_REQUESTED_SUCCEEDED =
  "UPLOAD_SCORECARD_FILE_REQUESTED_SUCCEEDED";
export const UPLOAD_SCORECARD_FILE_REQUESTED_FAILED =
  "UPLOAD_SCORECARD_FILE_REQUESTED_FAILED";

//scorecard validate
export const VALIDATE_SCORECARD_FILE_REQUESTED = 
  "VALIDATE_SCORECARD_FILE_REQUESTED";
export const VALIDATE_SCORECARD_FILE_REQUESTED_FAILED = 
  "VALIDATE_SCORECARD_FILE_REQUESTED_FAILED";
export const VALIDATE_SCORECARD_FILE_REQUESTED_SUCCEEDED = 
  "VALIDATE_SCORECARD_FILE_REQUESTED_SUCCEEDED";

export const VALIDATE_SCORECARD_FILE_REQUESTED_SUCCEEDED_FAIL = 
  "VALIDATE_SCORECARD_FILE_REQUESTED_SUCCEEDED_FAIL"

export const VALIDATE_SCORECARD_FILE_REQUESTED_SUCCEEDED_TRUE =
  "VALIDATE_SCORECARD_FILE_REQUESTED_SUCCEEDED_TRUE"

export const REMOVE_VALIDATE_SCORECARD_FILE_REQUESTED = 
  "REMOVE_VALIDATE_SCORECARD_FILE_REQUESTED"

export const REMOVE_VALIDATE_SCORECARD_MESSAGE = 
  "REMOVE_VALIDATE_SCORECARD_MESSAGE"

export const REMVOE_UPLOAD_FILE_MESSAGE =
  "REMVOE_UPLOAD_FILE_MESSAGE";

export const RESET_UPLOAD_FILE_SUCCESS_STATUS =
  "RESET_UPLOAD_FILE_SUCCESS_STATUS";


export const SCORECARD_LMT_SET_SELECTED_COUNTRY =
  "SCORECARD_LMT_SET_SELECTED_COUNTRY";
export const SCORECARD_LMT_SET_SELECTED_CUSTOMER =
  "SCORECARD_LMT_SET_SELECTED_CUSTOMER";
export const SCORECARD_LMT_SET_SELECTED_SOURCE =
  "SCORECARD_LMT_SET_SELECTED_SOURCE";

/* SIDE_BAR */

export const SCORE_CARD_SIDE_BAR_ACTIVE_TAB = "SCORE_CARD_SIDE_BAR_ACTIVE_TAB";

// Set current date
export const SCORE_CARD_SET_CURRENT_DATE = "SCORE_CARD_SET_CURRENT_DATE";

/* PRIVATE ROUTE */
export const PRIVATE_ROUTE_AUTH_REQUESTED = "PRIVATE_ROUTE_AUTH_REQUESTED";
export const PRIVATE_ROUTE_AUTH_SUCCEEDED = "PRIVATE_ROUTE_AUTH_SUCCEEDED";
export const PRIVATE_ROUTE_AUTH_FAILED = "PRIVATE_ROUTE_AUTH_FAILED";

export const LOGINCOUNT_REQUESTED = "LOGINCOUNT_REQUESTED";
export const LOGINCOUNT_SUCCEEDED = "LOGINCOUNT_SUCCEEDED";
export const LOGINCOUNT_FAILED = "LOGINCOUNT_FAILED";

/* USER MANAGEMENT */
export const USERMANAGEMENT_GET_USERS_REQUESTED =
  "USERMANAGEMENT_GET_USERS_REQUESTED";
export const USERMANAGEMENT_GET_USERS_SUCCEEDED =
  "USERMANAGEMENT_GET_USERS_SUCCEEDED";
export const USERMANAGEMENT_GET_USERS_FAILED =
  "USERMANAGEMENT_GET_USERS_FAILED";

export const USERMANAGEMENT_GET_OPUS_REQUESTED =
  "USERMANAGEMENT_GET_OPUS_REQUESTED";
export const USERMANAGEMENT_GET_OPUS_SUCCEEDED =
  "USERMANAGEMENT_GET_OPUS_SUCCEEDED";
export const USERMANAGEMENT_GET_OPUS_FAILED = "USERMANAGEMENT_GET_OPUS_FAILED";

export const USERMANAGEMENT_GET_PERSONAS_REQUESTED =
  "USERMANAGEMENT_GET_PERSONAS_REQUESTED";
export const USERMANAGEMENT_GET_PERSONAS_SUCCEEDED =
  "USERMANAGEMENT_GET_PERSONAS_SUCCEEDED";
export const USERMANAGEMENT_GET_PERSONAS_FAILED =
  "USERMANAGEMENT_GET_PERSONAS_FAILED";

export const USERMANAGEMENT_GET_ROLES_REQUESTED =
  "USERMANAGEMENT_GET_ROLES_REQUESTED";
export const USERMANAGEMENT_GET_ROLES_SUCCEEDED =
  "USERMANAGEMENT_GET_ROLES_SUCCEEDED";
export const USERMANAGEMENT_GET_ROLES_FAILED =
  "USERMANAGEMENT_GET_ROLES_FAILED";

export const USERMANAGEMENT_PUT_USER_REQUESTED =
  "USERMANAGEMENT_PUT_USER_REQUESTED";
export const USERMANAGEMENT_PUT_USER_SUCCEEDED =
  "USERMANAGEMENT_PUT_USER_SUCCEEDED";
export const USERMANAGEMENT_PUT_USER_FAILED = "USERMANAGEMENT_PUT_USER_FAILED";

export const USERMANAGEMENT_POST_USER_REQUESTED =
  "USERMANAGEMENT_POST_USER_REQUESTED";
export const USERMANAGEMENT_POST_USER_SUCCEEDED =
  "USERMANAGEMENT_POST_USER_SUCCEEDED";
export const USERMANAGEMENT_POST_USER_FAILED =
  "USERMANAGEMENT_POST_USER_FAILED";

export const USERMANAGEMENT_DELETE_USER_REQUESTED =
  "USERMANAGEMENT_DELETE_USER_REQUESTED";
export const USERMANAGEMENT_DELETE_USER_SUCCEEDED =
  "USERMANAGEMENT_DELETE_USER_SUCCEEDED";
export const USERMANAGEMENT_DELETE_USER_FAILED =
  "USERMANAGEMENT_DELETE_USER_FAILED";

export const USERMANAGEMENT_PERSONA_WM_REQUESTED =
  "USERMANAGEMENT_PERSONA_WM_REQUESTED";
export const USERMANAGEMENT_PERSONA_WM_SUCCEEDED =
  "USERMANAGEMENT_PERSONA_WM_SUCCEEDED";
export const USERMANAGEMENT_PERSONA_WM_FAILED =
  "USERMANAGEMENT_PERSONA_WM_FAILED";

export const USERMANAGEMENT_PERSONA_VC_REQUESTED =
  "USERMANAGEMENT_PERSONA_VC_REQUESTED";
export const USERMANAGEMENT_PERSONA_VC_SUCCEEDED =
  "USERMANAGEMENT_PERSONA_VC_SUCCEEDED";
export const USERMANAGEMENT_PERSONA_VC_FAILED =
  "USERMANAGEMENT_PERSONA_VC_FAILED";

export const USERMANAGEMENT_PERSONA_SC_REQUESTED =
  "USERMANAGEMENT_PERSONA_SC_REQUESTED";
export const USERMANAGEMENT_PERSONA_SC_SUCCEEDED =
  "USERMANAGEMENT_PERSONA_SC_SUCCEEDED";
export const USERMANAGEMENT_PERSONA_SC_FAILED =
  "USERMANAGEMENT_PERSONA_SC_FAILED";

export const USERMANAGEMENT_ADD_MAP_LIST_REQUESTED =
  "USERMANAGEMENT_ADD_MAP_LIST_REQUESTED";
export const USERMANAGEMENT_ADD_MAP_LIST_SUCCEEDED =
  "USERMANAGEMENT_ADD_MAP_LIST_SUCCEEDED";
export const USERMANAGEMENT_ADD_MAP_LIST_FAILED =
  "USERMANAGEMENT_ADD_MAP_LIST_FAILED";

export const USERMANAGEMENT_PERSONA_MFT_REQUESTED =
  "USERMANAGEMENT_PERSONA_MFT_REQUESTED";
export const USERMANAGEMENT_PERSONA_MFT_SUCCEEDED =
  "USERMANAGEMENT_PERSONA_MFT_SUCCEEDED";
export const USERMANAGEMENT_PERSONA_MFT_FAILED =
  "USERMANAGEMENT_PERSONA_MFT_FAILED";

export const USERMANAGEMENT_UPDATE_PERMISSION_REQUESTED =
  "USERMANAGEMENT_UPDATE_PERMISSION_REQUESTED";
export const USERMANAGEMENT_UPDATE_PERMISSION_SUCCEEDED =
  "USERMANAGEMENT_UPDATE_PERMISSION_SUCCEEDED";
export const USERMANAGEMENT_UPDATE_PERMISSION_FAILED =
  "USERMANAGEMENT_UPDATE_PERMISSION_FAILED";

export const USERMANAGEMENT_PERSONVCLIST_REQUESTED =
  "USERMANAGEMENT_PERSONVCLIST_REQUESTED";
export const USERMANAGEMENT_PERSONVCLIST_SUCCEEDED =
  "USERMANAGEMENT_PERSONVCLIST_SUCCEEDED";
export const USERMANAGEMENT_PERSONVCLIST_FAILED =
  "USERMANAGEMENT_PERSONVCLIST_FAILED";

export const USERMANAGEMENT_PERSONMFTLIST_REQUESTED =
  "USERMANAGEMENT_PERSONMFTLIST_REQUESTED";
export const USERMANAGEMENT_PERSONMFTLIST_SUCCEEDED =
  "USERMANAGEMENT_PERSONMFTLIST_SUCCEEDED";
export const USERMANAGEMENT_PERSONMFTLIST_FAILED =
  "USERMANAGEMENT_PERSONMFTLIST_FAILED";

export const USERMANAGEMENT_PERSONSCLIST_REQUESTED =
  "USERMANAGEMENT_PERSONSCLIST_REQUESTED";
export const USERMANAGEMENT_PERSONSCLIST_SUCCEEDED =
  "USERMANAGEMENT_PERSONSCLIST_SUCCEEDED";
export const USERMANAGEMENT_PERSONSCLIST_FAILED =
  "USERMANAGEMENT_PERSONSCLIST_FAILED";

export const USERMANAGEMENT_UPDT_PERSONA_REQUESTED =
  "USERMANAGEMENT_UPDT_PERSONA_REQUESTED";
export const USERMANAGEMENT_UPDT_PERSONA_SUCCEEDED =
  "USERMANAGEMENT_UPDT_PERSONA_SUCCEEDED";
export const USERMANAGEMENT_UPDT_PERSONA_FAILED =
  "USERMANAGEMENT_UPDT_PERSONA_FAILED";
export const USERMANAGEMENT_DOWNLOAD_REQUESTED = 'USERMANAGEMENT_DOWNLOAD_REQUESTED';
export const USERMANAGEMENT_DOWNLOAD_SUCCEEDED = 'USERMANAGEMENT_DOWNLOAD_SUCCEEDED';
export const USERMANAGEMENT_DOWNLOAD_FAILED = 'USERMANAGEMENT_DOWNLOAD_FAILED';
export const USERMANAGEMENT_GET_STATUS = 'USERMANAGEMENT_GET_STATUS';
export const USERMANAGEMENT_GET_STATUS_SUCCEEDED = 'USERMANAGEMENT_GET_STATUS_SUCCEEDED';

export const SET_ACTIVE_BUTTON = 'SET_ACTIVE_BUTTON';

export const LIMITCONFIG_GET_WM_TABLE_REQUESTED =
  "LIMITCONFIG_GET_WM_TABLE_REQUESTED";
export const LIMITCONFIG_GET_WM_TABLE_SUCCEEDED =
  "LIMITCONFIG_GET_WM_TABLE_SUCCEEDED";
export const LIMITCONFIG_GET_WM_TABLE_FAILED =
  "LIMITCONFIG_GET_WM_TABLE_FAILED";

export const LIMITCONFIG_UPDATE_RULES_REQUESTED =
  "LIMITCONFIG_UPDATE_RULES_REQUESTED";
export const LIMITCONFIG_UPDATE_RULES_SUCCEEDED =
  "LIMITCONFIG_UPDATE_RULES_SUCCEEDED";
export const LIMITCONFIG_UPDATE_RULES_FAILED =
  "LIMITCONFIG_UPDATE_RULES_FAILED";

export const LIMITCONFIG_GET_VC_TABLE_REQUESTED =
  "LIMITCONFIG_GET_VC_TABLE_REQUESTED";
export const LIMITCONFIG_GET_VC_TABLE_SUCCEEDED =
  "LIMITCONFIG_GET_VC_TABLE_SUCCEEDED";
export const LIMITCONFIG_GET_VC_TABLE_FAILED =
  "LIMITCONFIG_GET_VC_TABLE_FAILED";

export const SCORECARDORDERING_LIST_REQUESTED =
  "SCORECARDORDERING_LIST_REQUESTED";
export const SCORECARDORDERING_LIST_SUCCEEDED =
  "SCORECARDORDERING_LIST_SUCCEEDED";
export const SCORECARDORDERING_LIST_FAILED = "SCORECARDORDERING_LIST_FAILED";

export const SCORECARDORDERING_UPDATE_REQUESTED =
  "SCORECARDORDERING_UPDATE_REQUESTED";
export const SCORECARDORDERING_UPDATE_SUCCEEDED =
  "SCORECARDORDERING_UPDATE_SUCCEEDED";
export const SCORECARDORDERING_UPDATE_FAILED =
  "SCORECARDORDERING_UPDATE_FAILED";

export const SCORECARDORDERING_OPU_REQUESTED =
  "SCORECARDORDERING_OPU_REQUESTED";
export const SCORECARDORDERING_OPU_SUCCEEDED =
  "SCORECARDORDERING_OPU_SUCCEEDED";
export const SCORECARDORDERING_OPU_FAILED = "SCORECARDORDERING_OPU_FAILED";



export const MFT_EXECSUMMARY_REQUESTED = "MFT_EXECSUMMARY_REQUESTED";
export const MFT_EXECSUMMARY_SUCCEEDED = "MFT_EXECSUMMARY_SUCCEEDED";
export const MFT_EXECSUMMARY_FAILED = "MFT_EXECSUMMARY_FAILED";

export const MFT_CARD_REQUESTED = "MFT_CARD_REQUESTED";
export const MFT_CARD_SUCCEEDED = "MFT_CARD_SUCCEEDED";
export const MFT_CARD_FAILED = "MFT_CARD_FAILED";

export const MFT_LINE_CHART_REQUESTED = "MFT_LINE_CHART_REQUESTED";
export const MFT_LINE_CHART_SUCCEEDED = "MFT_LINE_CHART_SUCCEEDED";
export const MFT_LINE_CHART_FAILED = "MFT_LINE_CHART_FAILED";

export const MFT_CARD_SECTION_REQUESTED = "MFT_CARD_SECTION_REQUESTED";
export const MFT_CARD_SECTION_SUCCEEDED = "MFT_CARD_SECTION_SUCCEEDED";
export const MFT_CARD_SECTION_FAILED = "MFT_CARD_SECTION_FAILED";

export const MFT_GROWTH_SECTION_REQUESTED = "MFT_GROWTH_SECTION_REQUESTED";
export const MFT_GROWTH_SECTION_SUCCEEDED = "MFT_GROWTH_SECTION_SUCCEEDED";
export const MFT_GROWTH_SECTION_FAILED = "MFT_GROWTH_SECTION_FAILED";

export const MFT_SUSTAIN_SECTION_REQUESTED = "MFT_SUSTAIN_SECTION_REQUESTED";
export const MFT_SUSTAIN_SECTION_SUCCEEDED = "MFT_SUSTAIN_SECTION_SUCCEEDED";
export const MFT_SUSTAIN_SECTION_FAILED = "MFT_SUSTAIN_SECTION_FAILED";

export const MFT_PERIODLIST_REQUESTED = "MFT_PERIODLIST_REQUESTED";
export const MFT_PERIODLIST_SUCCEEDED = "MFT_PERIODLIST_SUCCEEDED";
export const MFT_PERIODLIST_FAILED = "MFT_PERIODLIST_FAILED";

export const SET_MFT_CURRENTDATE = "SET_MFT_CURRENTDATE";
export const SET_MFT_CURRENTSECTION = "SET_MFT_CURRENTSECTION";
export const SET_MFT_CURRENTSUBSECTION = "SET_MFT_CURRENTSUBSECTION";
export const SET_MFT_CURRENTTABKEY = "SET_MFT_CURRENTTABKEY";

export const MFT_COMMON_REQUESTED = "MFT_COMMON_REQUESTED";
export const MFT_COMMON_SUCCEEDED = "MFT_COMMON_SUCCEEDED";
export const MFT_COMMON_FAILED = "MFT_COMMON_FAILED";

export const MFT_UPDATE_LINE_CHART_REQUESTED = "MFT_UPDATE_LINE_CHART_REQUESTED";
export const MFT_UPDATE_LINE_CHART_SUCCEEDED = "MFT_UPDATE_LINE_CHART_SUCCEEDED";
export const MFT_UPDATE_LINE_CHART_FAILED = "MFT_UPDATE_LINE_CHART_FAILED";
export const MFT_RESET_DYNAMIC_TABS = "MFT_RESET_DYNAMIC_TABS";
export const MFT_SELECT_PRIMARY_CARD = "MFT_SELECT_PRIMARY_CARD"
export const MFT_SET_UNIT_INDEX = "MFT_SET_UNIT_INDEX"
export const MFT_CLEAR_ON_UNMOUNT = "MFT_CLEAR_ON_UNMOUNT"
export const SET_IS_SHOW_FP = "SET_IS_SHOW_FP";

export const FP_GET_CHART_REQUESTED = "FP_GET_CHART_REQUESTED";
export const FP_GET_CHART_SUCCEEDED = "FP_GET_CHART_SUCCEEDED";
export const FP_GET_CHART_FAILED = "FP_GET_CHART_FAILED";

export const OPEN_MODAL_WATERFALL_CHART = "OPEN_MODAL_WATERFALL_CHART"
export const CLOSE_MODAL_WATERFALL_CHART = "CLOSE_MODAL_WATERFALL_CHART"

//Content Release Landing Page
export const CONTENT_RELEASE_ANNOUNCEMENT_REQUESTED = "CONTENT_RELEASE_ANNOUNCEMENT_REQUESTED"
export const CONTENT_RELEASE_ANNOUNCEMENT_SUCCEEDED = "CONTENT_RELEASE_ANNOUNCEMENT_SUCCEEDED"
export const CONTENT_RELEASE_ANNOUNCEMENT_FAILED = "CONTENT_RELEASE_ANNOUNCEMENT_FAILED"
export const OPEN_MODAL_CONTENT_RELEASE = "OPEN_MODAL_CONTENT_RELEASE"
export const CLOSE_MODAL_CONTENT_RELEASE = "CLOSE_MODAL_CONTENT_RELEASE"
//Release Content Admin
export const RC_ANNOUNCEMENT_REQUESTED = "RC_ANNOUNCEMENT_REQUESTED"
export const RC_ANNOUNCEMENT_SUCCEEDED = "RC_ANNOUNCEMENT_SUCCEEDED"
export const RC_ANNOUNCEMENT_FAILED = "RC_ANNOUNCEMENT_FAILED"
export const RC_ANNOUNCEMENT_PUBLISH_STATUS_TOGGLE = "RC_ANNOUNCEMENT_PUBLISH_STATUS_TOGGLE"
export const RC_ANNOUNCEMENT_DELETE = "RC_ANNOUNCEMENT_DELETE"
export const RC_ANNOUNCEMENT_CREATE = "RC_ANNOUNCEMENT_CREATE"
export const RC_ANNOUNCEMENT_UPDATE = "RC_ANNOUNCEMENT_UPDATE"
export const RC_ANNOUNCEMENT_FORM_TOGGLE = "RC_ANNOUNCEMENT_FORM_TOGGLE"
export const RC_ANNOUNCEMENT_SET_CURRENT_RECORD = "RC_ANNOUNCEMENT_SET_CURRENT_RECORD"
export const RC_ANNOUNCEMENT_SET_FORM_LOADING = "RC_ANNOUNCEMENT_SET_FORM_LOADING"
export const RC_ANNOUNCEMENT_ACTION_FAILED = "RC_ANNOUNCEMENT_ACTION_FAILED"
export const RC_ANNOUNCEMENT_CLEAR_FAILED_ACTION = "RC_ANNOUNCEMENT_CLEAR_FAILED_ACTION"

export const GET_CARGO_DELIVERY_REQUESTED = "GET_CARGO_DELIVERY_REQUESTED"
export const GET_CARGO_DELIVERY_SUCCESS = "GET_CARGO_DELIVERY_SUCCESS"
export const GET_CARGO_DELIVERY_FAIL = "GET_CARGO_DELIVERY_FAIL"

export const SUSTAINSIBILITY_CALLOUT_REQUEST = 'SUSTAINSIBILITY_CALLOUT_REQUEST'
export const SUSTAINSIBILITY_CLOSE_CALLOUT_REQUEST = 'SUSTAINSIBILITY_CLOSE_CALLOUT_REQUEST'

//Data health
export const DATA_PIPELINE_REQUESTED = "DATA_PIPELINE_REQUESTED";
export const DATA_PIPELINE_SUCCEEDED = "DATA_PIPELINE_SUCCEEDED";
export const DATA_PIPELINE_FAILED = "FP_GET_CHART_FAILED";
export const REINITIALIZE_USERTABLE_STATUS = "REINITIALIZE_USERTABLE_STATUS"

//sustainability
export const GET_DRILLDOWN_PFLNG_REQUESTED = 'GET_DRILLDOWN_PFLNG_REQUESTED'
export const GET_DRILLDOWN_PFLNG_SUCCESS = 'GET_DRILLDOWN_PFLNG_SUCCESS'
export const GET_DRILLDOWN_PFLNG_FAIL = 'GET_DRILLDOWN_PFLNG_FAIL'

export const GET_DRILLDOWN_GT_REQUESTED = 'GET_DRILLDOWN_GT_REQUESTED'
export const GET_DRILLDOWN_GT_SUCCESS = 'GET_DRILLDOWN_GT_SUCCESS'
export const GET_DRILLDOWN_GT_FAIL = 'GET_DRILLDOWN_GT_FAIL'

export const GET_DRILLDOWN_RGTP_REQUESTED = 'GET_DRILLDOWN_RGTP_REQUESTED'
export const GET_DRILLDOWN_RGTP_SUCCESS = 'GET_DRILLDOWN_RGTP_SUCCESS'
export const GET_DRILLDOWN_RGTP_FAIL = 'GET_DRILLDOWN_RGTP_FAIL'

export const GET_DRILLDOWN_RGTSU_REQUESTED = 'GET_DRILLDOWN_RGTSU_REQUESTED'
export const GET_DRILLDOWN_RGTSU_SUCCESS = 'GET_DRILLDOWN_RGTSU_SUCCESS'
export const GET_DRILLDOWN_RGTSU_FAIL = 'GET_DRILLDOWN_RGTSU_FAIL'