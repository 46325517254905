import React from 'react';

import { useSelector } from 'react-redux';
import { get } from 'lodash';

import s from './highlight.module.css';
import { formatDate } from '../../../utils/callout.utils';

const Highlight = () => {
  const { callOutData } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      callOutData: callout.calloutData,
    };
  });

  function getHighlightsSectionHeading() {        
    let sectionHeading = 'HIGHLIGHTS';

    if(callOutData && callOutData?.secondarySection?.sectionLabel)
    {
        sectionHeading = callOutData?.secondarySection?.sectionLabel;
    }
    return sectionHeading;
  }

  const highlights =
    callOutData?.secondarySection?.sectionValue.highlightData?.length &&
    callOutData?.secondarySection?.sectionValue;

  if (highlights?.highlightData?.length) {
    return (
      <div className={s.highlightComponent}>
        <div className={s.highlightTitle}>
          <span>{getHighlightsSectionHeading()}</span>
        </div>
        <div className={s.mapRightPanelHighlights}>
          <span className={s.highlightDate}>
            <img src='/img/icon/ic-time.svg' alt='time' />
            &nbsp; {formatDate(highlights?.dateCreated)}
          </span>
          {highlights?.highlightData?.map((h) => {
            const isMultipleHighlights = highlights?.highlightData?.length > 1;
            return (
              <div className={s.highlightDesc} key={h.category}>
                {isMultipleHighlights && (
                  <p className={s.highlightItemTitle}>{h.category}</p>
                )}
                <ul>
                  {h.highlighText.map((t) => {
                    return (
                      <li key={t}>
                        <span className={s.highlightItemDescription}>{t}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};

export default Highlight;
