import { isEmpty, values } from "lodash";
import queryString from "query-string";

import { IGNORE_URL_COUNT_REQUEST } from "../constants/endpoints";
import { URL_SIDE_BAR } from "../constants/map.constants";
import { OPU } from "../constants/opu.constants";
import { SESSION_STORAGE_KEY } from "../constants/user.constants";
import { userRoutes } from "./user.utils";

export function buildUrl(
  location: number,
  isSatelliteMap: boolean,
  level: number,
  selectedPointId?: number
) {
  return `${userRoutes()[4]?.path
    }?location=${location}&satellite=${isSatelliteMap}&level=${level}${selectedPointId ? "&id=" + selectedPointId : ""
    }`;
}

export function buildGhgUrl(
  location: number,
  level: number,
  selectedPointId?: number
) {
  const sustainabilityRoute = userRoutes().find(u => {
    return u.path === URL_SIDE_BAR.SUSTAINABILITY
  });
  return `${sustainabilityRoute?.path}?location=${location}&level=${level}${selectedPointId ? "&id=" + selectedPointId : ""}`;
}

export function checkActiveTab(pathName: string, currentTab: string) {
  return pathName.includes(currentTab) ? " active" : "";
}

export function ignoreUrlWhenCountRequest(url: string) {
  return IGNORE_URL_COUNT_REQUEST.filter((v) => url.includes(v)).length <= 0;
}

export function getTabDefault() {
  const pathName = window.location.pathname;
  if (pathName.includes(URL_SIDE_BAR.VALUE_CHAIN)) {
    return URL_SIDE_BAR.VALUE_CHAIN;
  } else if (pathName.includes(URL_SIDE_BAR.SCORECARDS)) {
    return URL_SIDE_BAR.SCORECARDS;
  } else if (pathName.includes(URL_SIDE_BAR.SUSTAINABILITY)) {
    return URL_SIDE_BAR.SUSTAINABILITY;
  }
  else return URL_SIDE_BAR.MAP;
}

export function isPageAdmin() {
  const pathName = window.location.pathname;
  return pathName.indexOf("admin") > 0;
}

export const getOpuApi = (
  viewType: string,
  allowOPU: any = [],
  notAllowOpu: any = []
) => {
  const viewPermission = sessionStorage.getItem(
    SESSION_STORAGE_KEY.VIEW_PERMISSION
  );

  const selectedPersona = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEY.SELECTED_PERSONA) as string
  );
  if (selectedPersona?.opuName) {
    const opuNameSelectedPersona = selectedPersona?.opuName.toLowerCase();
    return !notAllowOpu.includes(opuNameSelectedPersona)
      ? opuNameSelectedPersona
      : "";
  }
  // get OPU from redux store
  if (viewPermission) {
    const viewPermissionJson = values(JSON.parse(viewPermission));
    //GNE MLNG LNGA PFLNG
    if (viewPermissionJson && viewPermissionJson.length) {
      const opuModule = viewPermissionJson.filter(
        (v) => v.viewType === viewType
      );
      let opuName = opuModule?.[0]?.defaultView.opuName.toLowerCase();
      if (
        isEmpty(allowOPU) ||
        (!isEmpty(allowOPU) &&
          allowOPU.includes(opuName) &&
          !notAllowOpu.includes(opuName))
      ) {
        return opuName;
      }
    }
    return "";
  }
};

export const buildApiEndPoint = (
  opu: string,
  endpointBase: string,
  endpointGne: string,
  endpointOpu: string,
  opuSupport: any = [],
  opuNotSupport: any = []
) => {
  if (opuNotSupport.includes(opu)) {
    return "";
  }
  if (opu === OPU.GNE || !opuSupport.includes(opu)) {
    return endpointBase + endpointGne;
  }
  return `${endpointBase}/v1/${opu}${endpointOpu}`;
};
