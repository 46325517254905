import React, { useLayoutEffect, useRef } from 'react';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import calloutS from '../PrimaryInfo/primary-info.module.css';
import { toFixedNumber, toFixedSustainabilityNumber } from '../../../utils/Number.utils';
import { render } from '@testing-library/react';

am4core.useTheme(am4themes_animated);

const colors = ['#FC930D', '#BB9FCC', '#8B5CA7', '#461B6A', '#D2E36E', '#7AC8C5'];

export default function EmissionSourcesComponent() {
    const { callOutData } = useSelector((state) => {
        const callout = get(state, 'callout', null);
        return {
            callOutData: callout.sustainabilityCalloutData,
        };
    });

    const emissionSourceData: any[] = callOutData?.secondarySection?.emissionSources;


    const chart = useRef<am4charts.PieChart>();
    const total = emissionSourceData && emissionSourceData.find(e => e.label === "Total");
    const centerLabel = `<div style='text-align: center'>
            <p style='font-weight: 300; margin: 0'>${total ? total.label : "Data not available"}</ps>
            <p style='font-weight: 700; font-size: ${total?.value && total.value.toString().length >= 6 ? '17px' : '32px'} ; margin: 0'>${toFixedSustainabilityNumber(total?.value, 3)}</p>
            <p style='font-weight: 200; font-size: 10px; margin: 0; opacity: 0.5; line-height: 17px'>${total ? renderUnitSubscript(total.unit) : ''}</p>
        </div>`;


    function renderUnitSubscript(i: string) {
        if (i === 'tCO2e') {
            return '<span class="' + calloutS.co2Unit + '"><span>tCO<sub>2</sub>e</span></span>';
        }
        else {
            return '<span class="' + calloutS.co2Unit + '"><span>M&nbsp;tCO<sub>2</sub>e</span></span>';
        }
    }
    
    const labelModal = `<div style='line-height: 1.1em;'>
            <p style='margin: 0'>{name}</p>
            <span>{value}</span>
            <span style="font-size: 10px; opacity: 0.5">${total ? renderUnitSubscript(total.unit) : "Data not available"}</span>
        </div>
        `;

    // console.log("first",total?.value?.toString().length)


    function getCategoryColor(label: string) {
        switch (label) {
            case "Stationary Combustion":
                return "#7AC8C5";
            case "Flare":
                return "#D2E36E";
            case "ARGU":
                return "#8B5CA7";
            case "Fugitive Emission":
                return "#8A9DB2";
            case "Vent":
                return "#B8DBF9";
            case "Indirect Emission - Imported Electricity":
                return "#3685D3";
            case "Mobile & Transportation":
                return "#A0CEA5";
            default:
                return;
        }
    }

    useLayoutEffect(() => {
        let x = am4core.create('chartdiv', am4charts.PieChart);
        // x.responsive.enabled = true;
        //Binding data to chart
        x.data = emissionSourceData && emissionSourceData.filter(e => e.label !== total.label)
            .sort((a,b) => b.value - a.value)
            .map((item: any, i) => {
                return {
                    color: getCategoryColor(item.label),
                    ...item,
                };
            });
        let pieSeries = x.series.push(new am4charts.PieSeries()) as any;
        pieSeries.dataFields.value = 'value';
        pieSeries.dataFields.category = 'label';
        pieSeries.slices.template.propertyFields.fill = 'color';
        //Style stroke
        pieSeries.slices.template.stroke = am4core.color('#4a2abb');
        pieSeries.slices.template.stroke = am4core.color('#ffffff');
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.slices.template.strokeLinejoin = 'round';
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;
        pieSeries.tooltip.disabled = true;
        pieSeries.slices.template.states.getKey('hover').properties.scale = 1;
        pieSeries.slices.template.states.getKey(
            'active'
        ).properties.shiftRadius = 0;
        pieSeries.slices.template.cornerRadius = 5;
        //Cut a hole in the center of the chart
        x.innerRadius = am4core.percent(67);
        // x.paddingRight = 20;

        //turn off negative part in pie chart
        const negativeValues: number[] = []
        pieSeries.events.on("datavalidated", function (ev) {
            ev.target.slices.each(function (slice) {
                if (slice.dataItem.value < 0) {
                    negativeValues.push(slice.dataItem._index)
                    slice.dataItem.hide();
                }
            });
        });

        // Add a legend
        x.legend = new am4charts.Legend();
        // let legendContainer = am4core.create("emissionLegendChart", am4core.Container);
        // legendContainer.width = am4core.percent(100);
        // legendContainer.height = am4core.percent(100);
        // x.legend.parent = legendContainer;
        let marker = x.legend.markers.template.children.getIndex(0) as any;
        marker.cornerRadius(6, 6, 6, 6);
        marker.strokeWidth = 0;
        marker.strokeOpacity = 1;
        marker.width = 18;
        marker.height = 18;

        x.legend.position = 'right';
        x.legend.align = 'left';
        x.legend.valign = 'middle';
        x.legend.width = am4core.percent(80);
        //x.legend.height = am4core.percent(100);
        // x.legend.itemContainers.template.height = 100;
        x.legend.itemContainers.template.paddingTop = 0;
        x.legend.labels.template.html = labelModal;
        x.legend.valueLabels.template.disabled = true;
        x.legend.scrollable = true;
        x.legend.labels.template.truncate = false;
        x.legend.labels.template.wrap = true;

        x.numberFormatter.numberFormat = "0.000";
        x.legend.numberFormatter.numberFormat = "0.000";

        //disable click negative legend 
        x.legend.itemContainers.template.events.on('hit', function (ev: any) {
            const itemContainer = ev.target;
            if (negativeValues.includes(itemContainer.dataItem.index)) {
                itemContainer.isActive = false;
            }
        });
        const label = pieSeries.createChild(am4core.Label);
        label.html = centerLabel;
        label.horizontalCenter = 'middle';
        label.verticalCenter = 'middle';

        chart.current = x;

        return () => {
            x.dispose();
        };
    }, []);

    if (emissionSourceData) {
        return (
            <>
            <div className={calloutS.emissionChartContainer}>
                <div id='chartdiv' className={calloutS.emissionChart}></div>
                {/* <div id='emissionLegendChart' className={calloutS.emissionChartLegend}></div> */}
            </div>
            </>
        );
    }
    else {
        return (
            <div className={calloutS.emissionChartContainer}>
                Chart not available
            </div>
        );
    }
}