import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import PrimaryCard from './PrimaryCard';
import { Desktop, Mobile, Tablet } from '../../responsive/responesive';
import s from './scorecards.module.css';
import sMobile from './scorecardsResponsiveMobile.module.css';
import {
  openScorecardDetailsModal
} from '../../action/actionScoreCard';

import {
  SCORECARD_DETAILS
} from "../../constants/score-card.constants";
import { OPU_UPCS } from '../../constants/opu.constants';
import SecondaryCard from './SecondaryCard/SecondaryCard';

export interface PlantPerformanceProps {
}

// eslint-disable-next-line no-empty-pattern
export default function PlantPerformanceComponent({}: PlantPerformanceProps) {
  const dispatch = useDispatch();

  const plantPerformanceData = useSelector((state) =>
    get(state, 'scoreCard.plantPerformanceData', [])
  );
  
  const sectionIdActive = useSelector((state) => 
    get(state, 'scoreCard.sectionIdActive', 1)
  );

  const selectedPersona = useSelector((state) => 
    get(state, "user.selectedPersona")
  );

  const [currentPage, setCurrentPage] = useState(-1);
  const [currentData, setCurrentData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);

  const sectionName = plantPerformanceData?.sectionName;

  useEffect(() => {
    if (
      plantPerformanceData?.parameters //&&
      // plantPerformanceData?.parameters.length > 0
    ) {
      const { paramCount, pageSize: page } = plantPerformanceData;
      setPageSize(page);
      initPage(page, paramCount, 0);
    }
  }, [plantPerformanceData]);

  function initPage(page, totalItems, cPage) {
    const totalPages =
      totalItems % page !== 0
        ? parseInt((totalItems / page).toString()) + 1
        : totalItems / page;
    
    const newData: any = plantPerformanceData?.parameters?.filter(
      (item, idx) => {
        return idx >= cPage * page && idx < cPage * page + page;
      }
    );

    setCurrentPage(cPage);
    setCurrentData(newData);
    setTotalPage(totalPages);
  }

  function pageChanged(isNextPage) {
    let cPage = currentPage;
    if (isNextPage) {
      if (cPage < totalPage - 1) cPage += 1;
    } else {
      if (cPage > 0) cPage -= 1;
    }
    const newData: any = plantPerformanceData?.parameters?.filter(
      (item, idx) => {
        return idx >= cPage * pageSize && idx < cPage * pageSize + pageSize;
      }
    );
    if (cPage !== currentPage) {
      setCurrentPage(cPage);
    }
    setCurrentData(newData);
  }

  function openScorecardDetails() {
    dispatch(openScorecardDetailsModal());
  }

  function isEnableDetail(card){
    return selectedPersona === OPU_UPCS.MLNG && card.isAdditionalDataExists === true;
  }

  function ScoreCardColComponent() {
    const colLength = selectedPersona === OPU_UPCS.GNE 
      && currentData 
      && currentData.length
      && currentData.length % 2 === 0 
      ? Math.round(currentData.length / 2)
      : 5;
    const arrColIndexs = Array.from({length: colLength}, (item, index) => item = index);

    const emptyColLength = colLength < 5 ? 5 - colLength : 0;
    const arrEmptyColIndexes = Array.from( {length: emptyColLength}, (item, index) => item = colLength + index);

    return (
      <>
        {
          /* Columns with cards */
          arrColIndexs.map(iCol => {
            return <div key={`scorecards_col_${iCol}`} className={s.scoreCardsCol}>
              {currentData &&
                currentData.map((card, idx) => {
                  return idx % colLength === iCol ? <PrimaryCard key={idx} data={card} sectionName={sectionName} enableDetail={isEnableDetail(card)}/> : '';
                })}
            </div>
          })
        }

        {
          /* Empty columns to reserve scale */
          arrEmptyColIndexes.map(iCol => <div key={`scorecards_col_${iCol}`} className={s.scoreCardsCol}></div>)
        }
      </>
    );
  }

  function ScoreCardPageComponent(cssModule) {
    return (
      <div className={cssModule.scoreCardsPagination}>
        <button
          disabled={currentPage <= 0}
          type='button'
          onClick={() => pageChanged(false)}
        >
          <i className='mdi mdi-menu-left'></i>
        </button>
        <button
          disabled={currentPage >= totalPage - 1}
          type='button'
          onClick={() => pageChanged(true)}
        >
          <i className='mdi mdi-menu-right'></i>
        </button>
      </div>
    );
  }

  function _renderMobileTablet() {
    return (
      <>
        <div>
          <div>
            {SCORECARD_DETAILS.includes(sectionIdActive) &&
              <div className={sMobile.detailsIcon}
                onClick={openScorecardDetails}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {openScorecardDetails();}
                }}
                tabIndex={0}
              >
                <img
                  alt='details'
                  src='\img\icon\details-icon.svg'
                />
              </div> 
            }           
            <div className={sMobile.scoreCardsItems}>
              {ScoreCardColComponent()}
            </div>
            {ScoreCardPageComponent(sMobile)}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Desktop>
        {/* Big card list */}
        <div>
          <div className={s.scorecardContainer}>
            {SCORECARD_DETAILS.includes(sectionIdActive) &&
              <div className={s.detailsIcon}
                onClick={openScorecardDetails}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {openScorecardDetails();}
                }}
                tabIndex={0}
              >
                <img
                  alt='details'
                  src='\img\icon\details-icon.svg'
                />
              </div>
            }   
            <div className={s.scoreCardsItems}>
              {ScoreCardColComponent()}
            </div>
            {ScoreCardPageComponent(s)}
          </div>
        </div>
      </Desktop>

      <Mobile>{_renderMobileTablet()}</Mobile>

      <Tablet>{_renderMobileTablet()}</Tablet>
      {<SecondaryCard sectionName={sectionName} selectedPersona={selectedPersona} isShowCardNameAsHeader={true}/>}
    </>
  );
}
