import React, { useState, useEffect } from 'react'
import s from './GeneralComponent.module.css'
import * as am4core from "@amcharts/amcharts4/core";
import { Col, DatePicker, Row, Space, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import moment from "moment";
import { fetchLmtSalesCountryData, fetchScoreCardLmtChartDetail, setLmtCountrySelection, setLmtCustomerSelection } from '../../../../../action/actionScoreCard';
import { LMT_OPERATIONAL_SALES_UNITS, LMT_OPERATIONAL_SALES_UNITS_ENUMS } from '../../../../../constants/score-card.constants';
import LmtSalesDeliveredChartComponent from '../LmtSalesDeliveredChartComponent';
import LmtSalesStackedChartComponent from '../LmtSalesStackedChartComponent';
import LmtCustomerComparisonChartComponent from '../LmtCustomerComparisonChartComponent';
import LmtSalesListComponent from '../LmtSalesListComponent';
import LmtChartCustomerSelectionComponent from '../LmtChartCustomerSelectionComponent';
import LmtChartCountrySelectionComponent from '../LmtChartCountrySelectionComponent';
import LmtSalesBySourceSelectionComponent from '../LmtSalesBySourceSelectionComponent';

interface IProps {
    selectedTab: string;
    isVisibleModal: boolean
    showCountryFilter: boolean
    setShowCountryFilter: (value: boolean) => void
    showCustomerFilter: boolean
    setShowCustomerFilter: (value: boolean) => void
    showSourceFilter: boolean
    setShowSourceFilter: (value: boolean) => void
}

const GeneralComponent = (props: IProps) => {
    const { selectedTab, isVisibleModal, setShowCountryFilter, showCountryFilter, setShowCustomerFilter, showCustomerFilter, showSourceFilter, setShowSourceFilter } = props
    const dispatch = useDispatch();

    const { chartData, countryData, selectedLmtCountry, selectedLmtCustomer,selectedLmtSource } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            chartData: scorecard.lmtChartData,
            countryData: scorecard.lmtCountryData,
            selectedLmtCountry: scorecard.selectedLmtCountry,
            selectedLmtCustomer: scorecard.selectedLmtCustomer,
            selectedLmtSource: scorecard.selectedLmtSource
        };
    });
    const [showFromYearFilter, setShowFromYearFilter] = useState(false);
    const [showToYearFilter, setShowToYearFilter] = useState(false);
    const [showUnitSelection, setShowUnitSelection] = useState(false);

    const currentYear = new Date().getFullYear();
    // 5 year period
    const [fromYearFilterValue, setFromYearFilterValue] = useState(currentYear - 5);
    const [toYearFilterValue, setToYearFilterValue] = useState(currentYear);

    const [unitIndex, setUnitIndex] = useState(0);

    const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
    const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
    const [selectedSources, setSelectedSources] = useState<string[]>([]);

    useEffect(() => {
        // am4core.disposeAllCharts();
        // Build country request
        let countryRequest: string[] = [];
        if (selectedCountries && selectedCountries.length > 0) {
            countryRequest = selectedCountries
        }

        // Build customer request
        let customerRequest: string[] = [];
        if (selectedCustomers && selectedCustomers.length > 0) {
            customerRequest = selectedCustomers
        }

        let sourceRequest: string[] = [];
        if (selectedSources && selectedSources.length > 0) {
            sourceRequest = selectedSources
        }

        let request = {
            from: fromYearFilterValue,
            to: toYearFilterValue,
            unit: unitIndex,
            listCountry: countryRequest,
            listCustomer: customerRequest,
            listSource: sourceRequest,
            year: 2022,
            month: 'Feb'
        };

        dispatch(fetchScoreCardLmtChartDetail(request));
    }, [fromYearFilterValue, toYearFilterValue, unitIndex, selectedCountries, selectedCustomers, selectedSources]);

    useEffect(() => {
        dispatch(fetchLmtSalesCountryData());
        setShowCountryFilter(false)
        setShowCustomerFilter(false)
        setShowSourceFilter(false)
    }, [])

    useEffect(() => {
        setShowCountryFilter(false)
        setShowCustomerFilter(false)
        setShowSourceFilter(false)
        setShowFromYearFilter(false)
        setShowToYearFilter(false)
        setShowUnitSelection(false)
        setSelectedCountries([])
        setSelectedCustomers([])
        setSelectedSources([])
        dispatch(setLmtCountrySelection([]));
        dispatch(setLmtCustomerSelection([]));
    }, [isVisibleModal, selectedTab])

    const getRootContainer = (elementClassName): HTMLElement => {
      const elements = document.getElementsByClassName(elementClassName);
      if (elements.length > 0) {
          return elements[0] as HTMLElement;
      }
      return document.body;
    };

    // Change unit dataset
    const units = LMT_OPERATIONAL_SALES_UNITS;
    let keyIdx = 0;

    function handleChangeUnit(unit: string) {
        let selectedUnitIndex = units.findIndex(v => v == unit);
        setUnitIndex(selectedUnitIndex);
        setShowUnitSelection(false);
    };

    function handleFromYearSelect($event) {
        setFromYearFilterValue($event.year());
        setShowFromYearFilter(false);
    }

    function handleToYearSelect($event) {
        setToYearFilterValue($event.year());
        setShowToYearFilter(false);
    }

    function displaySelectedYear(selectedYear: any) {
        if (selectedYear)
            return selectedYear;
        return "?";
    }

    // Prepare objects for countries and customers filtering
    let displayCountries: any[] = [];
    let displayCustomers: any[] = [];
    let displaySources: any[] = [];

    // Country, customer and source selection handling
    if (countryData && countryData.length > 0) {
        for (let idx = 0; idx < countryData.length; idx++) {
            const country = countryData[idx].countryName;
            displayCountries.push({ country: country, selected: true });

            const listCustomers = countryData[idx].listCustomerName;
            for (let idxCustomer = 0; idxCustomer < listCustomers.length; idxCustomer++) {
            const customer = listCustomers[idxCustomer].customerName;
            if (displayCustomers.indexOf(customer) < 0) {
                displayCustomers.push({ customer: customer, selected: true });
            }
            const listSources = listCustomers[idxCustomer].listSource;
                for (let idxSource = 0; idxSource < listSources.length; idxSource++) {
                const source = listSources[idxSource];
                if (displaySources.indexOf(source) < 0) {
                    displaySources.push({ source: source, selected: true });
                }
            }
        }
    }
    displayCustomers = displayCustomers.filter((value, index) => {
        const _value = JSON.stringify(value);
        return index === displayCustomers.findIndex(obj => {
            return JSON.stringify(obj) === _value;
        });
    });

    displaySources = displaySources.filter((value, index) => {
        const _value = JSON.stringify(value);
        return index === displaySources.findIndex(obj => {
            return JSON.stringify(obj) === _value;
        });
    });
    }
    function handleCountrySelection(isShow: boolean) {
        if (isShow == false) {
            // open dropdown
            setShowCountryFilter(!isShow)
        }
        else {
            setSelectedCountries([...selectedLmtCountry])

            // Close dropdown
            setShowCountryFilter(!isShow);
        }
    }

    function handleCustomerSelection(isShow: boolean) {
        if (isShow == false) {
            // open dropdown
            setShowCustomerFilter(!isShow)
        }
        else {
            setSelectedCustomers([...selectedLmtCustomer]);

            // Close dropdown
            setShowCustomerFilter(!isShow);
        }
    }

    function handleSourceSelection(isShow: boolean) {
        if (isShow == false) {
            // open dropdown
            setShowSourceFilter(!isShow)
        }
        else {
            setSelectedSources([...selectedLmtSource]);

            // Close dropdown
            setShowSourceFilter(!isShow);
        }
    }

    const sortDisplayCustomers = displayCustomers.sort((a, b) => {
        return a.customer.localeCompare(b.customer);
    })


    function renderSelectionText(selected: string[]) {
        let selectionText = "";
        if (selected.length === 0) {
            return "All";
        }

        selectionText += selected[0]

        return selectionText + `${selected.length > 1 ? `, +${selected.length - 1}` : ''}`;
    }


    return (
        <>
            <div className={s.filterBlocks}>
                <div className={s.filterCountryCustomerBlock}>
                    <div className={s.filterBlock}>
                        <span>COUNTRY</span>
                        <div className={`${s.filterBlockDropdown} ${showCountryFilter ? s.show : ''}`}>
                            <button
                                type="button"
                                style={{ width: "155px", justifyContent: 'center' }}
                                onClick={() => handleCountrySelection(showCountryFilter)}
                            >
                                {renderSelectionText(selectedCountries)}
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            {(displayCountries && displayCountries.length > 0 && showCountryFilter) && (
                                <div className={s.filterSelectionDropdownMenu}>
                                    <LmtChartCountrySelectionComponent countryData={displayCountries} />
                                </div>)}
                        </div>
                    </div>
                    <div className={s.filterBlock}>
                        <span>CUSTOMER</span>
                        <div className={`${s.filterBlockDropdown} ${showCustomerFilter ? s.show : ''}`}>
                            <button
                                type="button"
                                style={{ width: "218px", justifyContent: 'center' }}
                                onClick={() => handleCustomerSelection(showCustomerFilter)}
                            >
                                <Tooltip title={renderSelectionText(selectedCustomers).toUpperCase()}>
                                    <span className={s.selectedCustomer}>{renderSelectionText(selectedCustomers)}</span>
                                </Tooltip>
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            {(sortDisplayCustomers && sortDisplayCustomers.length > 0 && showCustomerFilter) && (
                                <div className={s.filterSelectionDropdownMenu}>
                                    <LmtChartCustomerSelectionComponent customerData={sortDisplayCustomers} />
                                </div>)}
                        </div>
                    </div>
                    <div className={s.filterBlock}>
                        <span>SOURCE</span>
                        <div className={`${s.filterBlockDropdown} ${showSourceFilter ? s.show : ''}`}>
                            <button
                                type="button"
                                style={{ width: "218px", justifyContent: 'center' }}
                                onClick={() => handleSourceSelection(showSourceFilter)}
                            >
                                <Tooltip title={renderSelectionText(selectedSources).toUpperCase()}>
                                    <span className={s.selectedCustomer}>{renderSelectionText(selectedSources)}</span>
                                </Tooltip>
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            {(displaySources && displaySources.length > 0 && showSourceFilter) && (
                                <div className={s.filterSelectionDropdownMenu}>
                                    <LmtSalesBySourceSelectionComponent sourceData={displaySources} />
                                </div>)}
                        </div>
                    </div>
                </div>
                <div className={s.filterPeriodUnitBlock}>
                    <div className={s.filterBlock}>
                        <span>FROM</span>
                        <div className={s.datepickerSelector}></div>
                        <div className={`${s.filterBlockDropdown}`}
                            onBlur={() => setShowFromYearFilter(false)}>
                            <button
                                type="button"
                                style={{ width: "110px", justifyContent: 'center' }}
                                onClick={() =>
                                    setShowFromYearFilter(!showFromYearFilter)
                                }
                            >
                                {displaySelectedYear(fromYearFilterValue)}
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            <div className="scorecards-year-picker">
                                <Space direction="vertical">
                                    <DatePicker
                                        value={moment(
                                            `${fromYearFilterValue}`,
                                            "YYYY"
                                        )}
                                        onChange={handleFromYearSelect}
                                        open={showFromYearFilter}
                                        picker="year"
                                        getPopupContainer={() => getRootContainer(s.datepickerSelector)}
                                    />
                                </Space>
                            </div>
                        </div>
                    </div>
                    <div className={s.filterBlock}>
                        <span>TO</span>
                        <div className={s.datepickerSelector}></div>
                        <div className={`${s.filterBlockDropdown}`}
                            onBlur={() => setShowToYearFilter(false)}>
                            <button
                                type="button"
                                style={{ width: "110px", justifyContent: 'center' }}
                                onClick={() =>
                                    setShowToYearFilter(!showToYearFilter)
                                }
                            >
                                {displaySelectedYear(toYearFilterValue)}
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            <div className="scorecards-year-picker">
                                <Space direction="vertical">
                                    <DatePicker
                                        value={moment(
                                            `${toYearFilterValue}`,
                                            "YYYY"
                                        )}
                                        onChange={handleToYearSelect}
                                        open={showToYearFilter}
                                        picker="year"
                                        getPopupContainer={() => getRootContainer(s.datepickerSelector)}
                                    />
                                </Space>
                            </div>
                        </div>
                    </div>
                    <div className={s.filterBlock}>
                        <span>UNIT</span>
                        <div className={`${s.filterBlockDropdown}
                ${showUnitSelection ? s.show : ''}`}
                        >
                            <button
                                type="button"
                                style={{ width: "110px", justifyContent: 'center' }}
                                onClick={() =>
                                    setShowUnitSelection(!showUnitSelection)
                                }
                            >
                                {units[unitIndex]}
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            <div className={s.filterBlockDropdownMenu}>
                                <ul>
                                    <li>
                                        <button
                                            type="button"
                                            onClick={() => handleChangeUnit(LMT_OPERATIONAL_SALES_UNITS_ENUMS.MMT)}
                                        >
                                            {LMT_OPERATIONAL_SALES_UNITS_ENUMS.MMT}
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" onClick={() => handleChangeUnit(LMT_OPERATIONAL_SALES_UNITS_ENUMS.BCE)}>
                                            {LMT_OPERATIONAL_SALES_UNITS_ENUMS.BCE}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={s.chartBody}>
                {/* Render Chart components */}
                {chartData && (
                    <Row gutter={[10, 10]}>
                        <Col span={8} key={"chart" + keyIdx++}>
                            <LmtSalesDeliveredChartComponent chartData={chartData.saleDelivered} />
                        </Col>
                        <Col span={8} key={"chart" + keyIdx++}>
                            <LmtSalesStackedChartComponent chartData={chartData.saleByCustomer} chartName="Top 5 Sales By Customer" />
                        </Col>
                        <Col span={8} key={"chart" + keyIdx++}>
                            <LmtSalesStackedChartComponent chartData={chartData.saleBySource} chartName="Sales by Source" />
                        </Col>
                        <Col span={8} key={"chart" + keyIdx++}>
                            <LmtSalesStackedChartComponent chartData={chartData.saleByTermSpot} chartName="Sales by Term/Spot" />
                        </Col>
                        <Col span={8} key={"chart" + keyIdx++}>
                            <LmtSalesStackedChartComponent chartData={chartData.saleBySeason} chartName="Sales by Season" />
                        </Col>
                        <Col span={8} key={"chart" + keyIdx++}>
                            <LmtSalesStackedChartComponent chartData={chartData.saleByIncoterms} chartName="Sales by Incoterms" />
                        </Col>
                        <Col span={16} key={"chart" + keyIdx++}>
                            <LmtCustomerComparisonChartComponent chartData={chartData.saleByCustomer} chartName="Customer Comparison" />
                        </Col>
                        <Col span={8} key={"chart" + keyIdx++}>
                            <LmtSalesListComponent chartData={chartData.listOfDqtld} chartName="List of DQT/LD" />
                        </Col>
                        <Col style={fromYearFilterValue === 2024 && toYearFilterValue === 2024 ?{paddingTop: '90px'} : {}} span={8} key={"chart" + keyIdx++}>
                            <LmtSalesListComponent chartData={chartData.saleByPorts} chartName="Sales by Ports" />
                        </Col>
                        <Col style={fromYearFilterValue === 2024 && toYearFilterValue === 2024 ?{paddingTop: '90px'} : {}} span={8} key={"chart" + keyIdx++}>
                            <LmtSalesListComponent chartData={chartData.saleByVessels} chartName="Sales by Vessels" />
                        </Col>
                        <Col span={8} key={"chart" + keyIdx++}>
                            <LmtSalesListComponent chartData={chartData.srcMmbtuByCountry} chartName="SRC/MMBtu by Country" />
                        </Col>
                    </Row>
                )}

            </div>
        </>
    )
}

export default GeneralComponent