import s from './primary-info.module.css';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { useState } from 'react';
import { formatNumber, toFixedNumber, toFixedSustainabilityNumber } from '../../../utils/Number.utils';
import { formatDate } from '../../../utils/callout.utils';

function SustainabilityPrimaryInfo() {
  const { callOutData, lastUpdated } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      callOutData: callout.sustainabilityCalloutData,
      lastUpdated: callout.lastUpdated
    };
  });

  const inherentCo2Data = callOutData?.primaryTags; 
  
  const [tabIndex, setTabIndex] = useState(0);

  function tabClassName(i) {
    if (i === tabIndex) {
      return s.active;
    }
    return;
  }

  function onTabChange(i: number) {
    setTabIndex(i);
  }

  function renderUnitSubscript (i: string) {
    if (i === 'tCO2e') {
      return <span>tCO<sub>2</sub>e</span>;
    }
    else{
      return <span>M&nbsp;tCO<sub>2</sub>e</span>;
    }
  }

  function renderInherentCo2Tab(index: number) {
    // if (inherentCo2Data[index]) {
      const emission = inherentCo2Data ? inherentCo2Data[index].primaryValue: '';
      return (
        <>
        <div className={s.emissionPanelInfo}>
          <Row gutter={8}>
            <Col span={8}>
              <div className={s.emissionDataContainer}>
                <div className={s.emissionPrimaryValue}>
                  <div className={s.co2Title}>
                    {emission.emissionName}
                  </div>
                  <div className={s.co2Value} style={{fontSize: emission?.value?.toString().length === 7 ? '16px' : '20px'}}>
                    {emission.value ? toFixedSustainabilityNumber(emission.value, 3): 'N/A'}
                    <span className={s.co2Unit}>{emission.value ? renderUnitSubscript(emission.unit) : ''}</span>
                  </div>
                </div>
                <div className={s.co2DataConfident}>
                  <span><p>Data Confident</p>
                    <span className={s.confidentStatus}><p>HIGH</p></span>
                  </span>
                  <span><b>R</b><p>00.00%</p><div className={s.comingSoon}><p>Coming Soon</p></div></span>
                  <span><b>T</b><p>00.00%</p></span>
                  <span><b>A</b><p>00.00%</p></span>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className={s.emissionDataContainer}>
                <div className={s.emissionPrimaryValue}>
                  <div className={`${s.co2Title} ${s.disabled}`}>
                    Reduction
                    <div className={s.comingSoon}><p>Coming Soon</p></div>
                  </div>
                  <div className={`${s.co2Value} ${s.disabled}`}>
                    0.350
                    <span className={`${s.co2Unit} ${s.disabled}`}>{renderUnitSubscript(emission.unit)}</span>
                  </div>
                </div>

                <div className={s.co2DataConfident}>
                  <span><p>Data Confident</p>
                    <span className={s.confidentStatus}><p>HIGH</p></span>
                  </span>
                  <span><b>R</b><p>00.00%</p></span>
                  <span><b>T</b><p>00.00%</p></span>
                  <span><b>A</b><p>00.00%</p></span>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className={s.emissionDataContainer} style={{ borderRight: 'none' }}>
                <div className={s.emissionPrimaryValue}>
                  <div className={`${s.co2Title} ${s.disabled}`}>
                    Intensity
                    <div className={s.comingSoon}><p>Coming Soon</p></div>
                  </div>
                  <div className={`${s.co2Value} ${s.disabled}`}>
                    0.350
                    <span className={`${s.co2Unit} ${s.disabled}`}>{renderUnitSubscript(emission.unit)}</span>
                  </div>
                </div>
                <div className={s.co2DataConfident}>
                  <span><p>Data Confident</p>
                    <span className={s.confidentStatus}><p>HIGH</p></span>
                  </span>
                  <span><b>R</b><p>00.00%</p></span>
                  <span><b>T</b><p>00.00%</p></span>
                  <span><b>A</b><p>00.00%</p></span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {callOutData?.primaryTags[0].primaryValue.unit === 'tCO2e' ? (
          <div className={s.sustainabilitySummaryDateContainer}>
            <span className={s.sustainabilitySummaryDate}>
                <img src='/img/icon/ic-time.svg' alt='time' /> {lastUpdated ? formatDatetime(lastUpdated) : 'N/A'}
            </span>
          </div>
        ) : (
          <div className={s.sustainabilitySummaryDateContainer}>
            <span className={s.sustainabilitySummaryDate}>
              <img src='/img/icon/ic-time.svg' alt='time' />{lastUpdated ? formatDatetime(lastUpdated) : 'N/A'}
            </span>
          </div>
        )}
        </>
      )
    // }

    // return (<div className={s.emissionPanelInfo}>
    //   Data not available
    // </div>)
  }

  return (
    <div className={s.emissionValuePrimaryInfo}>
      <div className={s.calloutTab}>
        {/* <ul>
          {inherentCo2Data && inherentCo2Data.length > 0 &&
            inherentCo2Data.map((co2Data, idx) => {
              return (
                <li
                  key={co2Data.tagName}
                  className={tabClassName(idx)}
                  onClick={() => onTabChange(idx)}
                >
                  {co2Data.tagName}
                </li>
              );
            })
          }
        </ul> */}
      </div>
      <div className="calloutGhgPrimaryInfo">
        { renderInherentCo2Tab(tabIndex)}
      </div>
    </div>
  );

  function formatDatetime(datetimeString) {
    const date = new Date(datetimeString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate(); 
    const month = monthNames[date.getMonth()]; 
    const year = date.getFullYear(); 
    let hour = date.getHours(); 
    const minute = date.getMinutes().toString().padStart(2, '0'); 
    const ampm = hour >= 12 ? 'PM' : 'AM'; 
    
    hour = hour % 12 || 12;
    
    return `Last updated on ${day} ${month} ${year} ${hour}:${minute} ${ampm}`;
  }
}

export default SustainabilityPrimaryInfo;
