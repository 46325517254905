import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { formatScoreCardNumber } from "../../../utils/Number.utils";

import s from "./ForwardPriceChartComponent.module.css";
const FORWARD_PRICE_LEGEND = [
    {
        label: 'AECO',
        color: '#F06800',
        isHide: false,
        chartIdx: 0,
        value: 'values0',
        isLNG: true
    },
    {
        label: 'HH',
        color: '#00A19C',
        isHide: false,
        chartIdx: 1,
        value: 'values1',
        isLNG: true
    },
    {
        label: 'NBP',
        color: '#637AB8',
        isHide: false,
        chartIdx: 2,
        value: 'values2',
        isLNG: true
    },
    {
        label: 'JKM',
        color: '#F64D4B',
        isHide: false,
        chartIdx: 3,
        value: 'values3',
        isLNG: true
    },
    {
        label: 'TTF',
        color: '#6FC27B',
        isHide: false,
        chartIdx: 4,
        value: 'values4',
        isLNG: true
    },
    {
        label: 'JCC',
        color: '#F0AB00',
        isHide: false,
        chartIdx: 0,
        value: 'values5',
        isLNG: false
    },
    {
        label: 'BRENT',
        color: '#8BC5F5',
        isHide: false,
        chartIdx: 1,
        value: 'values6',
        isLNG: false
    }
]

const SHOWRIGHTCHART = 'SHOWRIGHTCHART'
const SHOWLEFTCHART = 'SHOWLEFTCHART'

const ForwardPriceChartV2Component = (props: any) => {
    const chartLNG = useRef<am4charts.XYChart>();
    const chartCrude = useRef<am4charts.XYChart>();
    const fpChartScrollbar = useRef<am4charts.XYChart>();
    const [chartConfig, setchartConfig]: any = useState(FORWARD_PRICE_LEGEND);
    const [hiddenCharts, setHiddenChart]: any = useState('');
    useEffect(() => {
        if (!isEmpty(props?.forwardPriceData)) {
            const chartDivLNG = am4core.create("forwardPriceChartLNG", am4charts.XYChart);
            const chartDivCrude = am4core.create("forwardPriceChartCrude", am4charts.XYChart);
            const chartScrollbar = am4core.create("forwardPriceScrollbar", am4charts.XYChart);
            fpChartScrollbar.current = chartScrollbar;
            chartLNG.current = chartDivLNG;
            chartCrude.current = chartDivCrude;
            const dateAxisLNG = chartLNG?.current?.xAxes.push(new am4charts.DateAxis());
            const dateAxisCRUDE = chartCrude?.current?.xAxes.push(new am4charts.DateAxis());
            configChart(props?.forwardPriceData?.chartData?.lngData, chartLNG.current, 'lng', dateAxisLNG)
            configChart(props?.forwardPriceData?.chartData?.crudeChart, chartCrude.current, 'crude', dateAxisCRUDE)
            configScrollBar(props?.forwardPriceData?.chartData?.lngData, fpChartScrollbar.current, dateAxisCRUDE, dateAxisLNG)
        }

        // Add data
        return () => {
            chartLNG.current?.dispose();
            chartCrude.current?.dispose();
        };
    }, [props.forwardPriceData]);

    function configChart(chartData, chartCurrent: am4charts.XYChart, chartType, dateAxis) {
        if (chartCurrent && chartData.length > 0) {
            chartCurrent.data = chartData;
            chartCurrent.background.fillOpacity = 0;
            chartCurrent.height = am4core.percent(100);
            chartCurrent.cursor = new am4charts.XYCursor();
            chartCurrent.cursor.maxTooltipDistance = -1;
            chartCurrent.cursor.lineY.disabled = true;
            chartCurrent.zoomOutButton.disabled = true;
            chartCurrent.cursor.behavior = "none";
            customizeDateAxis(chartCurrent, dateAxis);
            customizeValueAxis(chartCurrent, chartType);
        }
    }
    function customizeDateAxis(chartCurrent: am4charts.XYChart, dateAxis) {
        dateAxis.renderer.fullWidthTooltip = true;
        dateAxis.renderer.ticks.template.disabled = false;
        dateAxis.baseInterval = { timeUnit: "month", count: 1 };
        dateAxis.renderer.labels.template.fill = am4core.color("#ffffffa6");
        dateAxis.renderer.labels.template.fontSize = 12;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.labels.template.disabled = true;
        dateAxis.cursorTooltipEnabled = false;
        dateAxis.events.on("datavalidated", (ev) => {
            const axis = ev.target;
            const start = axis.positionToDate(0);
            const end = axis.positionToDate(1);
            const current = new Date(start);
            while (current < end) {
                const range = axis.axisRanges.create();
                const nextMonth = new Date(current);
                nextMonth.setMonth(current.getMonth() + 1);
                range.date = current;
                range.endDate = nextMonth;
                const monthName = moment(current).format("MMM").toLocaleUpperCase();
                const dateText = current.getFullYear() > moment(current).subtract(1, "months").toDate().getFullYear() ? `${monthName}\n${moment(current).format("YYYY")}` : `${monthName}`
                range.label.dataItem.text = dateText;
                range.label.fontSize = props.forwardPriceData.isZoom ? 15 : 11;
                range.label.dx = 30;
                range.grid.strokeOpacity = 0;
                //Iterate
                current.setMonth(current.getMonth() + 1);
            }
        });

        chartCurrent.events.on("ready", function () {
            dateAxis.zoomToDates(
                chartCurrent.data[0].date,
                chartCurrent.data[11].date,
                false,
                true
            );
            chartCurrent.zoomOutButton.disabled = true;
        })

    }

    function customizeValueAxis(chartCurrent: am4charts.XYChart, chartType) {
        // Create series
        const chartCount = chartType === "lng" ? 5 : 2
        const valueAxis = chartCurrent.yAxes.push(new am4charts.ValueAxis());
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.labels.template.fill = am4core.color("#ffffffa6");
        valueAxis.renderer.labels.template.fontSize = 12;

        let colorIndex = chartType === "lng" ? 0 : 5;
        for (let idx = 0; idx < chartCount; idx++) {
            createLineChart(chartCurrent, `values${idx}`, valueAxis, colorIndex++, chartType);
        }
    }

    function cutomizeTooltipV2(chartType) {
        let tooltipItems = '<div><p>{timestamp}</p></div>';
        const chartCount = chartType === "lng" ? 5 : 7;
        const startIdx = chartType === 'lng' ? 0 : 5;
        const unit = chartType === 'lng' ? 'USD/MMbtu' : 'USD/bbl';
        for (let idx = startIdx; idx < chartCount; idx++) {
            const item = chartConfig[idx];
            tooltipItems +=
                `<tr key=${idx}>
                    <td align="left">
                        <span style="color:${item.color};">{values${item.chartIdx}}</p>
                    </td>
                    <td><p>${unit}</td>
                </tr>`
        }

        return `<table>
            ${tooltipItems}
        </table>`

    }

    function createLineChart(
        chartCurrent: am4charts.XYChart,
        seriesNo: string,
        valueAxis: am4charts.ValueAxis,
        idx: number,
        chartType: string
    ) {
        const lineSeries = chartCurrent.series.push(new am4charts.LineSeries());
        const circleBullet = new am4charts.CircleBullet();
        const lineColor = FORWARD_PRICE_LEGEND[idx].color;
        circleBullet.circle.stroke = am4core.color(FORWARD_PRICE_LEGEND[idx].color);
        circleBullet.circle.fill = am4core.color(lineColor);
        circleBullet.fillOpacity = 0;
        circleBullet.strokeOpacity = 0;
        circleBullet.dx = 10;

        const bulletState = circleBullet.states.create("hover");
        bulletState.properties.fillOpacity = 1;
        bulletState.properties.strokeOpacity = 1;
        lineSeries.bullets.push(circleBullet);
        lineSeries.dataFields.valueY = seriesNo;
        lineSeries.dataFields.dateX = "date";
        lineSeries.yAxis = valueAxis;
        lineSeries.stroke = am4core.color(lineColor);
        lineSeries.strokeWidth = 2;
        lineSeries.showOnInit = false;
        lineSeries.tooltipHTML = cutomizeTooltipV2(chartType)

        const lineTooltip = lineSeries.tooltip;
        if (lineTooltip) {
            lineTooltip.pointerOrientation = "down";
            lineTooltip.getFillFromObject = false;
            lineTooltip.background.fill = am4core.color("#051212");
            lineTooltip.background.opacity = 0.95;
            lineTooltip.background.strokeOpacity = 0;
        }
    }

    function configScrollBar(chartData, chartCurrent: am4charts.XYChart, dateAxisCRUDE: am4charts.DateAxis, dateAxisLNG: am4charts.DateAxis) {
        if (chartCurrent && chartData.length > 0) {
            chartCurrent.data = chartData;
            chartCurrent.cursor = new am4charts.XYCursor();
            chartCurrent.cursor.lineY.disabled = true;
            chartCurrent.zoomOutButton.disabled = true;
            chartCurrent.plotContainer.visible = false
            chartCurrent.leftAxesContainer.visible = false;
            chartCurrent.rightAxesContainer.visible = false;
            chartCurrent.width = am4core.percent(100)

            const dateAxis = chartCurrent.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.fullWidthTooltip = true;
            dateAxis.renderer.ticks.template.disabled = false;
            dateAxis.baseInterval = { timeUnit: "month", count: 1 };
            dateAxis.renderer.labels.template.disabled = true;
            dateAxis.cursorTooltipEnabled = false;
            chartCurrent.events.on("ready", function () {
                dateAxis.zoomToDates(
                    chartCurrent.data[0].date,
                    chartCurrent.data[11].date,
                    false,
                    true
                );
                chartCurrent.zoomOutButton.disabled = true;
                dateAxis.events.on('startendchanged', (ev) => {
                    const chartLng = chartLNG.current;
                    const chartCrud = chartCrude.current;
                    if (chartLng && chartCrud) {
                        dateAxisCRUDE.start = ev.target.start;
                        dateAxisCRUDE.end = ev.target.end;
                        dateAxisLNG.start = ev.target.start;
                        dateAxisLNG.end = ev.target.end;
                    }
                })
            })

            const valueAxis = chartCurrent.yAxes.push(new am4charts.ValueAxis());
            valueAxis.cursorTooltipEnabled = false;
            const lineSeries = chartCurrent.series.push(new am4charts.LineSeries());
            lineSeries.dataFields.valueY = 'values0';
            lineSeries.dataFields.dateX = "date";
            lineSeries.yAxis = valueAxis;

            chartCurrent.scrollbarX = new am4core.Scrollbar();
            chartCurrent.scrollbarX.minHeight = 10;
            chartCurrent.scrollbarX.thumb.background.fill = am4core.color('#ffffffa6');
            chartCurrent.scrollbarX.background.fill = am4core.color('#0b1b23');
            chartCurrent.scrollbarX.parent = chartCurrent.bottomAxesContainer;
            chartCurrent.scrollbarX.startGrip.disabled = true;
            chartCurrent.scrollbarX.endGrip.disabled = true;
        }
    }


    function setLegend(item) {
        const newChartConfig = chartConfig.map((config) => {
            return item.label === config.label ? { ...config, isHide: !config.isHide } : config
        })

        setchartConfig(newChartConfig);
        const leftCharts: any = []
        let rightCharts: any = []
        newChartConfig.forEach((value) => {
            if (value.isLNG && value.isHide) {
                leftCharts.push(value.label);
            }
            if (!value.isLNG && value.isHide) {
                rightCharts.push(value.label)
            }
        })
        let hiddenChart = '';
        const chartLNGCurrent = chartLNG.current;
        const chartCrudeCurrent = chartCrude.current;
        const chartCurrent = item.isLNG ? chartLNGCurrent : chartCrudeCurrent;
        switch (true) {
            case leftCharts.length === 5:
                hiddenChart = SHOWRIGHTCHART
                break;
            case rightCharts.length === 2:
                hiddenChart = SHOWLEFTCHART
                break;
            default:
                break;
        }
        setHiddenChart(hiddenChart);

        if (chartCurrent && !chartCurrent?.isDisposed()) {
            if (!item.isHide) {
                chartCurrent.series.getIndex(item.chartIdx)?.hide()
            } else {
                chartCurrent.series.getIndex(item.chartIdx)?.show()
            }
        }
    }
    function openAllLegend() {
        const newChartConfig = chartConfig.map((config) => {
            return { ...config, isHide: false }
        })
        setchartConfig(newChartConfig)
        const chartLNGCurrent = chartLNG.current;
        const chartCrudeCurrent = chartCrude.current;
        if (chartLNGCurrent && chartCrudeCurrent) {
            chartLNGCurrent.series.each((value) => value.show());
            chartCrudeCurrent.series.each((value) => value.show());
        }
        setHiddenChart('')
    }

    function _legendChart() {
        return (
            <div className={s.legendSection}>

                <div className={s.legendBlock}>
                    <div
                        className={s.legendALL}
                        onClick={() => openAllLegend()}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                openAllLegend();
                            }
                        }}
                        tabIndex={0}
                    >
                        <p>ALL</p>
                    </div>
                    {chartConfig.map((item, idx) => (
                        <div
                            key={idx}
                            className={s.legendGroup}
                            onClick={() => setLegend(item)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setLegend(item);
                                }
                            }}
                            tabIndex={0}
                            style={{ opacity: item.isHide ? 0.6 : 1 }}
                        >
                            <div className={s.legend} style={{ backgroundColor: item.color }} />
                            <p>{item.label}</p>
                        </div>
                    ))}
                </div>
                <span className={s.lastUpdatedDate}>
                    <img src='/img/icon/ic-time.svg' alt='time' />
                    &nbsp; {props?.forwardPriceData?.chartData?.lastUpdated}
                </span>
            </div>
        );
    }
    function renderCharts() {
        let cssChart = {
            chartContent: '',
            chartHeaderLeft: '',
            chartHeaderRight: '',
            divBorder: '',
            trendChartLeft: '',
            trendChartRight: ''
        }

        switch (hiddenCharts) {
            case '':
                cssChart = {
                    chartContent: s.chartContent,
                    chartHeaderLeft: s.chartHeader,
                    chartHeaderRight: s.chartHeader,
                    divBorder: s.divBorder,
                    trendChartLeft: s.trendChart,
                    trendChartRight: s.trendChart
                }
                break;
            case SHOWLEFTCHART:
                cssChart = {
                    chartContent: s.chartContentColumn,
                    chartHeaderLeft: s.chartHeader,
                    chartHeaderRight: s.displayNone,
                    divBorder: s.displayNone,
                    trendChartLeft: s.trendChartLeft,
                    trendChartRight: s.trendChartHide
                }
                break;
            case SHOWRIGHTCHART:
                cssChart = {
                    chartContent: s.chartContentColumn,
                    chartHeaderLeft: s.displayNone,
                    chartHeaderRight: s.chartHeader,
                    divBorder: s.displayNone,
                    trendChartLeft: s.trendChartHide,
                    trendChartRight: s.trendChartRight
                }
                break;
        }
        return <div className={cssChart.chartContent}>
            <div className={cssChart.chartHeaderLeft}>
                <span>LNG Markers (USD/MMbtu)</span>
                <div id="forwardPriceChartLNG" className={cssChart.trendChartLeft} />
            </div>
            <div className={cssChart.divBorder} />
            <div className={cssChart.chartHeaderRight}>
                <span>Crude Markers (USD/bbl)</span>
                <div id="forwardPriceChartCrude" className={cssChart.trendChartRight} />
            </div>
        </div>
    }
    return (
        <>
            <div className={s.chartBlock}>
                <div className={s.chartContainer}>
                    {_legendChart()}
                    {renderCharts()}
                    <div id="forwardPriceScrollbar" className={s.scrollbar} />
                </div>
            </div>
        </>
    );
};
export default ForwardPriceChartV2Component;
