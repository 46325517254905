import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import { formatNumber } from "../../utils/Number.utils";
import s from "../ValueChain/valuechain.module.css";
import sRes from "./valuechainResponsive.module.css";

const itemUnit = { "mmscf/d": true, "km3/d": true, bce: true };
const IntegratedItemComponent = (props: any) => {
  const getPlannedValueMarkup = (item: any) => {
    if (props?.data?.unit.toLowerCase() === "bce") {
      return (
        <small className="text-white">
          &nbsp;/&nbsp;
          {item && item.planned !== null ? formatNumber(item?.planned) : "N/A"}
        </small>
      );
    }
    return "";
  };

  const _renderItemHeading = (hasProcessItem) => {
    return (
      <>
        {hasProcessItem && props?.integratedLNGProcess ? (
          <>
            <div className={s.progressItemIcon}>
              <img
                src={props?.integratedLNGProcess?.icon}
                alt="Static Process"
              />
            </div>
            <div className={sRes.progressItemText}>
              {props?.integratedLNGProcess?.text}
            </div>
          </>
        ) : (
          ""
        )}
        {props?.data?.value ? (
          <div>
            <b className={`text-${props?.data?.color}`}>
              {props?.data?.unit && formatNumber(props?.data?.value)}
            </b>
            {getPlannedValueMarkup(props?.data)}
            <span>{props?.data?.unit}</span>
          </div>
        ) : (
          <div className={sRes.itemHeadingNA}>N/A</div>
        )}
      </>
    );
  };

  function _renderItemUnit(item) {
    return itemUnit[item?.unit?.toLowerCase()] ? (
      // Value, Unit display in 2 line
      <>
        <b className={`text-${item?.color}`}>
          {formatNumber(item?.value)}
        </b>
        {getPlannedValueMarkup(item?.value)}
        <small>{item?.unit}</small>
      </>
    ) : (
      // Value, Unit display inline
      <>
        <b className={`text-${item?.color}`}>
          {formatNumber(item?.value)}{getPlannedValueMarkup(item)}
          <small>{item?.unit}</small>
        </b>
      </>
    )
  }

  const _renderBodyItem = () => {
    return props?.data?.subValues?.map((item: any, index: any) => {
      return (
        <div className={s.integrateItemValue} key={index}>
          <span>{item?.title}</span>
          {item?.value !== null ? (
            _renderItemUnit(item)
          ) : (
            <b className="text-grey">
              N/A {getPlannedValueMarkup(item?.value)}
            </b>
          )}
        </div>
      );
    });
  };

  const _renderBoxShadow = () => {
    return !props?.hideBody ? " " + sRes.integrateItemHeadingBoxShadow : "";
  };

  const _renderNoData = () => {
    return !props?.hideBody ? (
      <div className={`${s.integrateItem} ${sRes.integrateItemNA}`}>
        No data available
      </div>
    ) : (
      ""
    );
  };

  const _setClassIntegrateBody = () => {
    const isLengthTwo = props?.data?.subValues?.length === 2 ? s.flexStart : "";
    
    const integrateItemClass = `${s.integrateItemBody} ${isLengthTwo}`;
    return `${integrateItemClass}`;
  };

  // const isLastItem = props.isLastItem ? s.isLastItem : "";
  const itemClassName = `${s.integrateItem}`;
  
  const _renderMobileTable = () => (
    <div className={sRes.integrateItem}>
      <div className={sRes.integrateItemHeading + _renderBoxShadow()}>
        {_renderItemHeading(true)}
      </div>
      {props?.data?.subValues?.length > 0 ? (
        <div className={`${_setClassIntegrateBody()}`}>{_renderBodyItem()}</div>
      ) : (
        _renderNoData()
      )}
    </div>
  );
  return (
    <>
      <Desktop>
        {props?.data?.value !== null || props?.data?.subValues.length > 0 ? (
          <div className={itemClassName}>
            <div className={s.integrateItemHeading}>
              {_renderItemHeading(false)}
            </div>
            <div className={`${_setClassIntegrateBody()}`}>
              {_renderBodyItem()}
            </div>
          </div>
        ) : (
          <div className={`${s.integrateItem} ${s.integrateItemNA}`}>N/A</div>
        )}
      </Desktop>
      <Mobile>{_renderMobileTable()}</Mobile>
      <Tablet>{_renderMobileTable()}</Tablet>
    </>
  );
};

export default IntegratedItemComponent;
