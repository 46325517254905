import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import PrimaryCard from './PrimaryCard';
import { Desktop, Mobile, Tablet } from '../../responsive/responesive';
import s from './scorecards.module.css';
import sMobile from './scorecardsResponsiveMobile.module.css';
import DetailsModal from './DetailsModal/DetailsModal';
import {
  fetchScoreCardLmtOperationalExecSummary,
  openScorecardDetailsModal, openScorecardSecondaryCardTabModal
} from '../../action/actionScoreCard';
import DetailsChart from './DetailsChart/DetailsChart';
import { OPU_UPCS } from '../../constants/opu.constants';
import { LMT_PAGE_TYPE, LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS, LMT_TAB_SCORE_CARD, LMT_URL_MORE_DETAILS, MONTH_SHORT_TEXT } from '../../constants/score-card.constants';
import SecondaryCard from './SecondaryCard/SecondaryCard';

export interface FinancialExcellenceProps {
}

// eslint-disable-next-line no-empty-pattern
export default function FinancialExcellenceComponent({}: FinancialExcellenceProps) {
  const dispatch = useDispatch();

  const { financialExcellenceData, currentDate } = useSelector((state) => {
    const scorecard = get(state, 'scoreCard', null);
    return {
      financialExcellenceData: scorecard.financialExcellenceData,
      currentDate: scorecard.currentDate
    };
  });

  const sectionName = financialExcellenceData?.sectionName;
  const selectedPersona = useSelector(
    (state: any) => state.user.selectedPersona
  );

  const [currentPage, setCurrentPage] = useState(-1);
  const [currentData, setCurrentData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);

  useEffect(() => {
    if (
      financialExcellenceData?.parameters //&&
      // financialExcellenceData?.parameters.length > 0
    ) {
      const { paramCount, pageSize: page } = financialExcellenceData;
      setPageSize(page);
      initPage(page, paramCount, 0);
    }
  }, [financialExcellenceData]);

  function initPage(page, totalItems, cPage) {
    const totalPages =
      totalItems % page !== 0
        ? parseInt((totalItems / page).toString()) + 1
        : totalItems / page;
    
    const newData: any = financialExcellenceData?.parameters?.filter(
      (item, idx) => {
        return idx >= cPage * page && idx < cPage * page + page;
      }
    );

    setCurrentPage(cPage);
    setCurrentData(newData);
    setTotalPage(totalPages);
  }

  function pageChanged(isNextPage) {
    let cPage = currentPage;
    if (isNextPage) {
      if (cPage < totalPage - 1) cPage += 1;
    } else {
      if (cPage > 0) cPage -= 1;
    }
    const newData: any = financialExcellenceData?.parameters?.filter(
      (item, idx) => {
        return idx >= cPage * pageSize && idx < cPage * pageSize + pageSize;
      }
    );
    if (cPage !== currentPage) {
      setCurrentPage(cPage);
    }
    setCurrentData(newData);
  }

  function openScorecardDetails() {
    dispatch(openScorecardDetailsModal());
  }

  function isEnableDetail(card){
    return selectedPersona === OPU_UPCS.MLNG && card.isAdditionalDataExists == true;
  }

  function ScoreCardColComponent() {
    return (
      <>
        <div className={s.scoreCardsCol}>
          {currentData &&
            currentData.map((card, idx) => {
              return idx % 5 === 0 ? <PrimaryCard key={idx} data={card} sectionName={sectionName} enableDetail={isEnableDetail(card)}/> : '';
            })}
        </div>
        <div className={s.scoreCardsCol}>
          {currentData &&
            currentData.map((card, idx) => {
              return idx % 5 === 1 ? <PrimaryCard key={idx} data={card} sectionName={sectionName} enableDetail={isEnableDetail(card)}/> : '';
            })}
        </div>
        <div className={s.scoreCardsCol}>
          {currentData &&
            currentData.map((card, idx) => {
              return idx % 5 === 2 ? <PrimaryCard key={idx} data={card} sectionName={sectionName} enableDetail={isEnableDetail(card)}/> : '';
            })}
        </div>
        <div className={s.scoreCardsCol}>
          {currentData &&
            currentData.map((card, idx) => {
              return idx % 5 === 3 ? <PrimaryCard key={idx} data={card} sectionName={sectionName} enableDetail={isEnableDetail(card)}/> : '';
            })}
        </div>
        <div className={s.scoreCardsCol}>
          {currentData &&
            currentData.map((card, idx) => {
              return idx % 5 === 4 ? <PrimaryCard key={idx} data={card} sectionName={sectionName} enableDetail={isEnableDetail(card)}/> : '';
            })}
        </div>
      </>
    );
  }

  function ScoreCardPageComponent(cssModule) {
    return (
      <div className={cssModule.scoreCardsPagination}>
        <button
          disabled={currentPage <= 0}
          type='button'
          onClick={() => pageChanged(false)}
        >
          <i className='mdi mdi-menu-left'></i>
        </button>
        <button
          disabled={currentPage >= totalPage - 1}
          type='button'
          onClick={() => pageChanged(true)}
        >
          <i className='mdi mdi-menu-right'></i>
        </button>
      </div>
    );
  }

  function displayMoreDetails () {
    return (
    <div className={s.moreDetailText}>
      <img
        src='\img\icon\Icon Link.svg'
        alt='Icon More Detail'
      />
      <a
        href={LMT_URL_MORE_DETAILS}
        target='_blank'
        rel='noreferrer'
      >
        <span>More Details (Power BI)</span>
      </a>
    </div>
    );
  }

  function _renderMobileTablet() {
    return (
      <>
        <div>
          <div>
            {selectedPersona !== OPU_UPCS.LMT && 
            (<div className={sMobile.detailsIcon}
              onClick={openScorecardDetails}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {openScorecardDetails();}
              }}
              tabIndex={0}
            >
              <img
                alt='details'
                src='\img\icon\details-icon.svg'
              />
            </div>)}            
            <div className={sMobile.scoreCardsItems}>
              {ScoreCardColComponent()}
            </div>
            {selectedPersona === OPU_UPCS.LMT && currentData.length > 0 && displayMoreDetails()}
            {ScoreCardPageComponent(sMobile)}
          </div>
        </div>
      </>
    );
  }

  function requestExecSummary() {
    const params = `?section=${LMT_TAB_SCORE_CARD.FINANCIAL_PERFORMANCE}&page=${LMT_PAGE_TYPE.SCORECARD}` +
      `&year=${currentDate.year}&month=${MONTH_SHORT_TEXT[currentDate.month - 1]}`;
    dispatch(fetchScoreCardLmtOperationalExecSummary(params));
  }

  function openSecondaryDetailCard() {
    requestExecSummary();
    dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.EXECUTIVE_SUMMARY));
  }

  return (
    <>
      <Desktop>
        {/* Big card list */}
        <div>
          <div className={s.scorecardContainer}>
            {selectedPersona === OPU_UPCS.LMT &&
            (<div className={s.detailsIcon}
              onClick={openSecondaryDetailCard}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {openSecondaryDetailCard();}
              }}
              tabIndex={0}
            >
              <img
                alt='details'
                src='\img\icon\details-icon.svg'
              />
            </div>)}
            {selectedPersona !== OPU_UPCS.LMT && 
            (<div className={s.detailsIcon}
              onClick={openScorecardDetails}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {openScorecardDetails();}
              }}
              tabIndex={0}
            >
              <img
                alt='details'
                src='\img\icon\details-icon.svg'
              />
            </div>)}            
            <div className={s.scoreCardsItems}>
              {ScoreCardColComponent()}        
            </div>        
            {selectedPersona === OPU_UPCS.LMT && currentData.length > 0 && displayMoreDetails()}
            {ScoreCardPageComponent(s)}         
          </div>
        </div>
      </Desktop>

      <Mobile>{_renderMobileTablet()}</Mobile>

      <Tablet>{_renderMobileTablet()}</Tablet>
      <DetailsModal/>
      <DetailsChart/>

      {<SecondaryCard sectionName={sectionName} selectedPersona={selectedPersona} isShowCardNameAsHeader={true}/>}
    </>
  );
}
