import { formatNumber } from "../../utils/Number.utils";
import { formatUnit } from "../../utils/unit.utils";
import s from "./map.module.css";

const MapFlowrateComponent = (props: any) => {
    let location = props.data;
    return (
        <div className={s.flowrateItem}>
            <div className={s.locationName}>
                <span>{location.locationName}</span>
            </div>
            <div className={s.locationValue}>
                {
                    location?.parameters.map((param: any, idx: any) => {
                        return (
                            <div key={'y' + idx}>
                                {
                                    param?.displayLabel &&
                                    <div className={s.mb5px}><b className={s.dLabel}>{param?.displayLabel}</b></div>
                                }
                                <div className={s.locationUnit} key={'x' + idx}>
                                    <b>{formatNumber(param?.value)}</b>
                                    <span>{formatUnit(param?.unit)}</span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default MapFlowrateComponent;