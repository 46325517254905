import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { get, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useMediaQuery } from "react-responsive";

import s from "./BaseLmtSalesSummaryChart.module.css";
import { LmtSalesStackedChartComponentProps } from "./LmtSalesStackedChartComponent";
import { MOBILE_RESPONSIVE, TABLET_RESPONSIVE } from "../../../../constants/responsive.constants";
import { toFixedNumber } from "../../../../utils/Number.utils";
import { useSelector } from "react-redux";
// // const FORWARD_PRICE_LEGEND = [
// //     {
// //         label: 'AECO',
// //         color: '#F06800',
// //         isHide: false,
// //         chartIdx: 0,
// //         value: 'values0',
// //         isLNG: true
// //     },
// //     {
// //         label: 'HH',
// //         color: '#00A19C',
// //         isHide: false,
// //         chartIdx: 1,
// //         value: 'values1',
// //         isLNG: true
// //     },
// //     {
// //         label: 'NBP',
// //         color: '#637AB8',
// //         isHide: false,
// //         chartIdx: 2,
// //         value: 'values2',
// //         isLNG: true
// //     },
// //     {
// //         label: 'JKM',
// //         color: '#F64D4B',
// //         isHide: false,
// //         chartIdx: 3,
// //         value: 'values3',
// //         isLNG: true
// //     },
// //     {
// //         label: 'TTF',
// //         color: '#6FC27B',
// //         isHide: false,
// //         chartIdx: 4,
// //         value: 'values4',
// //         isLNG: true
// //     },
// //     {
// //         label: 'JCC',
// //         color: '#F0AB00',
// //         isHide: false,
// //         chartIdx: 0,
// //         value: 'values5',
// //         isLNG: false
// //     },
// //     {
// //         label: 'BRENT',
// //         color: '#8BC5F5',
// //         isHide: false,
// //         chartIdx: 1,
// //         value: 'values6',
// //         isLNG: false
// //     }
// // ]

export default function LmtCustomerComparisonChartComponent({ chartData, chartName }: LmtSalesStackedChartComponentProps) {
    const chart = useRef<am4charts.XYChart>();
    const fpChartScrollbar = useRef<am4charts.XYChart>();
    // const [chartConfig, setchartConfig]: any = useState(FORWARD_PRICE_LEGEND);
    // const [hiddenCharts, setHiddenChart]: any = useState('');
    const isMobile = useMediaQuery(MOBILE_RESPONSIVE);
    const isTablet = useMediaQuery(TABLET_RESPONSIVE);
    //convert name for avoid '()' bug in tooltip
    const convertName = (inputString: string) => {
        return inputString.replace('(', '*').replace(')', '@')
    }

    const deConvertName = (inputString: string) => {
        return inputString.replace('*', '(').replace('@', ')');
    }

    let transformData: any[] = [];

    if (chartData) {
        chartData = chartData.map((item) => { return { ...item, category: convertName(item.category) } });
        const allCustomers = chartData.map(c => c.category);

        transformData = Object.values(chartData?.reduce(function (transform, current) {
            const { year } = current;
            if (!transform[year]) {
                transform[year] = { year: current.year, customers: [] }
            }
            transform[year].customers.push({ category: current.category, value: current.value });
            return transform;
        }, {}));

        // Fill in missing customers for each year
        // for (let idx = 0; idx < transformData.length; idx++) {
        //     for (let customerIdx = 0; customerIdx < customers; customerIdx++) {
        //         transformData[idx][categoryName]
        //     }
        // }

        for (let idx = 0; idx < transformData.length; idx++) {
            let customers = transformData[idx].customers
            customers.map(function (obj) {
                // Get name of category
                const categoryName = obj['category'];
                // Create new obj with categoryName
                //transformData[idx][categoryName] = {[categoryName]: obj['value'], category: obj['category']};
                transformData[idx][categoryName] = obj['value'] ?? 0;

                const customersInYear = customers.map(c => c.category);
                allCustomers.map(a => {
                    // This customer is not in this year
                    if (customersInYear.indexOf(a) < 0) {
                        return transformData[idx][a] = 0;
                    }
                });

                return;
            });

            delete transformData[idx].customers;
        }
    }

    let keys: string[] = [];
    for (let idx = 0; idx < transformData.length; idx++) {
        for (let key in transformData[idx]) {
            if (transformData[idx].hasOwnProperty(key) && keys.indexOf(key) < 0) {
                keys.push(key);
            }
        }
    }
    useEffect(() => {
        if (!isEmpty(transformData)) {
            const chartDiv = am4core.create(chartName, am4charts.XYChart);
            chart.current = chartDiv;
            const chartCurrent = chart.current;
            //const chartScrollbar = am4core.create("comparisonScrollbar", am4charts.XYChart);
            const yearAxis = chartCurrent?.xAxes.push(new am4charts.CategoryAxis());
            //fpChartScrollbar.current = chartScrollbar;
            configChart(transformData, chartCurrent, yearAxis);
            //configScrollBar(transformData, fpChartScrollbar.current, keys[0]);
        }

        // Add data
        return () => {
            chart.current?.dispose();
        };
    }, [transformData]);

    function configChart(chartData, chartCurrent: am4charts.XYChart, yearAxis: am4charts.CategoryAxis) {
        if (chartCurrent && chartData.length > 0) {
            chartCurrent.data = chartData;
            chartCurrent.background.fillOpacity = 0;
            chartCurrent.height = am4core.percent(100);
            chartCurrent.cursor = new am4charts.XYCursor();
            chartCurrent.cursor.maxTooltipDistance = -1;
            chartCurrent.cursor.lineY.disabled = true;
            chartCurrent.zoomOutButton.disabled = true;
            chartCurrent.cursor.behavior = "none";
            chartCurrent.numberFormatter.numberFormat = "#.##"

            if (chartCurrent.logo) {
                chartCurrent.logo.disabled = true;
            }

            // Colors
            const customerColors = ["#F06800", "#ECC972", "#637AB8", "#8BC5F5", "#F5BB8B"];
            for (let idx = 0; idx < customerColors.length; idx++) {
                chartCurrent.colors.list.push(am4core.color(customerColors[idx]));
            }

            // Legend
            chartCurrent.legend = new am4charts.Legend();
            /* Create a separate container to put legend in */
            var legendContainer = am4core.create(chartName + "-legenddiv", am4core.Container);
            legendContainer.width = am4core.percent(100);
            legendContainer.height = am4core.percent(100);
            chartCurrent.legend.parent = legendContainer;
            // chartCurrent.legend.height = 40;
            chartCurrent.legend.scrollable = true;
            chartCurrent.legend.labels.template.text = "{name}";

            customizeYearAxis(chartCurrent, yearAxis);
            customizeValueAxis(chartCurrent);
        }
    }
    function customizeYearAxis(chart: am4charts.XYChart, yearAxis: am4charts.CategoryAxis) {
        yearAxis.dataFields.category = "year";
        yearAxis.renderer.minGridDistance = 10;
        yearAxis.renderer.labels.template.fill = am4core.color("#ffffffa6");
        yearAxis.fontSize = 11;
        yearAxis.renderer.grid.template.disabled = true;
        yearAxis.cursorTooltipEnabled = false;
        yearAxis.dateFormatter.dateFormat = "yyyy";
        yearAxis.numberFormatter = new am4core.NumberFormatter();
        yearAxis.numberFormatter.numberFormat = "#";
        (isMobile || isTablet) &&
            chart.events.on("ready", function () {
                const year = new Date().getFullYear();
                yearAxis.zoomToCategories(
                    year.toString(), (year + 1).toString()
                );
                chart.zoomOutButton.disabled = true;
            });
    }

    function customizeValueAxis(chartCurrent: am4charts.XYChart) {
        // Create series
        const valueAxis = chartCurrent.yAxes.push(new am4charts.ValueAxis());
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.labels.template.fill = am4core.color("#ffffffa6");
        valueAxis.renderer.labels.template.fontSize = 12;

        for (let keyIdx = 0; keyIdx < keys.length; keyIdx++) {
            if (keys[keyIdx] === 'year') continue;
            createLineChart(
                chartCurrent,
                valueAxis,
                keys[keyIdx],
                keys[keyIdx]
            );
        }

        // for (let idx = 0; idx < chartCount; idx++) {
        //     createLineChart(chartCurrent, `values${idx}`, valueAxis, chartType);
        // }
    }

    function cutomizeTooltipV2(seriesName, valueField) {
        let tooltipItems = `<span>${deConvertName(seriesName)}: {${valueField}}</p>`

        return tooltipItems
    }

    function createLineChart(
        chartCurrent: am4charts.XYChart,
        valueAxis: am4charts.ValueAxis,
        seriesName: string,
        valueField: string,
    ) {
        const lineSeries = chartCurrent.series.push(new am4charts.LineSeries());
        lineSeries.name = deConvertName(seriesName);
        const circleBullet = new am4charts.CircleBullet();

        //undisplay dot in chart
        // circleBullet.fillOpacity = 0;
        // circleBullet.strokeOpacity = 0;
        circleBullet.dx = 10;

        const bulletState = circleBullet.states.create("hover");
        bulletState.properties.fillOpacity = 1;
        bulletState.properties.strokeOpacity = 1;

        lineSeries.bullets.push(circleBullet);
        lineSeries.dataFields.valueY = valueField;
        lineSeries.dataFields.categoryX = "year";
        lineSeries.yAxis = valueAxis;
        lineSeries.strokeWidth = 2;
        lineSeries.showOnInit = false;
        lineSeries.tooltipHTML = cutomizeTooltipV2(seriesName, valueField)

        const lineTooltip = lineSeries.tooltip;
        if (lineTooltip) {
            lineTooltip.pointerOrientation = "down";
            lineTooltip.getFillFromObject = false;
            lineTooltip.background.fill = am4core.color("#051212");
            lineTooltip.background.opacity = 0.95;
            lineTooltip.background.strokeOpacity = 0;
        }
    }

    function configScrollBar(chartData, chartCurrent: am4charts.XYChart, key: string) {
        if (chartCurrent && chartData.length > 0) {
            chartCurrent.data = chartData;
            chartCurrent.cursor = new am4charts.XYCursor();
            chartCurrent.cursor.lineY.disabled = true;
            chartCurrent.zoomOutButton.disabled = true;
            chartCurrent.plotContainer.visible = false
            chartCurrent.leftAxesContainer.visible = false;
            chartCurrent.rightAxesContainer.visible = false;
            chartCurrent.width = am4core.percent(100);

            // const yearAxis = chartCurrent.xAxes.push(new am4charts.DateAxis());
            // dateAxis.renderer.fullWidthTooltip = true;
            // dateAxis.renderer.ticks.template.disabled = false;
            // dateAxis.renderer.labels.template.disabled = true;
            // dateAxis.cursorTooltipEnabled = false;
            // chartCurrent.events.on("ready", function () {
            //     dateAxis.zoomToDates(
            //         chartCurrent.data[0].date,
            //         chartCurrent.data[11].date,
            //         false,
            //         true
            //     );
            //     chartCurrent.zoomOutButton.disabled = true;
            //     dateAxis.events.on('startendchanged', (ev) => {
            //         const chartLng = chartLNG.current;
            //         if (chartLng) {
            //             dateAxisCRUDE.start = ev.target.start;
            //             dateAxisCRUDE.end = ev.target.end;
            //         }
            //     })
            // })

            const valueAxis = chartCurrent.yAxes.push(new am4charts.ValueAxis());
            valueAxis.cursorTooltipEnabled = false;
            const lineSeries = chartCurrent.series.push(new am4charts.LineSeries());
            lineSeries.dataFields.valueY = key;
            lineSeries.dataFields.dateX = "year";
            lineSeries.yAxis = valueAxis;

            chartCurrent.scrollbarX = new am4core.Scrollbar();
            chartCurrent.scrollbarX.minHeight = 10;
            chartCurrent.scrollbarX.thumb.background.fill = am4core.color('#ffffffa6');
            chartCurrent.scrollbarX.background.fill = am4core.color('#0b1b23');
            chartCurrent.scrollbarX.parent = chartCurrent.bottomAxesContainer;
            chartCurrent.scrollbarX.startGrip.disabled = true;
            chartCurrent.scrollbarX.endGrip.disabled = true;
        }
    }

    return (
        <>
            {transformData && transformData.length > 0 ?
                (
                    <div className={s.lineChartContainer}>
                        <div className={s.chartHeader}>
                            {chartName}
                        </div>
                        <div id={chartName} className={s.lineChart}></div>
                        {/* <div id="comparisonScrollbar" className={s.scrollbar} /> */}
                        <div id={chartName + "-legenddiv"} style={{ maxHeight: 50 }}></div>
                        {/* <div className="legendwrapper">
                        <div id="legenddiv"></div>
                    </div> */}
                    </div>
                ) :
                (
                    <div className={s.lineChartContainer}>
                        <span className={s.chartNotAvailable}>Chart not available</span>
                    </div>
                )
            }
        </>
    );
};

